/* eslint-disable */
import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Box, Stack } from '@mui/material';
import { TextField, OtpField } from 'components/Form';
import { Button, Typography } from 'components';
import { useResetPassword } from 'api/sdk';
import { convertServerErrors } from 'utils/form';

interface IVerifyEmailFormValues {
  email: string;
  password: string;
  otp: string;
}

interface IVerifyEmailFormProps {
  email: string;
  onSubmit: (values: IVerifyEmailFormValues) => void;
}
const VerifyEmailForm: React.FC<IVerifyEmailFormProps> = ({
  email,
  onSubmit
}) => {
  const { mutateAsync, error, isError, isLoading } = useResetPassword();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    otp: Yup.string().required('OTP is required')
  });

  const verifyEmailFormInitialValue: IVerifyEmailFormValues = {
    email,
    password: '',
    otp: ''
  };

  const handleSubmit = async (
    values: IVerifyEmailFormValues,
    formik: FormikHelpers<IVerifyEmailFormValues>
  ) => {
    mutateAsync({
      data: {
        email: values.email,
        password: values.password,
        otp: Number(values.otp)
      }
    })
      .then(() => {
        onSubmit(values);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formik.setErrors(convertServerErrors(err.response.data.detail));
        }
        if (err.response.status === 400) {
          console.log('errr', err.response);
          formik.setErrors({ otp: 'Invalid Code' });
        }
        if (err.response.status === 404) {
          formik.setErrors({ email: 'User not found' });
        }
      });
  };

  return (
    <Formik<IVerifyEmailFormValues>
      initialValues={verifyEmailFormInitialValue}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Stack spacing={3}>
              <TextField
                name="email"
                boootstrap
                label="Email"
                type="email"
                light
              />
              <TextField
                name="password"
                label="New Password"
                type="password"
                boootstrap
                light
              />
              <OtpField name="otp" label="Code" />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {/* Verify Email */}
                Confirm Password
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default VerifyEmailForm;
