import { Box, styled, IconButton } from '@mui/material';
import React, { useState } from 'react';

import { Check } from 'components/Form';

interface ThumbnailProps {
  thumbnailUrl: string;
  title: string;
  checkable?: boolean;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
  onClick?: () => void;
  handelChangeImageIndex?: (id: number) => void;
  index: number;
  showImageGallery: boolean;
  type?: string;
  id: string;
}

const getBorderColor = (type?: string) => {
  switch (type) {
    case 'OK':
      return 'green';
    case 'NG':
      return 'red';
    default:
      return '';
  }
};

const ImageContainer = styled('div')<{ type?: string }>(({ theme, type }) => ({
  height: 100,
  width: 100,
  borderRadius: 5,
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  float: 'left',
  padding: type ? '3px' : '0px',
  marginRight: 10,
  marginBottom: 10,
  border: type ? `6px solid green` : `2px solid ${theme.palette.grey[900]}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: 5
  }
}));

export const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbnailUrl,
  title,
  checkable,
  checked,
  onCheck,
  onClick,
  index,
  handelChangeImageIndex = () => {},
  showImageGallery,
  type,
  id
}) => {
  const handleContainerClick = () => {
    if (checkable && onCheck) {
      onCheck(!checked);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <ImageContainer
      type={type}
      onClick={handleContainerClick}
      onDoubleClick={() => handelChangeImageIndex(index)}
    >
      {checkable && (
        <Check
          checked={checked}
          onChange={() => onCheck && onCheck(!checked)}
          overlap={true}
        />
      )}
      <img src={thumbnailUrl} alt={title} draggable={false} loading="lazy" />
    </ImageContainer>
  );
};

export default Thumbnail;
