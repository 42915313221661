import React from 'react';

import { Card } from 'components/Card';
import { Grid, Skeleton } from '@mui/material';

export interface ObjectCategorySkeletonProps {}

export const ObjectCategorySkeleton: React.FC<
  ObjectCategorySkeletonProps
> = ({}) => {
  return (
    <Card>
      <Grid container spacing={2}>
        {/* Start Title */}
        <Grid item xs={8}>
          <Skeleton variant="text" width="100%" height={30} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" width="100%" height={30} />
        </Grid>
        {/* End Title */}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width={100} height={100} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width={100} height={100} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" width={100} height={100} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ObjectCategorySkeleton;
