import { Grid, ListItem, Stack, Box, CircularProgress } from '@mui/material';
import { Button, Card, TableContainer, Typography } from 'components';

import React, { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import UnderRepresented from './underRepresented';
import UnRepresented from './unRepresented';
import MisLabelled from './misLabelled';
import Thumbnail from 'pages/train/Thumbnail';
import { v4 } from 'uuid';
import {
  CreateObjectSchema,
  ImgSchema,
  NoveltySchema,
  ObjSchema,
  ObjSchemaImages,
  SyncObjectsRequest,
  UnRepresentedlist,
  UnderRepresentedList
} from 'schemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedConfiguration } from '../../store/configSlice';

import { SelectInput } from 'components/Form';
import {
  useCreateObjects,
  useGetNoveltyDetails,
  useGetObjectsByInference,
  useSyncObjectByInferenceid
} from 'api/sdk';
import { StartIncremental } from './modalToStartIncremental';
import { useSnack } from 'plugins/snack';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';

export const NoveltyDetection: React.FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { inference_id, configId, selectedModal } = location.state || {};
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );

  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [selectedModel, setSelectedModel] = useState<string>('');

  const snack = useSnack();
  const [noveltyObjects, setNoveltyObjects] = useState<NoveltySchema>();

  const {
    refetch: refetchNovelty,
    error,
    isLoading: noveltyLoading
  } = useGetNoveltyDetails({
    config_id: selectedConfig || '',
    inference_id: currentInferenceID || '',
    model_id: selectedModel || ''
  });

  useEffect(() => {
    dispatch(setSelectedConfiguration(configId));
    setCurrentInferenceID(inference_id);
    setSelectedModel(selectedModal);
  }, [inference_id, configId, selectedModal]);

  useEffect(() => {
    if (currentInferenceID && selectedConfig && selectedModel) {
      refetchNovelty().then(res => {
        setNoveltyObjects(res.data?.data);
      });
    }
  }, [currentInferenceID, selectedConfig, selectedModel]);

  const navigate = useNavigate();

  const [selectBins, setSelectBins] = useState<boolean>(false);

  const [currentBin, setCurrentBin] = useState<number | null | undefined>();
  const [currentTable, setCurrentTable] = useState<string>();
  const [currentClassName, setCurrentClassName] = useState<string>('');
  const [createModal, setCreateModal] = useState<boolean>(false);

  const [checkedUnRepresented, setCheckedUnRepresented] = useState<
    UnRepresentedlist[]
  >([]);

  const [checkedUnderRepresented, setCheckedUnderRepresented] = useState<
    UnderRepresentedList[]
  >([]);

  const ImgesArray = useMemo(() => {
    if (
      currentTable == 'underrepresented' &&
      noveltyObjects?.underrepresented
    ) {
      const selectedBin = noveltyObjects?.underrepresented.find(
        i => i.bin_number == currentBin
      );
      // const currentImages = selectedBin?.classnames.find(
      //   i => i.classname == currentClassName
      // );
      // return currentImages;
    }

    if (currentTable == 'unrepresented' && noveltyObjects?.unrepresented) {
      const selectedBin = noveltyObjects?.unrepresented.find(
        i => i.bin_number == currentBin
      );
      const currentImages = selectedBin?.classnames?.find(
        i => i.classname == currentClassName
      );
      return currentImages;
    }
  }, [currentTable, currentClassName, currentBin]);

  const ClassNames = useMemo(() => {
    if (
      currentTable == 'underrepresented' &&
      noveltyObjects?.underrepresented
    ) {
      const selectedBin = noveltyObjects?.underrepresented.find(
        i => i.bin_number == currentBin
      );
      // const filtered = selectedBin?.classnames.filter(
      //   i => i.images && i.images.length > 0
      // );
      // return filtered;
    }

    if (currentTable == 'unrepresented' && noveltyObjects?.unrepresented) {
      const selectedBin = noveltyObjects?.unrepresented.find(
        i => i.bin_number == currentBin
      );

      const filtered = selectedBin?.classnames?.filter(
        i => i.images && i.images.length > 0
      );
      return filtered;
    }
  }, [currentTable, currentBin]);

  useEffect(() => {
    if (ClassNames) {
      // const v: string = `${ClassNames[0].classname}`;
      // setCurrentClassName(v);
    }
  }, [ClassNames]);

  const handleObjectSelection = (
    currentTable: string,
    bin: number | null | undefined
  ) => {
    if (bin != null && bin != undefined) {
      setCurrentTable(currentTable);
      setCurrentBin(bin);
    }
  };

  const handleCheckUnrepresented = (res: UnRepresentedlist) => {
    const isExist = checkedUnRepresented.find(
      x => x.bin_number === res.bin_number
    );

    if (isExist) {
      setCheckedUnRepresented(prevState =>
        prevState.filter(x => x.bin_number !== res.bin_number)
      );
    } else {
      setCheckedUnRepresented(prevState => [...prevState, res]);
    }
  };

  const checkForlessImages = (): boolean => {
    const combined = [...checkedUnderRepresented, ...checkedUnRepresented];
    const classImageCount: Record<string, number> = {};

    for (const group of combined) {
      // if (group.classnames) {
      //   for (const classItem of group.classnames) {
      //     const className = classItem.classname;
      //     if (className && !classImageCount[className]) {
      //       classImageCount[className] = 0;
      //     }
      //     if (className && classItem.images) {
      //       classImageCount[className] += classItem.images.length;
      //     }
      //   }
      // }
    }

    return Object.values(classImageCount).every(count => count > 50);
  };

  return (
    <>
      <Stack mb={1} direction={'row'} justifyContent={'flex-start'}>
        {' '}
        <Button
          version="light"
          onClick={() => {
            navigate('/inference');
          }}
        >
          {' '}
          Back
        </Button>
      </Stack>

      <Box style={{ height: '82vh' }}>
        <Card style={{ height: '50%' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>Novelty detection </Typography>
            <Button onClick={() => setSelectBins(true)}>
              Select Bins For Incremental Training
            </Button>
          </Stack>

          <Grid container mt={1}>
            <Grid
              item
              xs={7}
              style={{
                backgroundColor: '#102633',
                color: '#fff',
                padding: '5px',
                textAlign: 'center'
              }}
            >
              {' '}
              <Typography> Under-represented </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: '#102633',
                color: '#fff',
                padding: '5px',
                textAlign: 'center',
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff'
              }}
            >
              {' '}
              <Typography> Unrepresented</Typography>
            </Grid>
          </Grid>

          <Grid container mt={1} style={{ height: '90%' }}>
            <Grid item xs={7} style={{ height: '100%' }}>
              <UnderRepresented
                handleObjectSelection={handleObjectSelection}
                UnderRepresentedList={noveltyObjects?.underrepresented || []}
                selectBins={selectBins}
                checkedUnderRepresented={checkedUnderRepresented}
                currentTable={currentTable}
                currentBin={currentBin}
                setSelectBins={setSelectBins}
                setCheckedUnderRepresented={setCheckedUnderRepresented}
              />
            </Grid>
            <Grid item xs={5} style={{ height: '100%' }}>
              <UnRepresented
                handleObjectSelection={handleObjectSelection}
                UnRepresentedlist={noveltyObjects?.unrepresented || []}
                handleCheckUnrepresented={handleCheckUnrepresented}
                checkedUnRepresented={checkedUnRepresented}
                selectBins={selectBins}
                currentTable={currentTable}
                currentBin={currentBin}
              />
            </Grid>
          </Grid>
        </Card>

        <Grid
          container
          style={{ height: '45%' }}
          padding={'0.5rem'}
          spacing={1}
        >
          <Grid item xs={12} style={{ height: '100%' }}>
            <Card style={{ height: '100%' }}>
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                spacing={2}
                alignItems={'center'}
              >
                <SelectInput
                  name="Classname"
                  maxWidth={'300px'}
                  width={'300px'}
                  options={
                    // ClassNames?.map(i => ({
                    //   label: i.classname ?? '',
                    //   value: i.classname ?? ''
                    // })) ||
                    []
                  }
                  placeholder={'Select Classname'}
                  value={currentClassName}
                  variant="outlined"
                  onChange={value => {
                    setCurrentClassName(value);
                  }}
                />
                <Typography style={{ fontWeight: 'bold' }}>
                  {currentClassName
                    ? currentClassName
                    : 'Please select a class'}
                </Typography>
              </Stack>

              <Grid container mt={1} style={{ height: '90%' }}>
                <Grid item xs={5} style={{ height: '100%' }}>
                  <Typography>Train Images </Typography>
                </Grid>
                <Grid item xs={1} style={{ height: '100%' }}></Grid>

                <Grid item xs={5} style={{ height: '100%' }}>
                  <Typography>Test Images </Typography>
                </Grid>
              </Grid>
              {!selectBins && (
                <Grid style={{ overflow: 'scroll', height: '90%' }}>
                  {/* {ImgesArray?.imagespath.map((item, index) => {
                    return (
                      <Thumbnail
                        id={`${index}`}
                        thumbnailUrl={item || ''}
                        checkable={false}
                        title="title"
                        index={index}
                        showImageGallery={false}
                      />
                    );
                  })} */}
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>

        {selectBins && (
          <Stack
            mt={2}
            direction={'row'}
            justifyContent={'center'}
            spacing={2}
            style={{ position: 'relative', zIndex: 5 }}
          >
            <Button
              version="light"
              onClick={() => {
                setCheckedUnRepresented([]);
                setCheckedUnderRepresented([]);
                setSelectBins(false);
              }}
            >
              {' '}
              cancel
            </Button>
            <Button
              onClick={() => {
                if (checkForlessImages()) {
                  setCreateModal(true);
                } else {
                  snack({
                    message: 'Please select atleast 50 images for each class',
                    severity: 'error'
                  });
                }
              }}
            >
              Add for Incremental Training
            </Button>
          </Stack>
        )}
      </Box>

      <StartIncremental
        open={createModal}
        onClose={() => setCreateModal(false)}
        checkedUnderRepresented={checkedUnderRepresented}
        checkedUnRepresented={checkedUnRepresented}
        selectedModal={selectedModal}
        selectedConfig={selectedConfig || ''}
      />
    </>
  );
};
