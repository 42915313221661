/* eslint-disable */
import {
  List,
  ListItem,
  Collapse,
  styled,
  ListItemText,
  Divider,
  ListItemButton
} from '@mui/material';
import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import routes from 'routes/privateRoutesConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface IMenuModeProps {
  mode: 'light' | 'dark';
  subMenu?: boolean;
}

const StyledList = styled(List)<IMenuModeProps>(({ theme, mode }) => ({
  minWidth: '240px',
  backgroundColor:
    mode === 'light'
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  color:
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
}));

const StyledListItem = styled(ListItem)<
  IMenuModeProps & { active: boolean; disabled?: boolean }
>(({ theme, mode, active, subMenu, disabled }) => ({
  padding: '5px 0',
  paddingLeft: subMenu ? '16px' : '0',
  // color: active ? theme.palette.primary.main : 'inherit',
  borderBottom: `1px solid ${
    mode === 'light'
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText
  }`,
  ':last-of-type': {
    borderBottom: 'none'
  },
  backgroundColor: active ? theme.palette.primary.contrastText : 'inherit',
  ...(active && {
    color: '#000 !important',
    backgroundColor: theme.palette.primary.contrastText
  }),
  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5
  })
}));

const AppMenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  marginTop: '64px'
}));

const AppMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [openIndex, setOpenIndex] = useState<number | null>(() => {
    let opened = null;
    Object.keys(routes).forEach((key, index) => {
      const item = routes[key];
      Object.keys(item?.items || {}).forEach((key, idx) => {
        const subItem = (item?.items || {})[key];

        if (location.pathname.includes(subItem.route || '')) {
          opened = index;
        }
      });
    });
    return opened;
  });
  const highlight = (pathname: string, route: string) => {
    if (pathname == route) {
      return true;
    }
    if (pathname == '/labelAssist1' && route == '/Media') {
      return true;
    }
    return false;
  };

  return (
    <AppMenuContainer>
      {/* <StyledList disablePadding mode="dark">
        {Object.keys(routes).map((key, index) => {
          const item = routes[key];

          const path = item.route || '';

          if (item.route != '/labelAssist1') {
            return (
              <>
                {item.route !== '/novelty' && (
                  <StyledListItem
                    // disabled={item.route == '/novelty'}
                    mode="dark"
                    key={`app-menu-${index}`}
                    onClick={() => {
                      navigate(`${item.route}`);
                    }}
                    active={highlight(location.pathname, item.route || '')}
                  >
                    <ListItemButton>{item.name}</ListItemButton>
                  </StyledListItem>
                )}
              </>
            );
          }
        })}
      </StyledList> */}
      <StyledList disablePadding mode="dark">
        {Object.keys(routes).map((key, index) => {
          const item = routes[key];
          const isOpened = openIndex === index;
          const path = item.route || '';
          console.log('itesssss' + item?.route);
          if (item.route != '/labelAssist1') {
            return (
              <>
                {item.route !== '/novelty' && (
                  <StyledListItem
                    mode="dark"
                    key={`app-menu-${index}`}
                    // onClick={() => setOpenIndex(isOpened ? null : index)}
                    // active={false}
                    onClick={() => {
                      navigate(`${item.route}`);
                    }}
                    active={highlight(location.pathname, item.route || '')}
                  >
                    <ListItemButton>{item.name}</ListItemButton>
                    {/* {isOpened ? <ExpandLess /> : <ExpandMore />} */}
                  </StyledListItem>
                )}

                <Collapse in={openIndex == index} timeout="auto" unmountOnExit>
                  <StyledList disablePadding mode="light" subMenu>
                    {Object.keys(item?.items || {}).map((key, idx) => {
                      const subItem = (item?.items || {})[key];
                      return (
                        <StyledListItem
                          mode="light"
                          key={`app-menu-${index}-${idx}`}
                          onClick={() => {
                            subItem.route &&
                              navigate(`${item.route}${subItem.route}`);
                          }}
                          active={
                            location.pathname === `${path}${subItem.route}`
                          }
                          subMenu
                        >
                          <ListItemButton
                            LinkComponent={'a'}
                            // disabled={
                            //   location.pathname === `${path}${subItem.route}`
                            // }
                          >
                            {subItem.name}
                          </ListItemButton>
                        </StyledListItem>
                      );
                    })}
                  </StyledList>
                </Collapse>
              </>
            );
          }
        })}
      </StyledList>
    </AppMenuContainer>
  );
};
export default AppMenu;
