import { format } from 'date-fns';

export const FORMAT_DATE_TIME = 'yyyy-MM-dd HH:mm:ss';
export const FORMAT_DATE_ONLY = 'dd-MM-yyyy';
export const FORMAT_TIME_ONLY = 'HH:mm:ss';
export const FORMAT_DATE = 'YYYY-MM-DD';

/**
 * Main Data and Time conversion utility to keep formats the same across entire Application
 * @param {string|object} dateOrString - date to show as UTC string or Date object instance
 * @param {string} [dateFormat] - time conversion template in 'date-fns' format, `FORMAT_DATE_TIME` by default
 * @param {string} [fallbackValue] - optional fallback value if data conversion is not possible
 */
export function dateToString(
  dateOrString: string | Date,
  dateFormat = FORMAT_DATE_TIME,
  fallbackValue = ''
): string {
  const date =
    typeof dateOrString === 'object' ? dateOrString : new Date(dateOrString);
  let result;
  try {
    result = format(date, dateFormat);
  } catch (error) {
    result = fallbackValue;
  }
  return result;
}

export function convertToDateTime(time: string): Date {
  const timeRegex = /^(\d{2}):(\d{2}):(\d{2})$/;

  const [, hours, minutes, seconds] = time.match(timeRegex)!.map(Number);

  // Create a new Date object with the given time
  const dateTime = new Date();
  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  dateTime.setSeconds(seconds);
  dateTime.setMilliseconds(0);

  return dateTime;
}

export function convertDurationToSeconds(duration: string): number {
  const durationRegex = /^(\d{2}):(\d{2}):(\d{2})$/;

  const [, hours, minutes, seconds] = duration
    .match(durationRegex)!
    .map(Number);

  // Calculate the total number of seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  return totalSeconds;
}
export function convertSecondsToDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Format hours, minutes, and seconds to always have two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const pad = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const secondsToHms = (d: number) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? `${pad(h)}:` : '00:';
  const mDisplay = m > 0 ? `${pad(m)}:` : '00:';
  const sDisplay = s > 0 ? pad(s) : '00';
  return hDisplay + mDisplay + sDisplay;
};

export const secondsToFriendlyTime = (d: number) => {
  if (d === 0) {
    return '0s';
  }
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? `${m}m ` : '';
  const sDisplay = s > 0 ? `${s}s` : '';
  return hDisplay + mDisplay + sDisplay;
};
