import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  CardActionArea,
  IconButton,
  LinearProgress,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { useListConfigurations } from 'api/sdk';
import { useWebSocket } from 'hooks';
import { Formik, Form } from 'formik';

import { ObjectCategoryCard } from 'components';
import Inference from '../../assets/images/trainingImage.svg';
import { CreateModal } from './createModal';
import { useNavigate } from 'react-router-dom';
import Reports from '../../assets/images/reportsQuality.svg';
import reportsNotFound from '../../assets/images/reportsNotFound.svg';
import styled, { keyframes } from 'styled-components';

import ReportIcon from '../../assets/images/inferenceProgress.svg';
import {
  ConfigSchema,
  CreateObjectSchema,
  IchorInferenceSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema
} from 'schemas';

import { Recommend } from '@mui/icons-material';
import Env from 'config/env';
import { setSelectedConfiguration } from 'store/configSlice';
import { useDispatch } from 'react-redux';
import FileManager from 'components/filemanager/rawData';
import PreprocessedData from 'components/filemanager/preProcessedData';
interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;

  handelRefetchObjects: () => Promise<any>;
  setCurrentInferenceID: (inferenceID: string | undefined) => void;
  setSelectedModel: (data: string) => void;
  selectedModel: string | undefined;
  setStatusValue: any;
  InferenceListArray: any;

  handleModelList: () => void;
  modelList: any;
  InferenceList: InferenceSchema[];
  currentInferenceID: string | undefined;

  refetchInference: () => void;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  objects,
  handelRefetchObjects,
  modelList,
  InferenceList,
  handleModelList,
  setCurrentInferenceID,
  currentInferenceID,
  selectedModel,
  setSelectedModel,
  InferenceListArray,
  setStatusValue,

  refetchInference
}) => {
  const { data, isLoading, isError, refetch } = useListConfigurations();

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [startInference, setStartInference] = React.useState(false);

  const [value, setValue] = useState<string | undefined>(undefined);
  const [step, setStep] = useState<string | undefined>(undefined);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const [inferenceValue, setInferenceValue] = useState('');

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [status, setStatus] = useState('QUEUED');

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);
  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    console.log(message, 'message');
    if (
      message?.pipeline_type === 'quality_control_inference' ||
      message?.pipeline_type === 'quality_control_live_inference'
    ) {
      setFromWebsocket(data);
      setStatus(type);
      if (type == 'COMPLETED') {
        refetchInference();
        handelRefetchObjects().then(res => {
          setProcessing(false);
        });
      }
    }

    if (message?.pipeline_type === 'novelty_detection') {
      if (message.type == 'COMPLETED') {
      }
    }
  };

  const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

  const IconWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
  `;
  const BlinkingImage = styled.img`
    width: 30px;
    height: 30px;
  `;
  const BlinkingCircle = styled.div`
    position: absolute;
    top: -5px; /* Adjust position based on your requirement */
    right: -5px; /* Adjust position based on your requirement */
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    animation: ${blink} 1s infinite;
  `;
  const count = useMemo(() => {
    const o =
      objects?.reduce((acc, obj) => acc + (obj.images?.length || 0), 0) || 0;
    return o;
  }, [objects]);

  const navigate = useNavigate();

  const rows =
    InferenceList?.map(item => {
      let inference_status;

      // Determine inference status
      if (
        status === 'COMPLETED' &&
        fromWebsocket?.data &&
        fromWebsocket?.inference_id === item.inference_id
      ) {
        inference_status = 'COMPLETED'; // Show "Completed" when data is received and inference_id matches
      } else if (
        fromWebsocket?.progress !== undefined &&
        fromWebsocket?.inference_id === item.inference_id
      ) {
        inference_status = fromWebsocket.progress; // Show progress if available and inference_id matches
      } else if (fromWebsocket?.inference_id === item.inference_id) {
        inference_status = fromWebsocket?.status || item.status; // Fallback to WebSocket status or item status
      } else {
        inference_status = item.status; // Default to item status if no conditions are met
      }

      // Map fields to rows
      return {
        inferencename: item.inference_name,
        configurationname: item.config_name,
        modelname: item.model_name,
        ModelArchitecture: item?.model_architecture,
        totalimages: item.total_image_count,
        class_count: item.class_count,
        inference_id: item.inference_id,
        config_id: item.config_id,
        inference_status,
        model_id: item.model_id || '',
        mode: item?.mode || '',
        novelty_status: item.novelty_status || false,
        nov_status: item.nov_status,
        isDeleted: item?.is_deleted
      };
    }).filter(item => item.mode != 'live') || [];

  let DataCount = data && data?.data?.length;
  const columns: ITableHeadColumn[] = [
    {
      value: 'Inference Name',
      key: 'inferencename',
      align: 'center'
    },
    // {
    //   value: 'Inference Mode',
    //   key: 'mode',
    //   align: 'center'
    // },

    {
      value: 'Model Name',
      key: 'modelname',
      align: 'center'
    },
    {
      value: 'Model Architecture',
      key: 'ModelArchitecture',
      align: 'center'
    },
    {
      value: 'Total Images',
      key: 'totalimages',
      align: 'center'
    },
    {
      value: 'Total Classes',
      key: 'class_count',
      align: 'center'
    },

    {
      value: 'Inference Status',
      key: 'inference_status',
      align: 'center',
      render: ({ value, record }) => {
        const trainingStatus = record?.inference_status;

        if (trainingStatus === 'COMPLETED') {
          return <Typography variant="body2">Completed</Typography>;
        }
        if (trainingStatus === 'FAILED') {
          return <Typography variant="body2">Failed</Typography>;
        }
        if (trainingStatus === 'INPROGRESS') {
          return <Typography variant="body2">InProgress</Typography>;
        }

        if (trainingStatus === 'QUEUED') {
          return <Typography variant="body2">Queued</Typography>;
        }
        if (trainingStatus === 'STOPPED') {
          return <Typography variant="body2">Stopped</Typography>;
        }

        // If it's neither "COMPLETED" nor "QUEUED", show the progress bar
        return (
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              // value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
              value={
                typeof trainingStatus === 'number'
                  ? Number(trainingStatus.toFixed(2))
                  : 0
              }
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                style={{ fontSize: '10px' }}
              >
                {`${Math.round(trainingStatus as number)}%`}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      value: 'Novelty Status',
      key: 'nov_status',
      align: 'center',
      render: ({ value, record }) => {
        const trainingStatus = record?.nov_status;

        if (trainingStatus === 'COMPLETED') {
          return <Typography variant="body2">Completed</Typography>;
        }
        if (trainingStatus === 'FAILED') {
          return <Typography variant="body2">FAILED</Typography>;
        }
        if (trainingStatus === 'INPROGRESS' && record?.mode === 'live') {
          return <Typography variant="body2">INPROGRESS</Typography>;
        }

        if (trainingStatus === 'QUEUED') {
          return <Typography variant="body2">Queued</Typography>;
        }
        if (trainingStatus === 'STOPPED') {
          return <Typography variant="body2">Stopped</Typography>;
        }
      }
    },
    {
      value: 'Action',
      key: 'Novelty',
      align: 'center',
      render: ({ value, record }) => {
        return (
          <Box
            display="flex"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            {/* IconButton for "View Training Data" */}

            <Tooltip title="View Inferred Result">
              <IconButton
                disabled={
                  record.inference_status === 'FAILED' ||
                  record?.inference_status === 'QUEUED' ||
                  record.isDeleted != false
                }
                onClick={() => {
                  if (
                    record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS'
                  ) {
                    // Navigate to step 3 for live inference
                    onChange(3);
                  } else {
                    dispatch(
                      setSelectedConfiguration(record.config_id as string)
                    );
                    setCurrentInferenceID(record.inference_id as string);
                    setSelectedModel(record.model_id as string);
                    record?.mode === 'live' ? onChange(4) : onChange(2);
                    setStatusValue('COMPLETED');
                  }
                }}
              >
                {/* <img
                  src={
                    record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS'
                      ? ReportIcon // Replace this with the image source for "live in progress"
                      : record?.inference_status === 'COMPLETED'
                      ? Reports
                      : reportsNotFound
                  }
                  className={
                    record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS'
                      ? 'blink'
                      : ''
                  }
                  alt="Report"
                  style={{
                    width: '30px',
                    height: '30px'
                    // filter: `grayscale(90%)`
                  }}
                /> */}
                <IconWrapper>
                  {record?.mode === 'live' &&
                    record?.inference_status === 'INPROGRESS' && (
                      <BlinkingCircle />
                    )}
                  <BlinkingImage
                    src={
                      record?.mode === 'live' &&
                      record?.inference_status === 'INPROGRESS'
                        ? ReportIcon
                        : record?.inference_status === 'COMPLETED' ||
                          record?.inference_status === 'STOPPED'
                        ? Reports
                        : reportsNotFound
                    }
                    alt="Report"
                  />
                </IconWrapper>
              </IconButton>
            </Tooltip>
            <Tooltip title="View Novelty Data">
              <IconButton
                disabled={!record.novelty_status}
                onClick={() => {
                  if (record.novelty_status) {
                    navigate('/novelty', {
                      state: {
                        inference_id: record.inference_id,
                        configId: record.config_id,
                        selectedModal: record.model_id,
                        statusValue: 'COMPLETED',
                        type:
                          record.calss_name === 'OK' ||
                          record.calss_name === 'NG'
                            ? 'LABELLED'
                            : 'UNLABELLED'
                      }
                    });
                  }
                }}
              >
                <img
                  src={record.novelty_status ? Reports : reportsNotFound}
                  alt="Report"
                  style={{
                    width: '30px',
                    height: '30px',
                    filter: `grayscale(90%)`
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedConfig != '') {
      setStep('INITIAL');
    }
  }, []);

  return (
    <>
      <Card
        style={{
          height: '82vh'
          // display: 'flex',
          // flexDirection: 'column',
          // justifyContent: 'space-between'
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h6" fontWeight="bold">
            Recorded Inference Dashboard
          </Typography>{' '}
          {configs && !startInference && (
            <SelectInput
              name="configurationType"
              label="Select Configuration"
              variant="outlined"
              width={'250px'}
              placeholder="Select Configuration"
              // disabled={value != undefined}
              options={configs?.map(i => ({
                label: i.name,
                value: i.id
              }))}
              value={selectedConfig || ''}
              onChange={value => {
                dispatch(setSelectedConfiguration(value));

                setStep('INITIAL');
              }}
            />
          )}
        </Stack>
        {configs &&
          configs.length > 0 &&
          selectedConfig != '' &&
          selectedConfig != undefined &&
          !startInference && (
            // !startInference &&
            <Box>
              <Box style={{ height: '65vh', overflowY: 'scroll' }}>
                {rows && (
                  <Table
                    stickyHeader={true}
                    columns={columns}
                    rows={rows}
                    striped={false}
                  />
                )}
              </Box>
            </Box>
          )}
        {configs && configs.length == 0 && (
          <Stack style={{ textAlign: 'center' }}>
            {' '}
            <Typography>
              {' '}
              Please add configuration to inference a model
            </Typography>{' '}
          </Stack>
        )}

        {configs && configs.length > 0 && selectedConfig == '' && (
          <Stack
            style={{
              height: '41vh',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={Inference}
              alt="Inference"
              style={{ width: '350px', height: '400px' }}
            />
            <Typography style={{ color: '#707070', fontSize: '26px' }}>
              Select Configuration type to proceed with the process{' '}
            </Typography>
          </Stack>
        )}

        {configs &&
          configs.length > 0 &&
          selectedConfig != '' &&
          startInference &&
          step === 'INITIAL' && (
            <PreprocessedData
              type="BOTH"
              selectedConfig={selectedConfig || ''}
              configs={configs}
              onCancel={() => setStartInference(false)}
              onSelect={e => {
                onChange(1);
                setSelectedModel('');
                handleModelList();
                setCreateModalOpen(true);
                setCurrentInferenceID(e);
              }}
            />
          )}
        <Stack spacing={2} direction="column" alignItems="center">
          <Typography
            variant="body2"
            style={{ color: 'red', fontWeight: '16px' }}
          >
            {errorMessage}
          </Typography>
        </Stack>

        {configs &&
          configs.length > 0 &&
          selectedConfig != '' &&
          !startInference && (
            <>
              <Stack direction={'row'} justifyContent={'center'}>
                {' '}
                <Button
                  onClick={() => {
                    setStep('INITIAL');
                    setStartInference(true);
                    setInferenceValue('inference');
                  }}
                  style={{ width: '310px', height: '45px' }}
                >
                  {' '}
                  Start Inference
                </Button>
                {/* <Button
                  onClick={() => {
                    setStep('INITIAL');
                    setInferenceValue('live_inference');

                    setStartInference(true);
                  }}
                  style={{ width: '310px', height: '45px', marginLeft: '20px' }}
                >
                  {' '}
                  Start Live Inference
                </Button> */}
              </Stack>
            </>
          )}

        <Modal open={processing} onClose={() => console.log('')}>
          {' '}
          {/* {loading && (
            <>
              <LinearProgress value={progress} variant="determinate" />
              <Typography>Uploading Images...{`${progress}%`}</Typography>
            </>
          )} */}
          {processing && (
            <div style={{ display: 'flex' }}>
              <CircularProgress size={24} />
              <Typography style={{ marginLeft: '10px' }}>
                Upload Completed please wait images are being processed
              </Typography>
            </div>
          )}
        </Modal>
      </Card>
      <CreateModal
        open={createModalOpen}
        InferenceId={currentInferenceID}
        objects={objects || []}
        ModelList={modelList}
        setSelectedModel={setSelectedModel}
        count={count}
        onClose={() => setCreateModalOpen(false)}
        selectedModel={selectedModel}
        InferenceListArray={InferenceListArray}
        refetchInference={refetchInference}
        inferenceValue={inferenceValue}
        onSucess={() => {
          InferenceListArray();
          setCreateModalOpen(false);
          setStep('INITIAL');
          setStartInference(false);
          onChange(1);
        }}
      />
    </>
  );
};
