/* eslint-disable  */
import React, { useEffect, useState, useRef } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import 'react-datepicker/dist/react-datepicker.css';
import { TextInput } from './TextInput';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField
} from '@mui/material';

export interface IDatePickerProps {
  name: string;
  id: string;
  onChange?: (value: Date | null) => void;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  availabledates?: Date[];
}

const DatePicker: React.FC<IDatePickerProps> = ({
  name,
  id,
  label,
  onChange,
  defaultValue = null,
  minDate,
  maxDate,
  availabledates,
  disabled = false
}) => {
  const datePickerRef = useRef<ReactDatePicker>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultValue);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevYearButtonDisabled,
    nextYearButtonDisabled
  }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <button
          onClick={() => changeYear(date.getFullYear() - 1)}
          disabled={prevYearButtonDisabled}
        >
          <SkipPreviousIcon sx={{ fontSize: 12 }} />
        </button>
        <button
          onClick={() => decreaseMonth()}
          disabled={prevYearButtonDisabled && date.getMonth() === 0}
        >
          <NavigateBeforeIcon sx={{ fontSize: 12 }} />
        </button>
        <span>
          {date.toLocaleString('default', { month: 'long' })}{' '}
          {date.getFullYear()}
        </span>
        <button
          onClick={() => increaseMonth()}
          disabled={nextYearButtonDisabled && date.getMonth() === 11}
        >
          <NavigateNextIcon sx={{ fontSize: 12 }} />
        </button>
        <button
          onClick={() => changeYear(date.getFullYear() + 1)}
          disabled={nextYearButtonDisabled}
        >
          <SkipNextIcon sx={{ fontSize: 12 }} />
        </button>
      </div>
    );
  };

  const isDayBlocked = (date: Date) => {
    if (availabledates) {
      return availabledates.some(
        availabledate =>
          date.getDate() === availabledate.getDate() &&
          date.getMonth() === availabledate.getMonth() &&
          date.getFullYear() === availabledate.getFullYear()
      );
    }
    return false;
  };

  useEffect(() => {
    setSelectedDate(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <ReactDatePicker
        placeholderText="MM-DD-YYYY"
        ref={datePickerRef}
        dateFormat="MM/dd/yyyy"
        className="custom-datepicker"
        selected={selectedDate}
        id={id}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        onFocus={() => datePickerRef.current?.setOpen(true)}
        onBlur={() => datePickerRef.current?.setOpen(false)}
        filterDate={availabledates && isDayBlocked}
        onChange={date => {
          setSelectedDate(date);
          onChange && onChange(date);
        }}
        renderCustomHeader={renderCustomHeader}
        customInput={
          <OutlinedInput
            name={name}
            label={label}
            disabled={disabled}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleIconClick}
                >
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        }
      />
    </>
  );
};

export default DatePicker;
