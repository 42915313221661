import React, { useState, useRef, useEffect } from "react";
import { Modal, Grid } from "@mui/material";

interface VideoPlayerModalProps {
  playModal: boolean;
  videoName: string;
  setPlayModal: (value: boolean) => void;
  setVideoName: (value: string) => void;
}

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
  playModal,
  videoName,
  setPlayModal,
  setVideoName,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute("src");
      videoRef.current.load();
    }
  }, [videoName]);

  return (
    <Modal
      open={playModal}
      onClose={() => {
        setPlayModal(false);
        setVideoName("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          padding: "1rem",
          boxShadow: 24,
        }}
      >
        <div>
          <video width="100%" ref={videoRef} controls autoPlay>
            <source src={videoName} type="video/mp4" />
          </video>
        </div>
      </Grid>
    </Modal>
  );
};

export default VideoPlayerModal;
