import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDraggable  } from '@dnd-kit/core';

type SortableTaskItemProps = {
  children: React.ReactNode;
  id: string;
  path: string;

};

const DraggableItem = ({ children, id , path }: SortableTaskItemProps) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id , data:{
      id,
      path, 
    }
  });

  const style = {
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragging ? 'grab' : 'pointer'
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export default DraggableItem;
