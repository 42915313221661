import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface CustomIconButtonProps {
  icon: React.ComponentType<SvgIconProps>;
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  ariaLabel?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  icon: Icon,
  color,
  ariaLabel,
  disabled,
  onClick
}) => {
  return (
    <IconButton
      color={color}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon />
    </IconButton>
  );
};

export default CustomIconButton;
