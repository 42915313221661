import React from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

type BarChartProps = {
  data: {
    train: { accuracy: number; accuracy_old: number };
    test: { accuracy: number; accuracy_old: number };
    validation: { accuracy: number; accuracy_old: number };
  };
  width?: string;
  height?: string;
};

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChartComponentIncremental: React.FC<BarChartProps> = ({
  data,
  width = '400px !important',
  height = '172px !important'
}) => {
  const chartData = {
    labels: ['Train', 'Test', 'Validation'],
    datasets: [
      {
        label: 'Before Incremental', // New Accuracy
        data: [
          (data.train.accuracy * 100).toFixed(2),
          (data.test.accuracy * 100).toFixed(2),
          (data.validation.accuracy * 100).toFixed(2)
        ],
        backgroundColor: '#1E90FF' // Color for incremental accuracy
      },
      {
        label: 'After Incremental', // Old Accuracy
        data: [
          (data.train.accuracy_old * 100).toFixed(2),
          (data.test.accuracy_old * 100).toFixed(2),
          (data.validation.accuracy_old * 100).toFixed(2)
        ],
        backgroundColor: '#FF6347' // Different color for base accuracy
      }
    ]
  };

  const options = {
    barThickness: 24,
    barPercentage: 0.3,
    plugins: {
      legend: {
        display: true
      }
    },
    maintainAspectRatio: false
  };

  return (
    <Bar
      style={{ height: '10px !important ', width: width }} // Adjust height and width as needed
      data={chartData}
      options={options}
    />
  );
};

export default BarChartComponentIncremental;
