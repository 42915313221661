import React from 'react';

interface ICloseIconProps {
  height?: number;
  width?: number;
}

/**
 * Close Icon
 */
const ImportIcon: React.FC<ICloseIconProps> = ({ height = 24, width = 24 }) => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        height={height}
        width={width}
        fill="#000000"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            id="Icon_awesome-file-import"
            data-name="Icon awesome-file-import"
            d="M.662,12.656a.686.686,0,0,0-.662.7v1.406a.686.686,0,0,0,.662.7H5.3V12.656ZM20.91,4.614,16.856.308a.965.965,0,0,0-.7-.308H15.9V5.625h5.3V5.357A1.085,1.085,0,0,0,20.91,4.614ZM14.575,5.977V0H6.294A1.023,1.023,0,0,0,5.3,1.055v11.6h5.3V9.791a.66.66,0,0,1,1.135-.5l3.958,4.241a.781.781,0,0,1,0,1.055L11.73,18.826a.66.66,0,0,1-1.135-.5V15.469H5.3v5.977A1.023,1.023,0,0,0,6.294,22.5H20.206a1.023,1.023,0,0,0,.994-1.055V7.031H15.569A1.029,1.029,0,0,1,14.575,5.977Z"
            fill="#102633"
          />
        </g>
      </svg>
    </>
  );
};

export default ImportIcon;
