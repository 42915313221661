import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import { Stack, Box, IconButton, Tooltip } from '@mui/material';

import Reports from '../../assets/images/reportsQuality.svg';
import ReportsData from '../../assets/images/viewReports.svg';
import reportsNotFound from '../../assets/images/reportsNotFound.svg';

import { ConfigSchema, CreateObjectSchema, ObjSchema } from 'schemas';
import Inference from '../../assets/images/trainingImage.svg';
import { useWebSocket } from 'hooks';

import Env from 'config/env';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
// import { useSaveFolderFiles } from 'api/sdk';
import { useGetTrainingResult } from '../../api/sdk';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;
  objects: ObjSchema[] | undefined;
  setCurrentInferenceID: (config: string | undefined) => void;
  setInferenceReport: any;
  handelRefetchObjects: () => Promise<any>;
  setViewMode: (data: boolean) => void;
}
interface TrainingReport {}
interface InferenceValue {
  mode: string;
}
interface IncrementalTrainingReport {
  before_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
  after_incremental: {
    train_accuracy: number;
    [key: string]: any; // Add other properties if necessary
  };
}
interface InferenceReport {
  training_report: TrainingReport[];

  incremental_training_report?: IncrementalTrainingReport[];
  inference_report_label?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
    true_positive: number;
    true_negative: number;
    false_positive: number;
    false_nagative: number;
    accuracy: number;
  }>;
  inference_report_unlabel?: Array<{
    total_images: number;
    total_ai_ok_images: number;
    total_ai_ng_images: number;
  }>;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  objects,
  handelRefetchObjects,
  setCurrentInferenceID,
  setInferenceReport,
  setViewMode
}) => {
  const [step, setStep] = useState<string>('TABLE');
  const [processing, setProcessing] = useState<boolean>(false);

  const [selectedObjectID, setSelectedObjectID] = useState<string | undefined>(
    undefined
  );

  const { data, refetch: refectchTrainingResults } = useGetTrainingResult({
    config_id: selectedConfig || ''
  });

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [status, setStatus] = useState('QUEUED');

  const [fromWebsocket, setFromWebsocket] = useState<any>();
  const token = useSelector((state: RootState) => state.auth.token);

  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    setFromWebsocket(data);
    setStatus(type);

    if (type == 'COMPLETED') {
      handelRefetchObjects().then(res => {
        setProcessing(false);
      });
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedConfig != '') {
      setStep('INITIAL');
    }
  }, []);

  useEffect(() => {
    if (selectedConfig != '') {
      setStep('TABLE');
      refectchTrainingResults().then(res => {});
    }
  }, []);

  const dispatch = useDispatch();

  const columns: ITableHeadColumn[] = [
    {
      value: 'Model Name',
      key: 'ModelName',
      align: 'center'
    },
    {
      value: 'Base Model',
      key: 'baseModel',
      align: 'center'
    },
    {
      value: 'Model Architecture',
      key: 'ModelArchitecture',
      align: 'center'
    },
    {
      value: 'Total # Images',
      key: 'TotalImages',
      align: 'center'
    },
    {
      value: 'Total # Classes',
      key: 'totalClasses',
      align: 'center'
    },

    {
      value: 'Train test split',
      key: 'Traintestsplit',
      align: 'center'
    },
    {
      value: 'Batch size',
      key: 'Batchsize',
      align: 'center'
    },
    {
      value: 'Learning Rate',
      key: 'Learningrate',
      align: 'center'
    },
    {
      value: 'Epochs',
      key: 'Epochs',
      align: 'center'
    },

    {
      value: 'Test Accuracy',
      key: 'TestAccuracy'
    },

    {
      value: 'Reports',
      key: 'actions',
      render: ({ value, record }) => {
        return (
          <Box display="flex" gap={1}>
            {/* IconButton for "View Training Data" */}
            <Tooltip title="View Training Data">
              <IconButton
                disabled={record.isDeleted != false}
                onClick={() => {
                  if (record) {
                    setCurrentInferenceID(record.inferenceID as string);
                    setViewMode(true);
                    onChange(3);
                  }
                }}
                aria-label="View Training Data"
              >
                <img
                  src={Reports} // Assuming Reports is the icon you want for "View Training Data"
                  alt="View Training Data"
                  style={{ width: '30px', height: '30px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Reports">
              {/* IconButton for "View Reports" */}
              <IconButton
                onClick={() => {
                  fetchTrainingResult(record?.modelId as string);
                  setCurrentInferenceID(record.inferenceID as string);
                  setViewMode(true);
                  onChange(2);
                }}
                disabled={
                  record.TrainingStatus === 'FAILED' ||
                  record?.TrainingStatus === 'QUEUED' ||
                  record.isDeleted != false
                }
                aria-label="View Reports"
              >
                <img
                  src={
                    record.TrainingStatus === 'FAILED' ||
                    record?.TrainingStatus === 'QUEUED'
                      ? reportsNotFound
                      : ReportsData
                  }
                  alt="View Reports"
                  style={{ width: '30px', height: '25px' }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];

  const fetchTrainingResult = async (inference_id: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/training/get_training_inference_details?model_id=${inference_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setInferenceReport(response?.data);
    } catch (error) {
      console.error('Error fetching training result:', error);
    }
  };

  const rows =
    data?.data?.map(item => ({
      ModelName: item.model_name,
      ModelArchitecture: item?.model_architecture,
      TotalImages: item?.total_image_count,
      totalClasses: item?.class_count || 0,

      Traintestsplit: item.train_spilt,
      Batchsize: item.batch_size,
      Learningrate: item.learning_rate,
      Epochs: item.total_epochs,
      TrainingStatus: item.test_accuracy
        ? item.status
        : fromWebsocket?.model_id === item.model_id &&
          fromWebsocket?.progress !== undefined
        ? fromWebsocket.progress
        : item.status
        ? item.status
        : 'COMPLETED',
      TestAccuracy: item.test_accuracy
        ? (item.test_accuracy * 100).toFixed(2) + ' %'
        : fromWebsocket?.model_id === item.model_id &&
          fromWebsocket?.['Test accuracy']
        ? (fromWebsocket['Test accuracy'] * 100).toFixed(2) + ' %'
        : '0 %',
      inferenceID: item.inference_id,
      baseModel: item.old_model_name || '-',
      modelId: item.model_id || '-',
      isDeleted: item?.is_deleted
    })) || [];

  return (
    <Card
      style={{
        height: '82vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {configs && step != 'INITIAL' && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h6" fontWeight="bold">
            Reports
          </Typography>

          <SelectInput
            label="Select Configuration"
            placeholder="Select Configuration"
            variant="outlined"
            width={'250px'}
            name="configurationType"
            options={configs?.map(i => ({ label: i.name, value: i.id }))}
            value={selectedConfig || ''}
            onChange={(value: string) => {
              dispatch(setSelectedConfiguration(value));
            }}
          />
        </Stack>
      )}

      {configs && configs.length == 0 && (
        <Stack style={{ textAlign: 'center' }}>
          <Typography>Please add configuration to train a model</Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig == '' && (
        <Stack
          style={{
            height: '41vh',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={Inference}
            alt="Inference"
            style={{ width: '350px', height: '400px' }}
          />
          <Typography style={{ color: '#707070', fontSize: '26px' }}>
            Select Configuration type to proceed with the process{' '}
          </Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig != '' && (
        <Box>
          {step == 'TABLE' && (
            <Box>
              <Box style={{ height: '65vh', overflowY: 'scroll' }}>
                {rows && (
                  <Table
                    // alignRows='center'
                    stickyHeader={true}
                    columns={columns}
                    rows={rows}
                    striped={false}
                  />
                )}
              </Box>

              {/* <Stack direction={'row'} justifyContent={'center'}>
                <Button
                  onClick={() => {
                    setViewMode(false);
                    setStep('INITIAL');
                  }}
                  style={{ width: '310px', height: '45px' }}
                >
                  {' '}
                  START TRAINING
                </Button>
              </Stack> */}
            </Box>
          )}
        </Box>
      )}

      <Stack spacing={2} direction="column" alignItems="center">
        <Typography
          variant="body2"
          style={{ color: 'red', fontWeight: '16px' }}
        >
          {errorMessage}
        </Typography>
      </Stack>
    </Card>
  );
};
