import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';
import { Main } from './main';
import { DropClass } from './dropClass';
import { ViewDetails } from 'pages/train/viewDetails';

import { useLocation } from 'react-router-dom';
import PreprocessedData from 'components/filemanager/preProcessedData';
import { useSnack } from 'plugins/snack';
import {
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest,
  TrainingResultSchema
} from 'schemas';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { v4 } from 'uuid';
import {
  useGetModelList,
  useGetObjectsByInference,
  useListConfigurations,
  useSyncObjectByInferenceid
} from 'api/sdk';
import { setAllConfigs } from 'store/configSlice';
import { useDispatch } from 'react-redux';
export const IncrementalTraining: React.FC = () => {
  const [step, setStep] = useState<number>(1);

  const onChange = (num: number) => {
    setStep(num);
  };
  const { mutateAsync: syncObject } = useSyncObjectByInferenceid();

  const [models, setModels] = useState<TrainingResultSchema[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjects] = useState<ObjSchema[]>([]);
  const [objectsinModels, setObjectsinModels] = useState<string[]>([]);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();
  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const dispatch = useDispatch();

  const { isLoading, isError, refetch } = useListConfigurations();
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const snack = useSnack();
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const configs = useSelector((state: RootState) => state.config.allConfigs);

  const { refetch: refetchModels } = useGetModelList({
    config_id: selectedConfig || ''
  });

  const { refetch: currentObjectsRefetch, isLoading: objectsloading } =
    useGetObjectsByInference(currentInferenceID || '', {
      pipeline_satus: false
    });

  useEffect(() => {
    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
        setObjectAll(res?.data?.data);
      });
    }
  }, [currentInferenceID]);
  useEffect(() => {
    refetchModels().then(res => {
      setModels(res.data?.data || []);
    });
  }, [selectedConfig]);

  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      inference_id: inferenceid ? inferenceid : obj.inference_id,
      configuration_id: selectedConfig || '',
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      inferenceId: inferenceid ? inferenceid : currentInferenceID || '',
      params: { config_id: selectedConfig || '' }
    });
  };
  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          models={models || []}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          setObjectsinModels={setObjectsinModels}
        />
      )}

      {step == 2 && (
        <PreprocessedData
          type="TRAINING"
          selectedConfig={selectedConfig || ''}
          configs={configs || []}
          onSelect={(e, o) => {
            setCurrentInferenceID(e);
            setSelectedObjects(o || []);

            if (o) {
              const isNoOverlap = o.every(
                x => !objectsinModels.includes(x.classname)
              );
              const isSubset = o.every(x =>
                objectsinModels.includes(x.classname)
              );
              const hasPartialOverlap = o.some(
                x => !objectsinModels.includes(x.classname)
              );

              if (isNoOverlap) {
                snack({
                  message:
                    'There are no overlaps with the selected models. Please select a different dataset.',
                  severity: 'error'
                });
                return;
              }

              if (isSubset) {
                setStep(3);
                return;
              }

              if (hasPartialOverlap) {
                setOpen(true);
                return;
              }
            }

            setStep(3);
          }}
          onCancel={() => {
            setStep(1);
          }}
        />
      )}
      {step == 3 && (
        <ViewDetails
          onChange={onChange}
          selectedConfig={selectedConfig}
          objects={currentObjects}
          configs={configs}
          objectAll={objectAll}
          mode={'INCREMENTAL_TRAINING'}
          currentInferenceID={currentInferenceID}
          selectedModel={selectedModel}
          setStep={setStep}
        />
      )}

      <DropClass
        open={open}
        onClose={() => setOpen(false)}
        modelsClass={objectsinModels}
        selectedObjects={selectedObjects}
        selectedConfig={selectedConfig || ''}
        handleSync={handleSync}
        setStep={setStep}
        setCurrentInferenceID={setCurrentInferenceID}
      />
    </>
  );
};
