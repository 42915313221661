// function to get video duration from video url
export const getVideoDuration = (url: string) => {
  const video = document.createElement('video');
  video.src = url;
  video.load();
  const { duration } = video;
  video.pause();
  video.remove();
  return duration;
};

export const getVideoMeta = async (url: string) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = url;
    video.load();
    video.onloadedmetadata = () => {
      const { duration, videoWidth, videoHeight } = video;
      video.pause();
      video.remove();
      resolve({ duration, videoWidth, videoHeight });
    };
    video.onerror = error => {
      reject(error);
    };
  });
};
