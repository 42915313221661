import { Checkbox, styled } from '@mui/material';

export const Check = styled(Checkbox)<{ overlap?: boolean }>(
  ({ theme, overlap }) => ({
    padding: 0,
    ...(overlap && {
      position: 'absolute',
      marginTop: 2,
      marginLeft: 2,
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0
    })
  })
);

export default Check;
