import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import {
  Stack,
  Box,
  Grid,
  Divider,
  Select,
  IconButton,
  TextField,
  Pagination
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Thumbnail from 'components/Thumbnail';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SelectInput from 'components/Form/Inputs/SelectInput';
import {
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest,
  ObjSchemaImages,
  ImgSchema,
  LabelAsisitRequestQualityControlIncremental
} from 'schemas';
import styled from 'styled-components';
import {
  useCreateObjects,
  useGetObjectsByInference,
  useStartIncremetal,
  useSyncObjectByInferenceid,
  useSaveLabaelAssist,
  useDeleteObjectInferenceId
} from 'api/sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import Default from '../../assets/images/default.svg';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useSnack } from 'plugins/snack';
import DeleteModal from './deleteInferenceID';

interface LabelAssist1Props {}

export const LabelAssist1: React.FC<LabelAssist1Props> = ({}) => {
  const location = useLocation();

  const [data, setData] = useState<ObjSchema[]>([]);
  const [selectedClass, setSelectedClass] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [currentInferenceID, setCurrentInferenceID] = useState<string>('');
  const [previewImageID, setPreviewImageID] = useState<number>(0);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [createNewObj, setCreateNewObj] = useState<boolean>(false);
  const [newObjeName, setNewObjeName] = useState<string>('');
  const [discription, setDiscription] = useState<string>('');
  const [DeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [incrementedState, setIncrementedState] = useState<ObjSchema[]>([]);
  const [type, setType] = useState<string>('');
  const [check, setCheck] = useState<boolean>(false);
  const [trainingModal, setTrainingModal] = useState<boolean>(false);
  const [currentModelID, setCurrentModelID] = useState<string>('');
  const { mutateAsync: saveData, isLoading: saveLoading } =
    useSaveLabaelAssist();

  const snack = useSnack();
  useEffect(() => {
    if (data.length > 0) {
      const allImages = data
        .flatMap(item => item.images ?? [])
        .filter((image): image is ImgSchema => image !== null);

      const allImagesObject: ObjSchema = {
        category: 'All Categories',
        classname: 'ALL',
        configuration_id: 'ALL_CONFIG',
        groupname: 'All Groups',
        id: 'ALL_ID',
        images: allImages || [],
        inference_id: 'ALL_INFERENCE',
        mode: 'ALL_MODE'
      };

      setIncrementedState([allImagesObject, ...data]);
      setSelectedClass('ALL');
    }
  }, [data, currentInferenceID]);

  const [moveClass, setMoveClass] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByInferenceid();

  useEffect(() => {
    setCurrentInferenceID(location.state.inferenceid);
    setType(location.state.type);
    setCurrentModelID(location?.state?.modelID || '');
  }, [location]);

  const {
    data: totalData,
    refetch,
    isLoading: dataLoading
  } = useGetObjectsByInference(currentInferenceID, {
    pipeline_satus: false
  });

  const { mutateAsync: startIncremental } = useStartIncremetal();

  useEffect(() => {
    if (currentInferenceID != '') {
      refetch().then(res => {
        setData(res.data?.data.object_details || []);

        if (res.data?.data.object_details[0].mode != 'INFERENCE') {
          setCheck(true);
        } else {
          setCheck(false);
        }

        if (res.data?.data.object_details[1].classname) {
          setMoveClass(res.data?.data.object_details[1].classname || '');
        }
      });
    }
  }, [currentInferenceID]);

  const currentObject = useMemo(() => {
    return incrementedState?.find(i => i.classname == selectedClass);
  }, [data, selectedClass, currentInferenceID, incrementedState]);

  // useEffect(() => {
  //   setPage(1);
  //   setPreviewImageID(0);
  // }, [currentObject]);
  // console.log(previewImageID, page, 'preview');

  const onCheckItem = (checked: boolean, item: string) => {
    if (checked) {
      setCheckedItems(prev => [...prev, item]);
    } else {
      setCheckedItems(prev => prev.filter(i => i !== item));
    }
  };

  const handelChangeImageIndex = (id: number) => {
    setPreviewImageID(id);
  };
  const previewImageSrc = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = incrementedState?.find(i => i.classname === selectedClass)
      ?.images?.[previewImageID + page * 100];
    return image?.thumb_url || Default;
  }, [data, selectedClass, previewImageID, showOverlay, incrementedState]);

  const handlePrevImage = () => {
    setPreviewImageID(prev => Math.max(prev - 1, 0));
  };

  const handleNextImage = () => {
    setPreviewImageID(prev => prev + 1);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  const totalImageCount = useMemo(() => {
    let count = 0;
    for (let x of data) {
      if (x?.images?.length) {
        count = count + x?.images?.length || 0;
      }
    }
    return count;
  }, [data]);
  const handelMoveImage = (
    fromObjectIndex: number,
    toObjectIndex: number,
    checkedItems: string[]
  ) => {
    if (data) {
      setCheckedItems([]);

      const fromObject = data[fromObjectIndex];
      const toObject = data[toObjectIndex];
      fromObject.images = fromObject.images || [];
      toObject.images = toObject.images || [];

      const filteredImages = fromObject.images.filter(image =>
        checkedItems.includes(image.id)
      );
      toObject.images.push(...filteredImages);

      fromObject.images = fromObject.images.filter(
        image => !checkedItems.includes(image.id)
      );

      const updatedObjects = [...data];
      updatedObjects[fromObjectIndex] = fromObject;
      updatedObjects[toObjectIndex] = toObject;
      setData(updatedObjects);

      snack({
        message: `${checkedItems.length} images moved to ${toObject.classname}`,
        severity: 'success'
      });
    }
  };

  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    console.log('datadata', data);
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      inference_id: inferenceid,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      images:
        obj.images?.map(imagePath => ({
          id: imagePath.id,
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'TRAINING'
    }));

    await syncObject({
      data: updatedObjects,
      inferenceId: inferenceid,
      params: { config_id: configID || '' }
    });
  };

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  interface FormValues {
    modelName: string;
    trainTestSplit: number;
    batchSize: number;
    learningRate: number;
    epochs: number;
  }

  const initialValues: FormValues = {
    modelName: '',
    trainTestSplit: 80,
    batchSize: 8,
    learningRate: 0.001,
    epochs: 10
  };
  const validationSchema = Yup.object({
    modelName: Yup.string().required('Model Name is required'),
    trainTestSplit: Yup.number()
      .required('Train Test Split is required')
      .min(50, 'Train Test Split must be between 50 -95')
      .max(95, 'Train Test Split must be between 50 -95'),
    batchSize: Yup.number()
      .required('Batch Size is required')
      .min(1, 'Batch Size must be between 1-32')
      .max(32, 'Batch Size must be between 1-32'),
    learningRate: Yup.number()
      .required('Learning Rate is required')
      .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
      .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
    epochs: Yup.number()
      .required('Epochs is required')
      .min(1, 'Epochs must be between 1-500')
      .max(500, 'Epochs must be between 1-500')
  });

  const handleSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>
  ) => {
    const data2: LabelAsisitRequestQualityControlIncremental = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,

      inference_id: currentInferenceID || '',
      config_id: data[0].configuration_id || '',
      train_test_split_percentage: values.trainTestSplit,
      model_id: currentModelID
    };

    try {
      await startIncremental({ data: data2 });
      navigate('/train');
    } catch (error: any) {
      if (error.response.data.detail) {
        setFieldError('modelName', error.response.data.detail);
      }
    }
  };

  const checkForlastIndex = (previewImageid: number, page: number): boolean => {
    const lastPage = Math.floor(
      (currentObject?.images?.length as number) / 100 || 1
    );

    if (page == lastPage) {
      const total = currentObject?.images?.length as number;

      if (previewImageid + 1 + page * 100 >= total) {
        return true;
      } else return false;
    } else {
      console.log(previewImageid, 'previewImageid');
      if (previewImageid >= 99) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkForTraining = (): boolean => {
    if (data.length < 2) {
      snack({
        message: 'You cannot start training without at least 2 classes',
        severity: 'error'
      });
      return false;
    }

    for (const item of data) {
      if (!item.images?.length) {
        snack({
          message: `Class "${item.classname}" has 0 images`,
          severity: 'error'
        });
        return false;
      }

      if (item.images.length < 50) {
        snack({
          message: `Class "${item.classname}" has less than 50 images`,
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Button
        version="transparent"
        onClick={() => {
          setDeleteModalOpen(true);
        }}
      >
        {' '}
        <ArrowBackIosIcon /> BACK{' '}
      </Button>
      <Grid container>
        <Grid item xs={6} padding={1}>
          <Grid
            style={{
              backgroundColor: '#fff',
              height: 'calc(100vh - 200px)',
              padding: '10px'
            }}
          >
            <Typography variant="h6"> Data set creation </Typography>
            <Typography> Total Images : {totalImageCount}</Typography>

            <Stack direction={'row'} alignItems={'center'}>
              {data && (
                <SelectInput
                  width={'60%'}
                  fullwidth
                  name="select"
                  value={selectedClass || ''}
                  variant="outlined"
                  options={
                    incrementedState?.map(i => {
                      return { value: i.classname, label: i.classname };
                    }) || []
                  }
                  onChange={e => {
                    setSelectedClass(e);
                    setPage(0);
                    setPreviewImageID(0);
                  }}
                />
              )}
              {type != 'Incremental' && (
                <Button
                  version="light"
                  onClick={() => {
                    setCreateNewObj(true);
                  }}
                >
                  Add new Class
                </Button>
              )}
            </Stack>
            <Grid sx={{ height: '75%', overflowY: 'scroll' }} mt={1}>
              {dataLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    height: '75%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>
                      please wait till the data is loading
                    </Typography>
                  </Box>
                </Box>
              ) : (
                currentObject?.images
                  ?.slice(page * 100, (page + 1) * 100)
                  .map((item, index) => (
                    <Thumbnail
                      id={item.id}
                      title=""
                      showImageGallery={true}
                      key={index}
                      thumbnailUrl={item.thumb_url || ''}
                      index={index}
                      checkable={selectedClass != 'ALL'}
                      checked={checkedItems.includes(item.id)}
                      onCheck={checked => onCheckItem(checked, item.id)}
                      handelChangeImageIndex={handelChangeImageIndex}
                      type={previewImageID == index ? true : false}
                    />
                  ))
              )}
            </Grid>

            <Stack
              spacing={2}
              justifyContent={'center'}
              direction={'row'}
              mt={1.5}
            >
              <Pagination
                page={page}
                onChange={(e, v) => {
                  setPage(v);
                  setPreviewImageID(0);
                }}
                count={Math.floor(
                  (currentObject?.images?.length as number) / 100 || 1
                )}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={6} padding={1}>
          <Grid
            style={{
              backgroundColor: '#FFF',
              height: 'calc(100vh - 200px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '10px'
            }}
          >
            <Typography>
              Showing Image : {previewImageID + page * 100 + 1} /{' '}
              {currentObject?.images?.length}
            </Typography>

            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ height: 'calc(100vh - 325px)' }}
            >
              <IconButton
                disabled={previewImageID <= 0}
                onClick={() => {
                  setPreviewImageID(prev => prev - 1);
                }}
              >
                <ArrowCircleLeftIcon />
              </IconButton>
              <img
                src={previewImageSrc || ''}
                alt=""
                style={{
                  objectFit: 'contain',
                  maxWidth: 'calc(100% - 100px)',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto'
                }}
              />
              <IconButton
                disabled={checkForlastIndex(previewImageID, page)}
                onClick={() => {
                  // checkForlastIndex(previewImageID, page);
                  setPreviewImageID(prev => prev + 1);
                }}
              >
                <ArrowCircleRightIcon />
              </IconButton>
            </Stack>
            {data.length > 1 && (
              <Stack
                direction={'row'}
                spacing={4}
                alignItems={'center'}
                padding={1}
                justifyContent={'center'}
              >
                {data && (
                  <SelectInput
                    disabled={selectedClass == 'ALL'}
                    width={'250px'}
                    maxWidth={'250px'}
                    name="select"
                    variant="outlined"
                    value={moveClass || ''}
                    options={
                      data
                        ?.filter(i => i.classname != selectedClass)
                        .map(i => {
                          return { value: i.classname, label: i.classname };
                        }) || []
                    }
                    onChange={e => {
                      setMoveClass(e);
                    }}
                  />
                )}
                <Button
                  disabled={selectedClass == 'ALL'}
                  onClick={() => {
                    const fromObjIndex = data?.findIndex(
                      i => i.classname == selectedClass
                    );
                    const toObjIndex = data?.findIndex(
                      i => i.classname == moveClass
                    );
                    if (fromObjIndex != -1 && toObjIndex != -1) {
                      handelMoveImage(fromObjIndex, toObjIndex, checkedItems);
                    }
                  }}
                >
                  Move
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>

      {type == 'Data' && (
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button
            version="light"
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            {' '}
            cancel
          </Button>
          <Button
            loading={syncLoading || saveLoading}
            onClick={() => {
              handleSync(
                data,
                data[0].configuration_id,
                currentInferenceID
              ).then(res => {
                if (check && checkForTraining()) {
                  saveData({
                    params: { inference_id: currentInferenceID }
                  }).then(res => {
                    navigate('/Media');
                  });
                }

                if (!check) {
                  console.log('am not here');
                  saveData({
                    params: { inference_id: currentInferenceID }
                  }).then(res => {
                    navigate('/Media');
                  });
                }
              });
            }}
          >
            {' '}
            save
          </Button>
        </Stack>
      )}

      {type == 'Incremental' && (
        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button version="light"> Cancel </Button>
          <Button
            onClick={() => {
              setTrainingModal(true);
            }}
          >
            {' '}
            Start Incremental Training{' '}
          </Button>
        </Stack>
      )}

      <Modal
        open={createNewObj}
        onClose={() => {
          setCreateNewObj(false);
        }}
      >
        <Typography mb={1} mt={1}>
          Enter New Class Name
        </Typography>

        <TextField
          variant="outlined"
          placeholder="Enter Class Name"
          fullWidth
          size="small"
          value={newObjeName}
          onChange={e => {
            setNewObjeName(e.target.value);
          }}
        />

        <TextField
          style={{ marginTop: '12px' }}
          variant="outlined"
          placeholder="Enter Discription"
          fullWidth
          size="small"
          value={discription}
          onChange={e => {
            setDiscription(e.target.value);
          }}
        />

        <Stack direction={'row'} justifyContent={'center'} spacing={1} mt={1}>
          <Button
            version="light"
            onClick={() => {
              setDiscription('');
              setNewObjeName('');
              setCreateNewObj(false);
            }}
          >
            cancel
          </Button>
          <Button
            disabled={newObjeName.length == 0 || syncLoading}
            onClick={() => {
              handleSync(
                data,
                data[0].configuration_id,
                currentInferenceID
              ).then(res => {
                if (
                  totalData?.data.object_details
                    .map(i => i.classname)
                    .includes(newObjeName)
                ) {
                  snack({
                    message: `classname ${newObjeName} already exists`,
                    severity: 'error'
                  });
                  return;
                }
                CreateObjects({
                  configId: data[0].configuration_id,
                  data: [
                    {
                      classname: newObjeName,
                      configuration_id: data[0].configuration_id,
                      groupname: data[0].groupname,
                      mode: 'TRAINING',
                      inference_id: data[0].inference_id || ''
                    }
                  ]
                }).then(res => {
                  refetch().then(response => {
                    setData(response.data?.data.object_details || []);
                    setCreateNewObj(false);
                  });
                });
              });
            }}
          >
            save
          </Button>
        </Stack>
      </Modal>

      <Modal
        open={trainingModal}
        onClose={() => setTrainingModal(false)}
        title="Training Summary"
      >
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={2} alignItems="center">
                  {/* Total Labelled Images */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Labelled Images
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      :{totalData?.data.labeled_count}
                    </Typography>
                  </Grid>

                  {/* Total Number of Classes */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total no. of classes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      {
                        Object.keys(totalData?.data.classname_counts || {})
                          .length
                      }
                    </Typography>
                  </Grid>

                  {/* Model Name */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Model Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="modelName">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          type="text"
                          variant="outlined"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* Train Test Split */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Train Test Split
                    </Typography>
                    <Typography variant="caption">(50 to 95)</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="trainTestSplit">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="outlined"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* Batch Size */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Batch Size
                    </Typography>
                    <Typography variant="caption">(1 to 32)</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="batchSize">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="outlined"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* Learning Rate */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Learning Rate
                    </Typography>
                    <Typography variant="caption">(0.00001 to 0.1)</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="learningRate">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="outlined"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* Epochs */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Epochs
                    </Typography>
                    <Typography variant="caption">(1 to 500)</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="epochs">
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          type="number"
                          variant="outlined"
                          error={Boolean(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* Submit Button */}
                  <Stack
                    direction="row"
                    justifyContent={'center'}
                    width={'100%'}
                    mt={2}
                  >
                    <Button type="submit" disabled={isSubmitting}>
                      Start Training
                    </Button>
                  </Stack>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <DeleteModal
        deleteModal={DeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        currentInferenceId={currentInferenceID}
      />
    </>
  );
};
