import { Box, IconButton, Modal as MModal, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { CloseIcon } from '../icons';
import { Typography } from '../Typography/Typography';

// import { CloseIcon } from 'components/icons';

interface IModalProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string | number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  height?: string;
  titleMargin?: string;
  padding?:string;
}

/* eslint-disable */
const StyledModalBox = styled(Box)<Pick<IModalProps, 'size' | 'height'| 'padding'>>(
  ({ size, height , padding}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 100px)',
    maxWidth:
      size === 'sm' ? 400 : size === 'md' ? 600 : size === 'lg' ? 800 : 1300,
    background: '#E6E6E6',
    height: height || 'auto',
    padding: padding || 20
  })
);
/* eslint-disable */

/**
 * Modal
 */
const Modal: React.FC<IModalProps & PropsWithChildren> = ({
  title,
  open,
  onClose,
  children,
  size = 'md',
  height,
  titleMargin = '10px',
  padding = '20px'
}) => {
  return (
    <>
      <MModal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onClose}
      >
        <>
          <StyledModalBox size={size} height={height} padding={padding} >
            <>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: -35,
                  top: -35,
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
              {title && (
                <Typography
                  variant="subtitle1"
                  fontWeight={'bold'}
                  component="h2"
                  style={{
                    marginBottom: titleMargin,
                    fontWeight: 'bold '
                  }}
                >
                  {title}
                </Typography>
              )}

              {children}
            </>
          </StyledModalBox>
        </>
      </MModal>
    </>
  );
};

export default Modal;
