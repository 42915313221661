import { Grid } from '@mui/material';
import React from 'react';
import ObjectCategorySkeleton from './objectCategorySkeleton';

const ObjectCategoryPageSkeleton: React.FC = () => {
  return (
    <Grid item xs={12} mt={4}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <ObjectCategorySkeleton />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <ObjectCategorySkeleton />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <ObjectCategorySkeleton />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <ObjectCategorySkeleton />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ObjectCategoryPageSkeleton;
