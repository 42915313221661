import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from '../Typography/Typography';
import Card from '../Card';
import Actions from './Actions';
import { useCreatePreProcessedObjects } from 'api/sdk';
import {
  Box,
  CircularProgress,
  Link,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Grid,
  Divider,
  IconButton,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import FolderType from './FolderType';

import {
  useGetDirectoryContentsFileManagerDirectoryContentsGet,
  useSearchDirectoryFileManagerSearchDirectoryGet
} from '../../api/media';
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
  defaultDropAnimation,
  DropAnimation,
  MouseSensor,
  DragEndEvent,
  DragStartEvent
} from '@dnd-kit/core';
import Folder from '../../assets/images/Folder.png';
import AddIcon from '@mui/icons-material/Add';
import {
  Contents,
  DirectoriesContents,
  DirectoriesContentsResponse,
  FileType
} from '../../schemasMedia';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import CustomIconButton from '../Button/IconButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SearchTab from './SearchTab';
import { Button } from 'components/Button/Button';
import styled from 'styled-components';
import SelectionModal from './selectionModal';
import ContextMenu from './contextMenu';
import DeleteModal from './DeleteModal';
import Datalist from './Datalist';
import ConfigSelectionModal from './configSelectionModal';
import Droppable from './droppable';
import { ConfigSchema } from 'schemas';
import DraggableItem from './Draggable';
import { useSnack } from 'plugins/snack';

interface FileManagerProps {}
interface createDateset {
  unlabelled: string[];
  labelled: string[];
}
interface draggedFolders {
  className: string;
  numberOfImages: number;
  path: string;
}

interface createDatesetUser {
  unlabelled: draggedFolders[];
  labelled: draggedFolders[];
  semilabelled: draggedFolders[];
}

const FileManager: React.FC<FileManagerProps> = ({}) => {
  const [currentDirectory, setCurrentDirectory] = useState<string>('/');
  const [directoryForDragndrop, setDirectoryForDragndrop] = useState<{
    folderName: string;
    path: string;
  }>({ folderName: '/', path: '/' });

  const [nextDirectory, setNextDirectory] = useState<string[]>([]);
  const [files, setFiles] = useState<Contents[]>([]);
  const [sortedFiles, setSortedFiles] = useState<Contents[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Contents[]>([]);
  const [editName, setEditName] = useState<boolean>(false);
  const [copyFiles, setCopyFiles] = useState<Contents[]>([]);
  const [type, setType] = useState<FileType>('copy');
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchQueryText, setSearchQueryText] = useState<string>('');
  const [fromDirectory, setFromDirectory] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('Name');
  const [sortType, setSortType] = useState<string>('Ascending');
  const [DeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectModal, setSelectModal] = useState<boolean>(false);
  const [createDataSet, setCreateDataset] = useState<boolean>(false);
  const [shouldAnimateReturn, setShouldAnimateReturn] = useState(false);
  const [typeofData, setTypeofData] = React.useState<string>('labelled');
  const [mode, setMode] = useState<string>('');
  const [configSelectionModalOpen, setConfigSelectionModalOpen] =
    useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [activeId, setActiveId] = useState<string | null>(null);

  const [datasetName, setDatasetName] = useState<createDatesetUser>({
    unlabelled: [],
    labelled: [],
    semilabelled: []
  });
  const snack = useSnack();

  const { refetch: refetchDirectory, isLoading: filesloading } =
    useGetDirectoryContentsFileManagerDirectoryContentsGet({
      directory_path: currentDirectory || '/'
    });

  const { data: drapanddrop, refetch: refetchdrapanddrop } =
    useGetDirectoryContentsFileManagerDirectoryContentsGet({
      directory_path: directoryForDragndrop.path || '/'
    });

  const {
    data,
    refetch: refetchSearchedFiled,
    isLoading: searchLoading
  } = useSearchDirectoryFileManagerSearchDirectoryGet({
    directory_name: searchQueryText
  });

  const handleRefetchDirectory = (select?: Contents[]) => {
    refetchDirectory().then(res => {
      setFiles(res.data?.data.contents || []);
      select && setSelectedFiles(select);
      select && setEditName(true);
    });
  };

  useEffect(() => {
    refetchDirectory().then(res => {
      setFiles(res.data?.data.contents || []);
    });
  }, [currentDirectory]);

  const sortFiles = () => {
    const sortedFiles = [...files].sort((a, b) => {
      let valA, valB;

      switch (sortBy) {
        case 'Name':
          valA = a.name?.toLowerCase() ?? '';
          valB = b.name?.toLowerCase() ?? '';
          break;
        case 'Date Created':
          valA = new Date(a.created_time ?? '').getTime();
          valB = new Date(b.created_time ?? '').getTime();
          break;
        case 'Date Modified':
          valA = new Date(a.modified_time ?? '').getTime();
          valB = new Date(b.modified_time ?? '').getTime();
          break;
        case 'Size':
          valA = a.size ?? 0;
          valB = b.size ?? 0;
          break;
        default:
          return 0;
      }

      if (sortType === 'Ascending') {
        return valA > valB ? 1 : -1;
      } else {
        return valA < valB ? 1 : -1;
      }
    });

    setSortedFiles(sortedFiles);
  };

  useEffect(() => {
    sortFiles();
  }, [sortBy, sortType, files]);

  const handleSelectFile = (file: Contents) => {
    if (selectedFiles.map(i => i.name).includes(file.name)) {
      setSelectedFiles(selectedFiles.filter(i => i.name !== file.name));
    } else {
      setSelectedFiles(prev => [...prev, file]);
    }
  };

  const selectCurrentDirectory = (path: string) => {
    setCurrentDirectory(path);
    setNextDirectory([]);
  };

  const onTextChanged = (text: string) => {
    setSearchQueryText(text);
  };

  useEffect(() => {
    if (searchQueryText.length > 0) {
      setFiles([]);
      setSearchMode(true);
      refetchSearchedFiled().then(res => {
        setFiles(res.data?.data.contents || []);
      });
    } else {
      setFiles([]);
      handleRefetchDirectory();
      setSearchMode(false);
    }
  }, [searchQueryText]);

  const StyledDiv = styled('div')<{}>(() => ({
    width: '130px',
    padding: '10px',
    position: 'relative',
    backgroundColor: 'fff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }));
  const [anchorPosition, setAnchorPosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAnchorPosition({ top: event.clientY, left: event.clientX });
  };

  const handleClose = () => {
    setAnchorPosition(null);
  };

  const open = Boolean(anchorPosition);
  const id = open ? 'simple-popover' : undefined;

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setShouldAnimateReturn(false);
    if (typeof active.id === 'string') {
      setActiveId(active.id);
    } else {
      console.error('Unexpected type for active.id:', typeof active.id);
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over || over.id !== 'droppable') {
      setShouldAnimateReturn(true);
      console.log('Dropped outside a valid droppable area');
      return;
    }

    const currentFile = files.find(i => i.name == active.id);

    setDirectoryForDragndrop({
      path: currentFile?.path || '',
      folderName: active.id as string
    });
  };

  useEffect(() => {
    if (directoryForDragndrop.path != '/') {
      refetchdrapanddrop().then(response => {
        addPathsToCreateDataset(
          directoryForDragndrop,
          response?.data?.data,
          typeofData
        );
      });
    }
  }, [directoryForDragndrop]);

  function addPathsToCreateDataset(
    mainFilePath: { folderName: string; path: string },
    response: DirectoriesContentsResponse | undefined,
    type: string
  ) {
    console.log('called', response);
    if (response) {
      let subFolder;

      if (response.num_subfolders == 0) {
        subFolder = false;
      } else {
        subFolder = true;
      }
      if (response.num_files == 0) {
        snack({
          message: 'cannot upload a empty folder',
          severity: 'error'
        });
      } else {
        let alreadyExists = false;

        for (const key in datasetName) {
          const datasetKey = key as keyof createDatesetUser;
          if (
            datasetName[datasetKey]
              .map(i => i.className)
              .includes(mainFilePath.folderName)
          ) {
            alreadyExists = true;
            break;
          }
        }

        if (alreadyExists) {
          snack({
            message: 'class name already exists',
            severity: 'error'
          });
        } else {
          if (type == 'Unlabelled') {
            setDatasetName(prev => {
              return {
                labelled: prev.labelled,
                semilabelled: prev.semilabelled,
                unlabelled: [
                  ...prev.unlabelled,
                  {
                    className: mainFilePath.folderName,
                    numberOfImages: response.num_files as number,
                    path: mainFilePath.path
                  }
                ]
              };
            });
          }

          if (type == 'labelled' && !subFolder) {
            setDatasetName(prev => {
              return {
                labelled: [
                  ...prev.labelled,
                  {
                    className: mainFilePath.folderName,
                    numberOfImages: response.num_files as number,
                    path: mainFilePath.path
                  }
                ],
                semilabelled: prev.semilabelled,
                unlabelled: prev.unlabelled
              };
            });
          }

          if (type == 'labelled' && subFolder) {
            for (let res in response.folder_file_count) {
              if (datasetName.labelled.map(i => i.className).includes(res)) {
                snack({
                  message: `Classname ${res} already exists`,
                  severity: 'error'
                });
                return;
              } else {
                setDatasetName(prev => {
                  return {
                    labelled: [
                      ...prev.labelled,
                      {
                        className: res,
                        numberOfImages: response?.folder_file_count?.[res],
                        path:
                          response?.contents.find(i => i.name == res)?.path ||
                          ''
                      }
                    ],
                    semilabelled: prev.semilabelled,
                    unlabelled: prev.unlabelled
                  };
                });
              }
            }

            let count = response.contents.filter(
              i => i.type != 'directory'
            ).length;
            if (count > 0) {
              setDatasetName(prev => {
                return {
                  semilabelled: [
                    ...prev.semilabelled,
                    {
                      className: mainFilePath.folderName,
                      numberOfImages: count,
                      path: mainFilePath.path
                    }
                  ],
                  labelled: prev.labelled,
                  unlabelled: prev.unlabelled
                };
              });
            }
          }
        }
      }
    }
  }

  const sensors = useSensors(mouseSensor);

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
    duration: shouldAnimateReturn ? 250 : 0,
    easing: 'ease'
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newChange: string
  ) => {
    setTypeofData(newChange);
  };
  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '0px',
    width: '100%',

    color: '#000',

    borderBottom: '1px solid #000',

    '&.Mui-selected': {
      borderTop: '1px solid #000',
      borderLeft: '1px solid #000',
      borderRight: '1px solid #000',
      borderBottom: '1px solid #CCD8DF',
      backgroundColor: '#CCD8DF',
      color: '#000'
    },
    '&.Mui-selected:hover': {}
  }));
  const labelledCount = useMemo(() => {
    let labelledCount = 0;
    for (let count of datasetName.labelled) {
      labelledCount = labelledCount + count.numberOfImages;
    }
    return labelledCount;
  }, [datasetName]);

  const unlabelledCount = useMemo(() => {
    let unlabelledCount = 0;
    for (let count of datasetName.unlabelled) {
      unlabelledCount = unlabelledCount + count.numberOfImages;
    }
    for (let count of datasetName.semilabelled) {
      unlabelledCount = unlabelledCount + count.numberOfImages;
    }
    return unlabelledCount;
  }, [datasetName]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box>
        <Box padding={1} bgcolor={'#fff'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Tooltip title="Previous" arrow>
              <CustomIconButton
                icon={MdNavigateBefore}
                disabled={currentDirectory == '/'}
                onClick={() => {
                  setCurrentDirectory(
                    currentDirectory.split('/').slice(0, -1).join('/')
                  );
                  const last = currentDirectory.split('/').slice(-1)[0];

                  if (nextDirectory.length > 0) {
                    setNextDirectory(prev => [...prev, last]);
                  } else {
                    setNextDirectory([last]);
                  }
                }}
              />
            </Tooltip>{' '}
            <Tooltip title="Next" arrow>
              <CustomIconButton
                icon={MdNavigateNext}
                disabled={nextDirectory.length == 0}
                onClick={() => {
                  setCurrentDirectory(
                    currentDirectory +
                      '/' +
                      nextDirectory[nextDirectory.length - 1]
                  );

                  setNextDirectory(prev => prev.slice(0, -1));
                }}
              />
            </Tooltip>
            <Typography> Data Storage</Typography>
            <Typography>
              {' '}
              <Breadcrumbs
                separator={<MdNavigateNext fontSize="small" />}
                aria-label="breadcrumb"
              >
                {currentDirectory.split('/').map((res, index) => {
                  return (
                    <Link
                      underline="hover"
                      key="1"
                      color="inherit"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        selectCurrentDirectory(
                          currentDirectory
                            .split('/')
                            .slice(0, index + 1)
                            .join('/')
                        );
                        setNextDirectory([]);
                      }}
                    >
                      {res}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </Typography>
          </Stack>
        </Box>
        <Actions
          currentDirectory={currentDirectory}
          handleRefetchDirectory={handleRefetchDirectory}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          files={files || []}
          setEditName={setEditName}
          copyFiles={copyFiles}
          setCopyFiles={setCopyFiles}
          setType={setType}
          type={type}
          setFiles={setFiles}
          onTextChanged={onTextChanged}
          fromDirectory={fromDirectory}
          setFromDirectory={setFromDirectory}
          inputRef={inputRef}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortType={setSortType}
          sortType={sortType}
          setDeleteModalOpen={setDeleteModalOpen}
          createDataSet={createDataSet}
          searchMode={searchMode}
        />

        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - 340px)',
            overflowY: 'scroll',
            backgroundColor: '#fff'
          }}
        >
          <Droppable id="nondroppable" createDataSet={createDataSet}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap'
              }}
              onClick={() => setSelectedFiles([])}
              onContextMenu={handleContextMenu}
            >
              {!filesloading &&
                sortedFiles &&
                sortedFiles?.length > 0 &&
                sortedFiles?.map((file, index) => {
                  const isDirectory = file.type === 'directory';

                  const folderContent = (
                    <FolderType
                      key={index}
                      selected={selectedFiles
                        .map(i => i.name)
                        .includes(file.name)}
                      fileType={file.type || 'video'}
                      name={file.name || ''}
                      handleRefetchDirectory={handleRefetchDirectory}
                      selectCurrentDirectory={selectCurrentDirectory}
                      currentDirectory={currentDirectory}
                      handleSelectFile={handleSelectFile}
                      setSelectedFiles={setSelectedFiles}
                      selectedFiles={selectedFiles}
                      file={file}
                      setFiles={setFiles}
                      editName={editName}
                      setEditName={setEditName}
                      handleContextMenu={handleContextMenu}
                      disabled={
                        copyFiles.map(i => i.path).includes(file.path) &&
                        type === 'move'
                      }
                    />
                  );

                  return isDirectory ? (
                    <DraggableItem
                      id={file.name || ''}
                      key={file.name}
                      path={file.path || ''}
                    >
                      {folderContent}
                    </DraggableItem>
                  ) : (
                    <div key={file.name}>{folderContent}</div>
                  );
                })}
              {filesloading && (
                <Box
                  sx={{
                    display: 'flex',
                    height: '50vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>
                      please wait till the data is loading
                    </Typography>
                  </Box>
                </Box>
              )}
              {searchMode && searchLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    height: '50vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <Typography>
                      please wait till the data is loading
                    </Typography>
                  </Box>
                </Box>
              )}

              {!searchMode && files && files?.length == 0 && (
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 350px)',
                    backgroundColor: '#fff'
                  }}
                >
                  nothing in this folder
                </Stack>
              )}

              {searchMode && files && files.length == 0 && (
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 350px)',
                    backgroundColor: '#fff'
                  }}
                >
                  nothing for the query string {searchQueryText}
                </Stack>
              )}
            </div>
          </Droppable>
          {createDataSet && (
            <>
              <Droppable id="droppable" createDataSet={createDataSet}>
                <Typography> Data Set Details</Typography>
                <Typography>
                  {' '}
                  No. of labelled images added : {labelledCount}
                </Typography>
                <Typography>
                  {' '}
                  No. of unlabelled images added : {unlabelledCount}
                </Typography>
                <ToggleButtonGroup
                  value={typeofData}
                  exclusive
                  onChange={handleChange}
                  aria-label="text alignment"
                  sx={{ width: '100%' }}
                >
                  <CustomToggleButton
                    value="labelled"
                    aria-label="centered"
                    disabled={typeofData == 'labelled'}
                  >
                    <AddIcon />
                    Labelled
                  </CustomToggleButton>
                  <CustomToggleButton
                    value="Unlabelled"
                    disabled={typeofData == 'Unlabelled'}
                  >
                    <AddIcon /> Unlabelled
                  </CustomToggleButton>
                </ToggleButtonGroup>
                <Grid container>
                  {typeofData == 'labelled' && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderBottom: '1px solid #000',
                        borderLeft: '1px solid #000',
                        borderRight: '1px solid #000',
                        height: 'calc(100vh - 500px)',
                        overflowY: 'scroll',
                        backgroundColor: '#CCD8DF'
                      }}
                    >
                      {datasetName?.labelled.map(file => {
                        return (
                          <Datalist
                            selected={typeofData == 'labelled' ? true : false}
                            classname={file.className}
                            noOfImages={file.numberOfImages}
                            onRemove={e => {
                              setDatasetName(prev => {
                                const filteredLabelled = prev.labelled.filter(
                                  i => i.className != e
                                );
                                return {
                                  labelled: [...filteredLabelled],
                                  unlabelled: [...prev.unlabelled],
                                  semilabelled: [...prev.semilabelled]
                                };
                              });
                            }}
                          />
                        );
                      })}
                      {datasetName?.labelled.length == 0 && (
                        <Box
                          sx={{
                            textAlign: 'center',

                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography>
                            please drag and drop the folders here{' '}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  )}

                  {typeofData != 'labelled' && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderBottom: '1px solid #000',
                        borderLeft: '1px solid #000',
                        borderRight: '1px solid #000',
                        height: 'calc(100vh - 500px)',
                        overflowY: 'scroll',
                        backgroundColor: '#CCD8DF'
                      }}
                    >
                      {datasetName.unlabelled.map(file => {
                        return (
                          <Datalist
                            classname={file.className}
                            noOfImages={file.numberOfImages}
                            selected={typeofData != 'labelled' ? true : false}
                            onRemove={e => {
                              setDatasetName(prev => {
                                const filteredunlabelled =
                                  prev.unlabelled.filter(i => i.className != e);
                                return {
                                  labelled: [...prev.labelled],
                                  unlabelled: [...filteredunlabelled],
                                  semilabelled: [...prev.semilabelled]
                                };
                              });
                            }}
                          />
                        );
                      })}
                      {datasetName.semilabelled.map(file => {
                        return (
                          <Datalist
                            classname={file.className}
                            noOfImages={file.numberOfImages}
                            selected={typeofData !== 'labelled' ? true : false}
                            onRemove={e => {
                              setDatasetName(prev => {
                                const filteredSemilabelled =
                                  prev.unlabelled.filter(i => i.className != e);
                                return {
                                  labelled: [...prev.labelled],
                                  unlabelled: [...prev.unlabelled],
                                  semilabelled: [...filteredSemilabelled]
                                };
                              });
                            }}
                          />
                        );
                      })}
                      {datasetName.semilabelled.length == 0 &&
                        datasetName.unlabelled.length == 0 && (
                          <Box
                            sx={{
                              textAlign: 'center',
                              backgroundColor: '#CCD8DF',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>
                              please drag and drop the folders here{' '}
                            </Typography>
                          </Box>
                        )}
                    </Grid>
                  )}
                </Grid>
              </Droppable>
            </>
          )}
        </div>

        <Stack
          direction={'row'}
          alignItems={'center'}
          borderTop={'1px solid #c2c2c2'}
          bgcolor={'#fff'}
          justifyContent={'space-between'}
          padding={2}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography variant="subtitle2"> {files.length} Items </Typography>
          </Stack>

          {createDataSet && (
            <Stack direction={'row'} spacing={2}>
              <Button
                onClick={() => {
                  setCreateDataset(false);
                  setDatasetName({
                    labelled: [],
                    semilabelled: [],
                    unlabelled: []
                  });
                }}
                version="light"
                size="small"
              >
                cancel
              </Button>
              <Button
                disabled={
                  datasetName.labelled.length == 0 &&
                  datasetName.semilabelled.length == 0 &&
                  datasetName.unlabelled.length == 0
                }
                onClick={() => {
                  setConfigSelectionModalOpen(true);
                }}
                version="dark"
                size="small"
              >
                Create
              </Button>
            </Stack>
          )}
          {!createDataSet && (
            <Button
              onClick={() => {
                setSelectModal(true);
              }}
            >
              Create Data-Set
            </Button>
          )}
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition || { top: 0, left: 0 }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onContextMenu={e => {
            e.preventDefault();
            handleContextMenu(e);
          }}
        >
          <ContextMenu
            currentDirectory={currentDirectory}
            files={files || []}
            setEditName={setEditName}
            setFiles={setFiles}
            setSelectedFiles={setSelectedFiles}
            onClose={handleClose}
            selectedFiles={selectedFiles}
            fromDirectory={fromDirectory}
            setFromDirectory={setFromDirectory}
            copyFiles={copyFiles}
            setCopyFiles={setCopyFiles}
            setType={setType}
            type={type}
            setDeleteModalOpen={setDeleteModalOpen}
            inputRef={inputRef}
          />
        </Popover>

        <DeleteModal
          openDeleteModal={DeleteModalOpen}
          onClose={setDeleteModalOpen}
          currentDirectory={currentDirectory}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setFiles={setFiles}
        />
        {selectModal && (
          <SelectionModal
            selectModal={selectModal}
            setSelectModal={setSelectModal}
            selectedFolder={selectedFiles}
            setCreateDataset={setCreateDataset}
            setMode={setMode}
            mode={mode}
          />
        )}
      </Box>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeId ? (
          <StyledDiv>
            <img src={Folder} alt="" style={{ width: '120px' }} />
            <Box textAlign={'center'}>
              <Typography variant="caption"> {activeId} </Typography>
            </Box>
          </StyledDiv>
        ) : null}
      </DragOverlay>

      <ConfigSelectionModal
        mode={mode}
        configSelectionModalOpen={configSelectionModalOpen}
        setConfigSelectionModalOpen={setConfigSelectionModalOpen}
        labeled_data={datasetName.labelled.map(i => {
          return i.path;
        })}
        semilabeled_data={datasetName.semilabelled.map(i => {
          return i.path;
        })}
        unlabelled_data={datasetName.unlabelled.map(i => {
          return i.path;
        })}
      />
    </DndContext>
  );
};

export default FileManager;
