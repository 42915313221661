/**
 * Represents a point with x and y coordinates.
 */
export interface Point {
  x: number;
  y: number;
}

/**
 * Calculates the average point from an array of points.
 * @param points Array of points represented as [x1, y1, x2, y2, ...]
 * @returns The average point with x and y coordinates.
 */
export const getAveragePoint = (points: number[]): Point => {
  let totalX = 0;
  let totalY = 0;
  for (let i = 0; i < points.length; i += 2) {
    totalX += points[i];
    totalY += points[i + 1];
  }
  return {
    x: totalX / (points.length / 2),
    y: totalY / (points.length / 2)
  };
};

/**
 * Calculates the distance between two points.
 * @param node1 First point represented as [x, y]
 * @param node2 Second point represented as [x, y]
 * @returns The distance between the two points as a string with 2 decimal places.
 */
export const getDistance = (node1: number[], node2: number[]): string => {
  const diffX = Math.abs(node1[0] - node2[0]);
  const diffY = Math.abs(node1[1] - node2[1]);
  const distanceInPixel = Math.sqrt(diffX * diffX + diffY * diffY);
  return distanceInPixel.toFixed(2);
};

/**
 * Limits the movement of a point within the stage area considering its radius.
 * @param stageWidth Width of the stage
 * @param stageHeight Height of the stage
 * @param vertexRadius Radius of the point
 * @param pos Current position of the point
 * @returns Adjusted position of the point within the stage area
 */
export const dragBoundFunc = (
  stageWidth: number,
  stageHeight: number,
  vertexRadius: number,
  pos: Point
): Point => {
  let { x, y } = pos;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};

/**
 * Finds the minimum and maximum values from an array of numbers.
 * @param points Array of numbers
 * @returns An array containing the minimum and maximum values.
 */
export const minMax = (points: number[]): number[] => {
  return points.reduce((acc: number[], val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};

export function convertPointsToVideoResolution(
  canvasSize: { width: number; height: number },
  videoResolution: { width: number; height: number },
  points: Point[]
): Point[] {
  const scaleX = canvasSize.width / (videoResolution.width || 1);
  const scaleY = canvasSize.height / (videoResolution.height || 1);

  return points.map(point => ({
    x: Math.round(point.x * scaleX),
    y: Math.round(point.y * scaleY)
  }));
}
