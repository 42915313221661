/* eslint-disable */
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField
} from '@mui/material';
import {
  useCreateObjects,
  useGetObjectsByInference,
  useLiveInference,
  useStartInference,
  useSyncObjectByInferenceid
} from 'api/sdk';
import { Modal, Typography } from 'components';
import { SelectInput } from 'components/Form';

import { Snack } from 'plugins/snack/Snack';
import { config } from 'process';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CreateObjectSchema,
  InferenceSchema,
  ModelListSchema,
  ObjSchema,
  ObjSchemaInferenceId,
  SyncObjectsRequest
} from 'schemas';
import { RootState } from 'store';
import { v4 } from 'uuid';
import * as Yup from 'yup';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSucess: () => void;
  ModelList: any;
  objects: ObjSchema[];

  InferenceId: string | undefined;

  count: number | undefined;
  setSelectedModel: (modalID: string) => void;
  selectedModel: string | undefined;
  InferenceListArray: () => Promise<void>;
  refetchInference: () => void;
  inferenceValue: any;
}

interface IFormValues {
  model_name: string;
  number_of_image: number | null;
  infer_all: boolean;
  specific_number: number | null;
}

export const CreateModal: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,

  ModelList,
  InferenceId,
  count,
  selectedModel,
  InferenceListArray,
  setSelectedModel,
  objects,

  inferenceValue,
  refetchInference
}) => {
  const [values, setValues] = useState<IFormValues>({
    model_name: '',
    number_of_image: 50,
    infer_all: true,
    specific_number: 50
  });
  const [newInferenceID, setNewInferenceID] = useState<string>('');
  const [newObjects, setNewObjects] = useState<ObjSchema[]>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [proceedDisable, setProceedDisable] = useState(true);
  const { mutateAsync, isLoading } = useStartInference();
  const { mutateAsync: liveInference } = useLiveInference();
  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const resetValues = () => {
    setValues({
      model_name: '',
      number_of_image: 50,
      infer_all: true,
      specific_number: 50
    });
  };

  useEffect(() => {
    if (selectedModel) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
  }, [selectedModel]);

  const handleTriggerInference = (newInferenceID: string) => {
    mutateAsync({
      data: {
        config_id: selectedConfig?.toString() ?? '',
        inference_id: newInferenceID,
        model_id: selectedModel?.toString() ?? ''
      }
    }).then(() => {
      refetchInference();
      onSucess();
      Snack({
        message: 'PipeLine Triggered successfully',
        severity: 'success'
      });
    });

    onClose();
  };
  const handleTriggerLiveInference = (newInferenceID: string) => {
    liveInference({
      data: {
        config_id: selectedConfig?.toString() ?? '',
        inference_id: newInferenceID,
        model_id: selectedModel?.toString() ?? ''
      }
    }).then(() => {
      refetchInference();
      onSucess();
      Snack({
        message: 'PipeLine Triggered successfully',
        severity: 'success'
      });
    });

    onClose();
  };

  useEffect(() => {
    if (open && ModelList.length === 1) {
      setSelectedModel(ModelList[0].model_id);
    }
  }, [open, ModelList, setSelectedModel]);

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  const { data: objectData, refetch: currentObjectsRefetch } =
    useGetObjectsByInference(newInferenceID || '', {
      pipeline_satus: false
    });

  useEffect(() => {
    if (newInferenceID) {
      currentObjectsRefetch().then(res => {
        setNewObjects(res.data?.data.object_details || []);
      });
    }
  }, [newInferenceID]);
  const {
    data: objectListArr,
    mutateAsync: syncObject,
    isLoading: syncLoading
  } = useSyncObjectByInferenceid();
  const handleSync = async (
    data: ObjSchema[],
    configID: string,
    inferenceid: string
  ): Promise<void> => {
    console.log(data, 'data:');
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      inference_id: inferenceid,
      configuration_id: configID || '',
      groupname: obj.groupname as string,
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          nas_path: imagePath.nas_path,
          image_path: imagePath.thumb_url,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'TRAINING'
    }));

    await syncObject({
      data: updatedObjects,
      inferenceId: inferenceid,
      params: { config_id: configID || '' }
    });
  };
  useEffect(() => {
    if (newObjects?.length && newObjects.length > 0) {
      const transformedObjects: ObjSchema[] = [];

      newObjects?.forEach(i => {
        let temp2: ObjSchema;
        const currentObj = objects.find(j => j.classname === i.classname);

        temp2 = {
          ...i,
          images: currentObj?.images || []
        };

        transformedObjects.push(temp2);
      });

      handleSync(
        transformedObjects,
        selectedConfig || '',
        transformedObjects[0].inference_id || ''
      ).then(res => {
        inferenceValue === 'live_inference'
          ? handleTriggerLiveInference(newInferenceID)
          : handleTriggerInference(newInferenceID);
      });
    }
  }, [newObjects, objects]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues();
      }}
      title="Select Model to Start Inference"
      size="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectInput
            name="Model"
            options={
              ModelList?.filter(
                (model: any) => model?.status === 'COMPLETED'
              ).map((model: any) => ({
                label: model?.model_name,
                value: model?.model_id
              })) || []
            }
            placeholder={'Select Model'}
            value={selectedModel}
            variant="outlined"
            onChange={value => {
              setSelectedModel(value);
              setErrorMessage(null);
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            resetValues();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={proceedDisable}
          style={{ marginLeft: '8px' }}
          onClick={() => {
            setProceedDisable(true);
            console.log(objects);
            const classesArrya = objects.map(i => i.classname);
            console.log('classesArrya', objects);
            const data: CreateObjectSchema[] = [];
            classesArrya.forEach(i => {
              data.push({
                classname: i,
                configuration_id: selectedConfig || '',
                mode: 'INFERENCE',
                groupname: objects[0]?.groupname
              });
            });
            CreateObjects({
              configId: selectedConfig || '',
              data: data
            }).then(res => {
              setNewInferenceID(res.data[0].inference_id as string);
            });
          }}
        >
          Proceed
        </Button>
      </Grid>
    </Modal>
  );
};
