/* eslint-disable */
import { CardProps, Card as MCard, Stack, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Typography } from '../Typography/Typography';

interface ICardProps extends CardProps {
  title?: string;
  padded?: boolean;
  actions?: Array<React.ReactNode>;
  titleMargin?: string;
}

const StyledCard = styled(MCard)<ICardProps>(({ theme, padded }) => ({
  // Apply theme overrides
  borderRadius: '0px !important',
  border: '0px solid rgb(154, 154, 154) !important',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important',
  backgroundColor: theme.palette.primary.main,
  padding: padded ? theme.spacing(2) : 0
}));
//'4px 1px 10px 2px grey !important',
const CardTitle = styled('div')({
  marginBottom: '15px'
});

/**
 * Card component
 */
export const Card: React.FC<ICardProps & PropsWithChildren> = ({
  children,
  title = '',
  padded = true,
  actions,
  titleMargin = '10px',
  ...props
}) => {
  return (
    <StyledCard padded={padded} {...props}>
      {(title || actions) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ marginBottom: titleMargin }}
        >
          <CardTitle>
            <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
              {title}
            </Typography>
          </CardTitle>
          {actions && (
            <Stack direction="row" spacing={2}>
              {actions}
            </Stack>
          )}
        </Stack>
      )}
      {children}
    </StyledCard>
  );
};

export default Card;
