import { Box, CircularProgress, LinearProgress, Stack } from '@mui/material';
import {
  Button,
  Card,
  Modal,
  ObjectCategoryCard,
  Typography
} from 'components';
import { SelectField, SelectInput } from 'components/Form';
import { Formik, Form, Field, FieldProps } from 'formik';
import React, { useRef, useState, useEffect } from 'react';
import {
  ConfigSchema,
  CreateObjectSchema,
  ModelListSchema,
  ObjSchema,
  TrainingResultSchema
} from 'schemas';
import Inference from '../../assets/images/inference.svg';
import { useWebSocket } from 'hooks';
import Env from 'config/env';
import { setSelectedConfiguration } from 'store/configSlice';
import { useDispatch } from 'react-redux';

interface IncrementalTrainingProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  selectedModel: string | undefined;
  configs: ConfigSchema[] | undefined;
  // objects: ObjSchema[] | undefined;
  models: TrainingResultSchema[];
  // setSelectedConfig: (config: string | undefined) => void;
  setSelectedModel: (config: string | undefined) => void;
  // setCurrentInferenceID: (inferenceID: string | undefined) => void;
  // currentInferenceID: string | undefined;
  // handleCreateObject: (
  //   configId: string,
  //   data: CreateObjectSchema[]
  // ) => Promise<any>;
  // handelRefetchObjects: () => Promise<any>;
  setObjectsinModels: (data: string[]) => void;
  // loading: boolean;
  // progress: number;
}
interface Form {
  configurationType: string;
}

export const Main: React.FC<IncrementalTrainingProps> = ({
  onChange,
  selectedConfig,
  configs,

  setSelectedModel,
  models,

  selectedModel,

  // setCurrentInferenceID,
  setObjectsinModels
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const dispatch = useDispatch();
  return (
    <Card
      style={{
        height: '82vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Stack spacing={2} direction={'row'} justifyContent={'space-between'}>
        <Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Incremental Training
          </Typography>
        </Box>

        {configs && (
          <SelectInput
            label="Select Configuration"
            placeholder="Select Configuration"
            variant="outlined"
            width={'250px'}
            name="configurationType"
            options={configs?.map(i => ({
              label: i.name,
              value: i.id
            }))}
            value={selectedConfig || ''}
            onChange={(value: string) => {
              dispatch(setSelectedConfiguration(value));
              setSelectedModel('');
            }}
          />
        )}

        {models && (
          <SelectInput
            label="Select Model"
            placeholder="Select Model"
            variant="outlined"
            width={'250px'}
            name="modelType"
            options={
              models
                ?.filter((model: any) => model?.status === 'COMPLETED')
                ?.map(i => ({
                  label: String(i.model_name),
                  value: String(i.model_id)
                })) || []
            }
            value={selectedModel}
            onChange={value => {
              setSelectedModel(value);
            }}
          />
        )}
      </Stack>

      <Stack spacing={2} direction="column" alignItems="center">
        <Typography
          variant="body2"
          style={{ color: 'red', fontWeight: '16px' }}
        >
          {errorMessage}
        </Typography>
      </Stack>
      <Stack direction={'row'} justifyContent={'center'}>
        <Button
          disabled={!selectedConfig || !selectedModel}
          onClick={() => {
            const a = models?.find(
              i => i.model_id === selectedModel
            )?.classnames;
            setObjectsinModels(a || []);
            onChange(2);
          }}
          style={{ width: '310px', height: '45px' }}
        >
          START INCREMENTAL TRAINING
        </Button>
      </Stack>
    </Card>
  );
};
