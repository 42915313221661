/* eslint-disable */

import { AccountCircle } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import React from 'react';

import { SettingsModal, TabNames } from 'components/SettingsModal';
import { useAppSelector } from 'hooks';
import { FORMAT_DATE_ONLY, dateToString } from 'utils';

import Modal from '../Modal';
import { Typography } from '../Typography/Typography';

/**
 * Account Popover
 */

const AccountPopover: React.FC = () => {
  const { profile } = useAppSelector(state => state.auth);
  const [tab, setTab] = React.useState<TabNames>('profile');

  const [open, setOpen] = React.useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        style={{
          paddingTop: 10
        }}
      >
        <Grid item xs={6} style={{ paddingLeft: '16px' }}>
          <Typography variant="body1">
            {profile?.role === 'organization_admin'
              ? 'Organization Admin'
              : 'Organization Member'}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingRight: '16px', textAlign: 'right' }}>
          <Typography variant="body1">V1.2</Typography>
        </Grid>
        <Grid item xs={12}>
          <List style={{ width: '100%' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={profile?.name}
                secondary={profile?.email}
              />
            </ListItem>
            <Typography
              style={{
                paddingLeft: '16px',
                paddingBottom: '10px'
              }}
              variant="body2"
            >
              License expires on{' '}
              {dateToString(
                String(profile?.license?.expires_at),
                FORMAT_DATE_ONLY
              )}
            </Typography>
            <Divider />
            <ListItem
              button
              onClick={() => {
                setTab('account');
                setOpen(true);
              }}
            >
              <ListItemText primary="Account Setting" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setTab('password');
                setOpen(true);
              }}
            >
              <ListItemText primary="Change Password" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <SettingsModal openTab={tab} />
      </Modal>
    </>
  );
};

export default AccountPopover;
