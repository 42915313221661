import React, { CSSProperties } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

type DroppableItemProps = {
  children: React.ReactNode;
  id: string;
  createDataSet: boolean;
};

const Droppable = ({ id, children, createDataSet }: DroppableItemProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id
  });

  const style: CSSProperties = {
    transition: 'border 0.3s ease',
    height: '100%',
    overflowY: id == 'nondroppable' ? 'scroll' : 'hidden',
    width: createDataSet ? (id == 'nondroppable' ? '70%' : '30%') : '100%'
  };

  return (
    <div style={style}>
      <div ref={setNodeRef}>{children}</div>
    </div>
  );
};

export default Droppable;
