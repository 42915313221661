import axios from 'axios';
import { useEffect } from 'react';

import { getProfile } from 'api/sdk';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { RootState, setAuthLoading, setAuthState } from 'store';

export const useAuth = () => {
  const {
    isLoggedIn,
    loading,
    token: loggedIntoken
  } = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token') || '';
    dispatch(setAuthLoading(true));
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/profile`, {
      headers:{
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
        if (response.status === 200) {
          dispatch(setAuthLoading(false));
          dispatch(
            setAuthState({
              access_token: token,
              token_type: 'Bearer',
              profile: response.data
            })
          );
        }
      })
      .catch(() => {
        dispatch(setAuthLoading(false));
      });
  }, []);

  /**
   * Interceptor for axios to add token to every request
   * @param {any} (
   * @returns {any}
   */
  useEffect(() => {
    if (!loggedIntoken) {
      return;
    }

    /* eslint-disable */
    // const interceptorId = axios.interceptors.request.use(config => {
    //   config.headers.Authorization = `Bearer ${loggedIntoken}`;
    //   return config;
    // });
    // return () => {
    //   axios.interceptors.request.eject(interceptorId);
    // };
  }, [loggedIntoken]);
  /* eslint-enable */

  return {
    isLoggedIn,
    loading
  };
};
