/* eslint-disable */

export const colors = {
  YELLOW: '#F7E967',
  PASTEL_RED: '#F26565',
  PICTON_BLUE: '#4AB6DF',
  GREEN: '#5DBB63',
  ELECTRIC_BLUE: '#6F8CE5',
  ORANGE: '#F5A962',
  PURPLE: '#A77DC2',
  LIGHT_BLUE: '#5CA9C9',
  LIGHT_GREEN: '#A1C181',
  LIGHT_ORANGE: '#F8B88B',
  LIGHT_PURPLE: '#BFA2DB',
  LIGHT_YELLOW: '#F8E3A1',
  LIGHT_GRAY: '#D3D3D3'
};

export const stringToColorCode = (str: string): string => {
  // Convert the string to a single integer hash
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Use the hash to generate three RGB values (0-255)
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;

  // Convert RGB values to a hex string (ensure two digits)
  const color = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return color;
};

export const decToHex = (dec: number): string => {
  return dec.toString(16);
};

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  color += decToHex(r) + decToHex(g) + decToHex(b);

  if (color.length < 7) {
    color = getRandomColor();
  }

  return color;
};
