import CloseIcon from './Close';
import EmployeeProductivityIcon from './EmployeeProductivity';
import InventoryManagementIcon from './InventoryManagement';
import ProductThroughputIcon from './ProductThroughput';
import SafetyGearMonitor from './SafetyGearMonitor';
import SecurityIcon from './Security';
import WorkflowMonitorIcon from './WorkflowMonitor';
import ProcessControl from './ProcessControl';

export {
  CloseIcon,
  EmployeeProductivityIcon,
  InventoryManagementIcon,
  ProductThroughputIcon,
  SafetyGearMonitor,
  SecurityIcon,
  WorkflowMonitorIcon,
  ProcessControl
};
