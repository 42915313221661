import {
  CircularProgress,
  Button as MButton,
  ButtonProps as MButtonProps,
  styled
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

export interface ButtonProps extends MButtonProps {
  actionType?: 'positive' | 'negative' | 'neutral';
  loading?: boolean;
  rounded?: boolean;
  version?: 'dark' | 'light' | 'transparent'|'selection';
}

const StyledButton = styled(MButton)<ButtonProps>(
  ({ theme, version, rounded, color }) => ({
    minWidth: '100px',
    ...(version === 'dark' && {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white}`
      },
      '&:disabled': {
        backgroundColor: theme.palette.primary.light,
        color: `${theme.palette.common.white}`
      }
    }),
    ...(version === 'light' && {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      '&:hover': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white
      },
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[300]
      }
    }),
    ...(version === 'transparent' && {
      color: theme.palette.common.black,

      '&:hover': {
        borderRadius: '3px',
        backgroundColor: '#DBDBDB'
      },
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[300]
      }
    }),
    ...(version === 'selection' && {
      color: theme.palette.common.black,
      border:"none",
      width:"100%", 
      height:"100%",
      boxShadow:"0px 0px 12px #00000033",
      borderRadius: '3px',

      '&:hover': {
       
      },
      '&:disabled': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[300]
      }
    }),

    ...(rounded && {
      borderRadius: '50px'
    }),
    ...(color === 'error' && {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.main
    }),
    ...(color === 'warning' && {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.main
    }),
    ...(color === 'info' && {
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white
    }),
    ...(color === 'success' && {
      color: theme.palette.common.white,
      borderColor: theme.palette.success.main,
      backgroundColor: theme.palette.success.main
    })
  })
);

export const Button: React.FC<
  ButtonProps & PropsWithChildren // eslint-disable-line
> = ({
  actionType = 'positive',
  loading = false,
  children,
  version = 'dark',
  ...props
}) => {
  return (
    <>
      <StyledButton
        actionType={actionType}
        version={version}
        {...props}
        disabled={loading || props.disabled}
      >
        {children}
        {loading && (
          <>
            &nbsp;
            <CircularProgress size={15} />
          </>
        )}
      </StyledButton>
    </>
  );
};
