import { Button, Card, Typography, Modal } from 'components';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react';

import { Stage, Layer, Image as KonvaImage, Circle } from 'react-konva';
import useImage from 'use-image';
import {
  Stack,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  IconButton,
  CircularProgress
} from '@mui/material';
import Thumbnail from './Thumbnail';
import {
  ConfigSchema,
  LabelAsisitRequestQualityControl,
  LabelAsisitRequestQualityControlIncremental,
  ObjResponseSchemaCount,
  ObjSchema,
  QualitygeneratemaskRequest,
  SyncObjectsRequest
} from 'schemas';
import { Graph } from './graph';
import { SelectInput, TextField } from 'components/Form';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Default from '../../assets/images/default.svg';
import { useNavigate } from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import {
  useStartIncremetal,
  useStartTraining,
  useModelArchitectureList,
  useSyncObjectByInferenceid
} from 'api/sdk';
import { v4 } from 'uuid';

interface MoveImageProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;

  objects: ObjSchema[] | undefined;
  objectAll: ObjResponseSchemaCount | undefined;

  mode: string;
  setStep: (data: number) => void;
  viewMode?: boolean;
  currentInferenceID: string | undefined;

  selectedModel?: string;
}
interface FormValues {
  modelName: string;
  trainTestSplit: number;
  batchSize: number;
  learningRate: number;
  epochs: number;
  classesType: string;
}

const initialValues: FormValues = {
  modelName: '',
  classesType: '',
  trainTestSplit: 80,
  batchSize: 8,
  learningRate: 0.001,
  epochs: 10
};

type WarningModalProps = {
  onClose: () => void;
  message: string;
};
const validationSchema = Yup.object({
  modelName: Yup.string().required('Model Name is required'),
  trainTestSplit: Yup.number()
    .required('Train Test Split is required')
    .min(50, 'Train Test Split must be between 50 -95')
    .max(95, 'Train Test Split must be between 50 -95'),
  batchSize: Yup.number()
    .required('Batch Size is required')
    .min(1, 'Batch Size must be between 1-32')
    .max(32, 'Batch Size must be between 1-32'),
  learningRate: Yup.number()
    .required('Learning Rate is required')
    .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
    .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
  epochs: Yup.number()
    .required('Epochs is required')
    .min(1, 'Epochs must be between 1-500')
    .max(500, 'Epochs must be between 1-500'),
  // classesType: Yup.string().when('mode', {
  //   is: 'TRAINING',
  //   then: schema => schema.required('Model Architecture is required'),
  //   otherwise: schema => schema.notRequired()
  // })
  classesType: Yup.string().required('Model Architecture is required')
});

const validationInferenceSchema = Yup.object({
  modelName: Yup.string().required('Model Name is required'),
  trainTestSplit: Yup.number()
    .required('Train Test Split is required')
    .min(50, 'Train Test Split must be between 50 -95')
    .max(95, 'Train Test Split must be between 50 -95'),
  batchSize: Yup.number()
    .required('Batch Size is required')
    .min(1, 'Batch Size must be between 1-32')
    .max(32, 'Batch Size must be between 1-32'),
  learningRate: Yup.number()
    .required('Learning Rate is required')
    .min(0.00001, 'Learning Rate must be between 0.00001 - 0.1')
    .max(0.1, 'Learning Rate must be  between 0.00001 - 0.1'),
  epochs: Yup.number()
    .required('Epochs is required')
    .min(1, 'Epochs must be between 1-500')
    .max(500, 'Epochs must be between 1-500')
  // classesType: Yup.string().when('mode', {
  //   is: 'TRAINING',
  //   then: schema => schema.required('Model Architecture is required'),
  //   otherwise: schema => schema.notRequired()
  // })
});

export const ViewDetails: React.FC<MoveImageProps> = ({
  selectedConfig,

  objects,
  objectAll,
  currentInferenceID,

  mode,
  configs,
  selectedModel,
  setStep,
  viewMode
}) => {
  const [selectedObject, setSelectedObject] = useState<string>('');

  const [modelArchitecture, setModelArchitecture] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [previewImageID, setPreviewImageID] = useState<number>(1);
  const [trainingModal, setTrainingModal] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [localImages, setLocalImages] = useState<ObjSchema[]>();

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const ImagesRowRef = useRef(null);
  const [imagesPerRow, setImagesPerRow] = useState(0);
  const [modelArchitectureList, setModelArchitectureList] = useState<string[]>(
    []
  ); // Initialize state to hold the data
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorWarning, setShowErrorWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const navigate = useNavigate();
  const handelChangeImageIndex = (id: number) => {
    setPreviewImageID(id);
    setShowPreview(true);
  };

  // Set the default selection to the first classname in the objects array
  useEffect(() => {
    if (objects && objects?.length > 0) {
      setSelectedObject(objects[0]?.classname);
    }
  }, [objects]);

  useEffect(() => {
    setShowOverlay(false);
  }, [previewImageID, selectedObject]);

  useEffect(() => {
    setLocalImages(objects);
  }, [objects]);

  const onCheckItem = (checked: boolean, item: string) => {
    if (checked) {
      setCheckedItems(prev => [...prev, item]);
    } else {
      setCheckedItems(prev => prev.filter(i => i !== item));
    }
  };

  const WarningModal: React.FC<WarningModalProps> = ({ onClose, message }) => (
    <Modal open={true} onClose={onClose}>
      <div>
        <Typography variant="h6">Warning: Class Count Below 200</Typography>
        <Typography variant="body1">{message}</Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mt={2}
        >
          <Button onClick={onClose}>Close</Button>

          <Button
            onClick={() => {
              onClose();
              setModelArchitecture('');
              objects && handleSync(objects);
              setTrainingModal(true);
            }}
          >
            Proceed
          </Button>
        </Stack>
      </div>
    </Modal>
  );

  useEffect(() => {
    setCheckedItems([]);
    setPreviewImageID(0);
    setPage(1);
  }, [selectedObject]);
  const { mutateAsync: startTraining, error, isError } = useStartTraining();
  const { mutateAsync: startIncremental } = useStartIncremetal();
  const { data: objectData, refetch: startArchitecture } =
    useModelArchitectureList();

  useEffect(() => {
    startArchitecture().then(res => {
      res != undefined &&
        setModelArchitectureList(res?.data?.data?.names || []);
    });
  }, []);
  console.log('localImages', configs);

  const handleSubmit = async (
    values: FormValues,
    { setFieldError }: FormikHelpers<FormValues>
  ) => {
    const currentID = localImages?.[0]?.inference_id;
    const data: LabelAsisitRequestQualityControl = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,
      model_architecture: values.classesType,
      inference_id: currentID || '',
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit
    };
    const data2: LabelAsisitRequestQualityControlIncremental = {
      batch_size: values.batchSize,
      epoches: values.epochs,
      learning_rate: values.learningRate,
      model_name: values.modelName,

      inference_id: currentID || '',
      config_id: selectedConfig || '',
      train_test_split_percentage: values.trainTestSplit,
      model_id: selectedModel || ''
    };
    if (mode == 'TRAINING') {
      try {
        await startTraining({ data });
        setStep(1);
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    } else {
      try {
        await startIncremental({ data: data2 });
        navigate('/train');
      } catch (error: any) {
        if (error.response.data.detail) {
          setFieldError('modelName', error.response.data.detail);
        }
      }
    }
  };

  const { mutateAsync: syncObject } = useSyncObjectByInferenceid();

  const handleSync = async (
    data: ObjSchema[],
    inferenceid?: string
  ): Promise<void> => {
    const updatedObjects: SyncObjectsRequest[] = data.map(obj => ({
      id: obj.id,
      classname: obj.classname,
      category: obj.category,
      groupname: obj.groupname as string,
      inference_id: inferenceid ? inferenceid : obj.inference_id,
      configuration_id: selectedConfig || '',
      images:
        obj.images?.map(imagePath => ({
          id: v4(),
          image_path: imagePath.thumb_url || null,
          nas_path: imagePath.nas_path,
          frame_path: null,
          frame_number: null,
          frame_width: null,
          frame_height: null,
          bounding_box: null,
          thumb_url: imagePath.thumb_url
        })) || [],
      mode: 'INFERENCE'
    }));

    await syncObject({
      data: updatedObjects,
      inferenceId: inferenceid ? inferenceid : currentInferenceID || '',
      params: { config_id: selectedConfig || '' }
    });
  };

  const mainObject = useMemo(() => {
    const o = localImages?.find(i => i.classname == selectedObject);
    return o ?? null;
  }, [localImages, objects, selectedObject]);

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const previewImageSrc = useMemo(() => {
    if (previewImageID === undefined) return Default;
    const image = localImages?.find(i => i.classname === selectedObject)
      ?.images?.[previewImageID];
    if (showOverlay) {
      return image?.overlayed_url || Default;
    } else return image?.thumb_url || Default;
  }, [localImages, selectedObject, previewImageID, showOverlay]);

  const handlePrevImage = () => {
    setPreviewImageID(prev => Math.max(prev - 1, 0));
  };

  const handleNextImage = () => {
    setPreviewImageID(prev =>
      Math.min(prev + 1, (mainObject?.images?.length || 1) - 1)
    );
  };

  const handleUprow = () => {
    if (previewImageID > imagesPerRow) {
      setPreviewImageID(previewImageID - imagesPerRow);
    }
  };
  const handleDownrow = () => {
    if (previewImageID + imagesPerRow <= 99) {
      setPreviewImageID(previewImageID + imagesPerRow);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          handleUprow();
          break;
        case 'ArrowDown':
          handleDownrow();
          break;
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [previewImageID]);

  useEffect(() => {
    setPreviewImageID((page - 1) * 100);
  }, [page]);

  const layoutRef = useRef(null);

  useEffect(() => {
    const updateImagesPerRow = () => {
      if (ImagesRowRef.current) {
        const boxWidth = (ImagesRowRef.current as HTMLElement)?.offsetWidth;
        const thumbnailWidth = 110;
        const imagesInRow = Math.floor(boxWidth / thumbnailWidth);
        setImagesPerRow(imagesInRow);
      }
    };
    updateImagesPerRow();
    window.addEventListener('resize', updateImagesPerRow);

    return () => {
      window.removeEventListener('resize', updateImagesPerRow);
    };
  }, []);
  const imagesPerPage = 100;

  const currentImages = mainObject?.images?.slice(
    (page - 1) * imagesPerPage,
    page * imagesPerPage
  );

  return (
    <>
      <Box style={{ height: '38px' }}>
        <Button
          version="light"
          onClick={() => {
            if (viewMode) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
        >
          Back
        </Button>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid container item xs={12} md={12} lg={12} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Card
              style={{
                minHeight: '78vh',
                maxHeight: '78vh',
                minWidth: '80vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px'
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  height: '15vh'
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight="bold">
                    Train Model
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Stack>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Configuration : {objectAll?.config_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Data-Set Name :{localImages?.[0]?.groupname}{' '}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Total Labelled Images : {objectAll?.labeled_count}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Total Classes :
                        {Object.keys(objectAll?.classname_counts || {}).length}{' '}
                      </Typography>
                    </Stack>
                  </Stack>
                  {objects && (
                    <Stack direction={'row'} mt={1}>
                      <SelectInput
                        label="Select Classes"
                        placeholder="Select Classes"
                        variant="outlined"
                        width={'250px'}
                        name="classes"
                        value={selectedObject}
                        options={objects
                          .filter(obj => obj.classname !== 'unlabeled')
                          .map(obj => ({
                            label: obj.classname,
                            value: obj.classname
                          }))}
                        onChange={value => setSelectedObject(value)} // Update the state on change
                      />
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Graph
                    height={'100%'}
                    width={'100%'}
                    labels={
                      objects
                        ?.filter(obj => obj.classname !== 'unlabeled')
                        ?.map(i => i.classname) || []
                    }
                    values={
                      objects
                        ?.filter(obj => obj.classname !== 'unlabeled')
                        ?.map(i => i?.images?.length || 0) || []
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2} mb={1}>
                <div
                  style={{ border: '1px solid #696969', opacity: 0.6 }}
                ></div>
              </Grid>
              <Grid
                item
                xs={12}
                mt={1}
                mb={1}
                style={{
                  minHeight: '45vh',
                  maxHeight: '45vh',
                  overflow: 'scroll'
                }}
              >
                <Box ref={ImagesRowRef}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      gridTemplateRows: 'repeat(10, 1fr)',
                      gridTemplateColumns: 'repeat(10, 1fr)'
                    }}
                  >
                    {currentImages?.map((item, index) => (
                      <Grid item xs={1} key={item.id}>
                        <Thumbnail
                          id={item.id}
                          title=""
                          showImageGallery={true}
                          thumbnailUrl={item.thumb_url || ''}
                          index={(page - 1) * imagesPerPage + index}
                          checkable={false}
                          checked={checkedItems.includes(item.id)}
                          onCheck={checked => onCheckItem(checked, item.id)}
                          handelChangeImageIndex={handelChangeImageIndex}
                          type={
                            (page - 1) * imagesPerPage + index ===
                            previewImageID
                              ? 'OK'
                              : ''
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              {(mainObject?.images?.length ?? 0) > imagesPerPage && (
                <Stack
                  spacing={2}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'30px'}
                  direction={'row'}
                >
                  <Pagination
                    count={Math.ceil(
                      (mainObject?.images?.length ?? 0) / imagesPerPage
                    )}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Stack>
              )}
            </Card>

            {!viewMode && (
              <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                <Button
                  onClick={() => {
                    setStep(1);
                    setTrainingModal(false);
                  }}
                  style={{ width: '150px', height: '45px' }}
                  version="light"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !objectAll?.classname_counts || // Checks if classname_counts is null or undefined
                    Object.keys(objectAll.classname_counts || {}).length === 0 // Checks if it's an empty object
                  }
                  onClick={() => {
                    if (
                      objectAll &&
                      objectAll?.classname_counts &&
                      Object.keys(objectAll.classname_counts || {}).length < 2
                    ) {
                      setShowErrorModal(true); // Show error modal if number of classes < 2
                    } else {
                      const classesWithLowCount = Object.keys(
                        objectAll?.classname_counts || {}
                      ).filter(
                        className =>
                          ((objectAll?.classname_counts &&
                            objectAll.classname_counts[className]) ||
                            0) < 200
                      );

                      if (classesWithLowCount.length > 0) {
                        // If there are any classes with less than 200 images, show the warning modal
                        setShowErrorWarning(true);
                        setWarningMessage(
                          `Classes with less than 200 images: ${classesWithLowCount.join(
                            ', '
                          )}`
                        );
                      } else {
                        setModelArchitecture('');
                        console.log('objects', objects);
                        objects && objects?.length > 0 && handleSync(objects);
                        setTrainingModal(true);
                      }
                    }
                  }}
                  style={{ width: '150px', height: '45px' }}
                >
                  Proceed
                </Button>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>

      {showErrorWarning && (
        <WarningModal
          onClose={() => setShowErrorWarning(false)}
          message={warningMessage} // Display the message with class names
        />
      )}

      {/* Error Modal */}
      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <Typography variant="h6" mb={2}>
          Error
        </Typography>
        <Typography variant="body1" mb={3}>
          Minimum 2 Classes Needed For Supervised Training{' '}
        </Typography>
        <Button
          onClick={() => setShowErrorModal(false)}
          variant="contained"
          color="primary"
        >
          OK
        </Button>
      </Modal>

      <Modal
        open={trainingModal}
        onClose={() => setTrainingModal(false)}
        // title="Training Summary"
      >
        <>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={
                mode === 'TRAINING'
                  ? validationSchema
                  : validationInferenceSchema
              }
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched, values, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Total Labelled Images
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        :{objectAll?.labeled_count}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Total no. of classes
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        {' '}
                        {
                          Object.keys(objectAll?.classname_counts || {}).length
                        }{' '}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Model Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="modelName"
                        type="text"
                        variant="outlined"
                        error={errors.modelName}
                      />
                    </Grid>
                    {mode == 'TRAINING' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 'bold' }}
                          >
                            Model Architecture
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} justifyContent="center">
                          {modelArchitectureList && (
                            <SelectInput
                              label="Select Model Architecture"
                              placeholder="Select Model Architecture"
                              variant="outlined"
                              width={'220px'}
                              name="classesType"
                              value={values.classesType}
                              options={
                                modelArchitectureList &&
                                modelArchitectureList?.map(obj => ({
                                  label: obj,
                                  value: obj
                                }))
                              }
                              onChange={value =>
                                setFieldValue('classesType', value)
                              }
                            />
                          )}
                          {errors.classesType && touched.classesType && (
                            <Typography
                              color="error"
                              variant="caption"
                              style={{ marginLeft: '20px' }}
                            >
                              {errors.classesType}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Train Test Split
                      </Typography>
                      <Typography variant="caption" align="center">
                        (50 to 95)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="trainTestSplit"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Batch Size
                      </Typography>
                      <Typography variant="caption">(1 to 32)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="batchSize"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Learning Rate
                      </Typography>
                      <Typography variant="caption">
                        (0.00001 to 0.1)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="learningRate"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Epochs
                      </Typography>
                      <Typography variant="caption">(1 to 500)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="epochs"
                        type="number"
                        variant="outlined"
                      />
                    </Grid>

                    <Stack
                      direction="row"
                      justifyContent={'center'}
                      width={'100%'}
                      mt={2}
                    >
                      <Button type="submit" disabled={isSubmitting}>
                        Start Training
                      </Button>
                    </Stack>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </>
      </Modal>
      <Modal
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev - 1);
              }}
              disabled={previewImageID <= 1}
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <img
              src={previewImageSrc || ''}
              style={{
                height: '50vh',
                objectFit: 'contain',
                width: '80%'
              }}
              alt=""
            />
            <IconButton
              onClick={() => {
                setPreviewImageID(prev => prev + 1);
              }}
              disabled={previewImageID + 1 >= (mainObject?.images?.length || 0)}
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Stack>
          <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={2}>
            <Box>
              Image Number :{previewImageID + 1}/{mainObject?.images?.length}
            </Box>
            <Box>Image Class Type : {selectedObject}</Box>
          </Stack>
        </>
      </Modal>
    </>
  );
};
