// /* eslint-disable  */

// import React, { useEffect } from 'react';

// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails
// } from 'components/Accordion';
// import { ChangePasswordForm } from './changePasswordForm';
// import ProfileDetails from './ProfileDetails';
// import { Stack, Typography } from '@mui/material';
// import { CreateViewerForm } from './createViewerForm';

// export type TabNames =
//   | 'profile'
//   | 'password'
//   | 'license'
//   | 'account'
//   | 'viewer';

// export interface ISettingsModalProps {
//   openTab: TabNames;
// }

// export const SettingsModal: React.FC<ISettingsModalProps> = ({
//   openTab = 'profile'
// }) => {
//   const [tab, setTab] = React.useState<TabNames>(openTab);

//   useEffect(() => {
//     setTab(openTab);
//   }, [openTab]);

//   return (
//     <>
//       <Stack spacing={2} sx={{ padding: 2 }} direction={'column'}>
//         <Accordion
//           aria-controls={`account-tab-control`}
//           id="tab-account"
//           expanded={tab === 'account'}
//           onChange={() => setTab('account')}
//         >
//           <AccordionSummary>
//             {' '}
//             <Typography
//               style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
//             >
//               Profile
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             {' '}
//             <ProfileDetails />
//           </AccordionDetails>
//         </Accordion>

//         <Accordion
//           aria-controls={`license-tab-control`}
//           id="tab-license"
//           expanded={tab === 'license'}
//           onChange={() => setTab('license')}
//         >
//           <AccordionSummary>
//             <Typography
//               style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
//             >
//               License
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>License Details</AccordionDetails>
//         </Accordion>

//         <Accordion
//           aria-controls={`password-tab-control`}
//           id="tab-password"
//           expanded={tab === 'password'}
//           onChange={() => setTab('password')}
//         >
//           <AccordionSummary>
//             {' '}
//             <Typography
//               style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
//             >
//               Change Password
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <ChangePasswordForm />
//           </AccordionDetails>
//         </Accordion>

//         {/* Viewers Account */}

//         <Accordion
//           aria-controls={`viewer-tab-control`}
//           id="tab-viewer"
//           expanded={tab === 'viewer'}
//           onChange={() => setTab('viewer')}
//         >
//           <AccordionSummary>
//             {' '}
//             <Typography
//               style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
//             >
//               Viewer Account
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             {/* ViewerList Table and Create Viewer  */}
//             <CreateViewerForm />
//           </AccordionDetails>
//         </Accordion>
//       </Stack>
//     </>
//   );
// };

/* eslint-disable  */

import React, { useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'components/Accordion';
import { ChangePasswordForm } from './changePasswordForm';
import ProfileDetails from './ProfileDetails';
import { Stack, Typography } from '@mui/material';
import { CreateViewerForm } from './createViewerForm';
import { useAppSelector } from 'hooks';
import LicenseDetails from './LicenseDetails';

export type TabNames =
  | 'profile'
  | 'password'
  | 'license'
  | 'account'
  | 'viewer';

export interface ISettingsModalProps {
  openTab: TabNames;
}

export const SettingsModal: React.FC<ISettingsModalProps> = ({
  openTab = 'profile'
}) => {
  const [tab, setTab] = React.useState<TabNames>(openTab);
  const { profile } = useAppSelector(state => state.auth);

  useEffect(() => {
    setTab(openTab);
  }, [openTab]);

  return (
    <>
      <Stack spacing={2} sx={{ padding: 2 }} direction={'column'}>
        <Accordion
          aria-controls={`account-tab-control`}
          id="tab-account"
          expanded={tab === 'account'}
          onChange={() => setTab('account')}
        >
          <AccordionSummary>
            {' '}
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              Profile
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {' '}
            <ProfileDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          aria-controls={`license-tab-control`}
          id="tab-license"
          expanded={tab === 'license'}
          onChange={() => setTab('license')}
        >
          <AccordionSummary>
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              License
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LicenseDetails />
          </AccordionDetails>
        </Accordion>

        <Accordion
          aria-controls={`password-tab-control`}
          id="tab-password"
          expanded={tab === 'password'}
          onChange={() => setTab('password')}
        >
          <AccordionSummary>
            {' '}
            <Typography
              style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
            >
              Change Password
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChangePasswordForm />
          </AccordionDetails>
        </Accordion>

        {/* Viewers Account */}
        {profile?.role === 'organization_admin' && (
          <Accordion
            aria-controls={`viewer-tab-control`}
            id="tab-viewer"
            expanded={tab === 'viewer'}
            onChange={() => setTab('viewer')}
          >
            <AccordionSummary>
              {' '}
              <Typography
                style={{ fontWeight: 'bold', color: 'black', fontSize: '16px' }}
              >
                Viewer Account
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* ViewerList Table and Create Viewer  */}
              <CreateViewerForm />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
    </>
  );
};
