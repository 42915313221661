import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'store';

const mediaAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MEDIA_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
});
 
// const interceptorId = axios.interceptors.request.use(config => {
//   config.headers.Authorization = `Bearer ${loggedIntoken}`;
//   return config;
// });
// return () => {
//   axios.interceptors.request.eject(interceptorId);
// };
// }, [loggedIntoken]);

mediaAxiosInstance.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  error => Promise.reject(error)
);

export const mediaAxiosInstanceFn = <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const source = axios.CancelToken.source();
  const promise = mediaAxiosInstance({
    ...config,
    cancelToken: source.token
  }).then(
    (response: AxiosResponse<T>) => response // Return the full response object
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export default mediaAxiosInstanceFn;
