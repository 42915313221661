import React, {
  LegacyRef,
  RefObject,
  useEffect,
  useState,
  useRef,
  useContext
} from 'react';
import { Typography } from '../Typography/Typography';
import Card from '../Card';
import {
  Box,
  Divider,
  Popover,
  Stack,
  TextField,
  TextFieldProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import CustomIconButton from '../Button/IconButton';

import { FiFolderPlus } from 'react-icons/fi';
import { FaRegCopy } from 'react-icons/fa6';
import { TbCut } from 'react-icons/tb';
import { FaRegPaste } from 'react-icons/fa6';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BiSortAlt2 } from 'react-icons/bi';
import { Button } from 'components/Button/Button';

import { IoIosAdd } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { Tooltip } from '@mui/material';
import {
  useProcessFileTransferFileManagerFilesTypePatch,
  useCreateDirectoryFileManagerDirectoryPost
} from '../../api/media';
import { Contents, DirectoriesContents, FileType } from '../../schemasMedia';
import FileUploadModal from './FileUploadModal';
import styled from 'styled-components';
import { useSnack } from 'plugins/snack';
import { MyContext } from '../../layouts/PrivateLayout/';
import { store } from 'store';

interface FileManagerProps {
  currentDirectory: string;
  handleRefetchDirectory: (select?: Contents[]) => void;
  selectedFiles: Contents[];
  setSelectedFiles: (file: Contents[]) => void;
  files: Contents[];
  setEditName: (data: boolean) => void;
  copyFiles: Contents[];
  setCopyFiles: (data: Contents[]) => void;
  type: FileType;
  setType: (data: FileType) => void;
  setFiles: (Data: Contents[]) => void;
  onTextChanged: (Text: string) => void;
  fromDirectory: string;
  setFromDirectory: (data: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setSortType: (data: string) => void;
  sortType: string;
  setSortBy: (data: string) => void;
  sortBy: string;
  setDeleteModalOpen: (data: boolean) => void;
  createDataSet: boolean;
  searchMode: boolean;
}

enum FileStatus {
  uploading = 'uploading',
  uploadFailed = 'uploadFailed',
  uploadCancelled = 'uploadCancelled',
  uploadComplete = 'uploadComplete',
  waitingForUnzip = 'waitingForUnzip',
  unzipping = 'unzipping',
  unzipFailed = 'unzipFailed',
  completed = 'completed'
}

const Actions: React.FC<FileManagerProps> = ({
  currentDirectory,
  selectedFiles,
  setSelectedFiles,
  files,
  setEditName,
  copyFiles,
  setCopyFiles,
  setType,
  type,
  setFiles,
  onTextChanged,
  fromDirectory,
  setFromDirectory,
  inputRef,
  setSortType,
  sortType,
  setSortBy,
  sortBy,
  setDeleteModalOpen,
  createDataSet,
  searchMode,
  handleRefetchDirectory
}) => {
  interface unzipProgress {
    progress: number;

    foldername: string;
  }

  const uploadRef = useRef<HTMLInputElement>(null);
  const [unzipProgressFromWebSocket, setunzipProgressFromWebSocket] =
    useState<unzipProgress>();
  const {
    uploadFiles,
    cancelUpload,
    open: disabledUpload,
    filesWithStatus
  } = useContext(MyContext);

  const [errorMessage, setErrorMessage] = useState<string>('');
  function isvalidJSON(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  const { mutateAsync: createFolder } =
    useCreateDirectoryFileManagerDirectoryPost();

  const { mutateAsync: moveFiles } =
    useProcessFileTransferFileManagerFilesTypePatch();

  const getNewFolderName = (): string => {
    const folderPattern = /^New folder(\d+)?$/;
    const folderNumbers = files
      .filter(
        file =>
          file.type === 'directory' && folderPattern.test(file.name as string)
      )
      .map(file => {
        const match = file?.name?.match(folderPattern);
        return match && match[1] ? parseInt(match[1]) : 1;
      });

    const maxNumber = folderNumbers.length > 0 ? Math.max(...folderNumbers) : 0;
    const newFolderNumber = maxNumber + 1;

    return newFolderNumber === 1
      ? 'New folder'
      : `New folder${newFolderNumber}`;
  };
  const snack = useSnack();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const currentDirectoryFilesName = files.map(i => i.name?.split('.')[0]);

      const hasDuplicate = Array.from(selectedFiles).some(file =>
        currentDirectoryFilesName.includes(file.name.split('.')[0])
      );

      if (hasDuplicate) {
        Array.from(selectedFiles).forEach(file => {
          if (currentDirectoryFilesName.includes(file.name.split('.')[0])) {
            // console.log(uploadRef.current, 'current');
            if (uploadRef.current) {
              uploadRef.current.value = '';
            }
            snack({
              message: `File name: ${file.name} already exists in the current folder`,
              severity: 'error'
            });
          }
        });
        return;
      }
      const token = store.getState().auth.token;

      const uploadUrl = `${process.env.REACT_APP_MEDIA_BASE_URL}/file_manager/upload_file?filepath=${currentDirectory}`;
      uploadFiles(selectedFiles, uploadUrl, token || '');
    }
  };
  const [processedFiles, setProcessedFiles] = useState<Set<string>>(new Set());
  useEffect(() => {
    const newProcessedFiles = new Set(processedFiles);

    filesWithStatus.forEach(file => {
      if (
        file.status === FileStatus.completed &&
        !processedFiles.has(file.filename)
      ) {
        handleRefetchDirectory();
        newProcessedFiles.add(file.filename);
      }
    });

    setProcessedFiles(newProcessedFiles);
  }, [filesWithStatus]);
  useEffect(() => {
    if (!disabledUpload) {
      if (uploadRef.current) {
        uploadRef.current.value = '';
      }
    }
  }, [disabledUpload]);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      onTextChanged(searchQuery);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      backgroundColor: '#fff',
      '& fieldset': {
        borderColor: 'transparent'
      },
      '&:hover fieldset': {
        borderColor: 'transparent'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent'
      }
    }
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  console.log(uploadRef.current?.value, 'uploadREF');
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{ backgroundColor: '#EEEEEE', padding: '10px' }}
    >
      <Stack direction={'row'} spacing={3}>
        <Tooltip title="New Folder" arrow>
          <CustomIconButton
            disabled={createDataSet || searchMode}
            icon={FiFolderPlus}
            onClick={() =>
              createFolder({
                params: {
                  directory_name: getNewFolderName(),
                  directory_path: currentDirectory
                }
              }).then(res => {
                setFiles(res.data.contents);
                const selectedFile = res.data.contents.filter(
                  file => file.name == getNewFolderName()
                );

                setSelectedFiles(selectedFile);
                setEditName(true);
              })
            }
          />
        </Tooltip>

        <Tooltip title="Copy" arrow>
          <CustomIconButton
            icon={FaRegCopy}
            disabled={selectedFiles.length == 0 || createDataSet || searchMode}
            onClick={() => {
              const files = selectedFiles.filter(
                file => file.type != 'Directory'
              );

              setCopyFiles(files);
              setFromDirectory(currentDirectory || '/');
              setType('copy');
            }}
          />
        </Tooltip>

        <Tooltip title="Cut" arrow>
          <CustomIconButton
            disabled={selectedFiles.length == 0 || createDataSet || searchMode}
            icon={TbCut}
            onClick={() => {
              const files = selectedFiles.filter(
                file => file.type != 'Directory'
              );
              setCopyFiles(files);

              setFromDirectory(currentDirectory || '/');
              setType('move');
            }}
          />
        </Tooltip>

        <Tooltip title="Paste" arrow>
          <CustomIconButton
            icon={FaRegPaste}
            disabled={copyFiles.length == 0 || createDataSet || searchMode}
            onClick={() => {
              moveFiles({
                data: {
                  filenames: copyFiles.map(file => file.name as string),
                  from_directory: fromDirectory,
                  to_directory: `/${currentDirectory}`
                },
                type: type || 'copy'
              }).then(res => {
                res.data && setFiles(res.data.contents);
                setCopyFiles([]);
              });
            }}
          />
        </Tooltip>

        <Tooltip title="Rename" arrow>
          <CustomIconButton
            disabled={selectedFiles.length != 1 || createDataSet || searchMode}
            icon={MdOutlineEdit}
            onClick={() => {
              setEditName(true);
              inputRef.current?.focus();
              inputRef.current?.select();
            }}
          />
        </Tooltip>

        <Tooltip title="delete" arrow>
          <CustomIconButton
            disabled={selectedFiles.length === 0 || createDataSet || searchMode}
            icon={RiDeleteBin6Line}
            onClick={() => {
              selectedFiles != undefined &&
                selectedFiles.length > 0 &&
                setDeleteModalOpen(true);
              if (uploadRef.current) {
                uploadRef.current.value = '';
              }
            }}
          />
        </Tooltip>

        <Divider orientation="vertical" flexItem />

        <Button
          version="transparent"
          aria-describedby={id}
          onClick={handleClick}
        >
          <BiSortAlt2 />
          SORT
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <List sx={{ padding: 1 }}>
            <ListItem disablePadding sx={{ marginBottom: '4px' }}>
              <ListItemButton
                sx={{
                  padding: '2px 4px',
                  backgroundColor: sortBy == 'Name' ? '#ededed' : '#fff'
                }}
                onClick={() => {
                  setSortBy('Name');
                }}
              >
                <ListItemText primary="Name" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
                backgroundColor: sortBy == 'Date Modified' ? '#ededed' : '#fff'
              }}
              onClick={() => {
                setSortBy('Date Modified');
              }}
            >
              <ListItemButton sx={{ padding: '2px 4px' }}>
                <ListItemText primary="Date Modified" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
                backgroundColor: sortBy == 'Date Created' ? '#ededed' : '#fff'
              }}
              onClick={() => {
                setSortBy('Date Created');
              }}
            >
              <ListItemButton sx={{ padding: '2px 4px' }}>
                <ListItemText primary="Date Created" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
                backgroundColor: sortBy == 'Size' ? '#ededed' : '#fff'
              }}
              onClick={() => {
                setSortBy('Size');
              }}
            >
              <ListItemButton sx={{ padding: '2px 4px' }}>
                <ListItemText primary="Size" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
                backgroundColor: sortType == 'Ascending' ? '#ededed' : '#fff'
              }}
              onClick={() => {
                setSortType('Ascending');
              }}
            >
              <ListItemButton sx={{ padding: '2px 4px' }}>
                <ListItemText primary="Ascending" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
                backgroundColor: sortType == 'Descending' ? '#ededed' : '#fff'
              }}
              onClick={() => {
                setSortType('Descending');
              }}
            >
              <ListItemButton sx={{ padding: '2px 4px' }}>
                <ListItemText primary="Descending" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Stack>

      <Stack direction={'row'}>
        <TextField
          variant="outlined"
          placeholder="Search"
          fullWidth
          size="small"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
        />
      </Stack>

      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {/* Suggested :
        <Tooltip title="View File" arrow>
          <CustomIconButton icon={FaRegFileLines} />
        </Tooltip>
        <Tooltip title="View Folder" arrow>
          <CustomIconButton icon={FaRegFolderOpen} />
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        <Tooltip title="Grid View" arrow>
          <CustomIconButton icon={IoGridSharp} />
        </Tooltip>
        <Tooltip title="List View" arrow>
          <CustomIconButton icon={MdGridView} />
        </Tooltip> */}
        <Button component="label" size="small" disabled={disabledUpload}>
          <IoIosAdd /> UPLOAD
          <input
            type="file"
            multiple
            hidden
            onChange={handleFileChange}
            ref={uploadRef}
          />
        </Button>
      </Stack>
    </Stack>
  );
};

export default Actions;
