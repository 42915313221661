/* eslint-disable  */
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ITableHeadColumn,
  PageTitle,
  Table,
  Chat,
  ChatMessage,
  Card,
  Button,
  AppLoading
} from 'components';
import React, { useEffect, useState } from 'react';
import { FORMAT_DATE_TIME, dateToString, FORMAT_DATE } from 'utils';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CreateConfigModal } from './createConfigModal';
import { useListConfigurations } from 'api/sdk';
import moment from 'moment';
import { useSnack } from 'plugins/snack';
import { useDeleteConfiguration } from 'api/sdk';
import { useConfirm } from 'plugins/confirm';
import { useDispatch } from 'react-redux';
import AddconfigIcon from 'components/icons/Addconfig';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAllConfigs } from 'store/configSlice';

export const ConfigurationListPage: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const snack = useSnack();
  const dispatch = useDispatch();
  const [configName, setConfigName] = useState<string | undefined>('');
  const { isLoading, isError, refetch } = useListConfigurations();
  const {
    mutateAsync,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete
  } = useDeleteConfiguration();
  const confirm = useConfirm();
  const [searchTerm, setSearchTerm] = useState('');
  const data = useSelector((state: RootState) => state.config.allConfigs);

  const handleDeleteClick = (recordId: string, recordName: string) => {
    confirm({
      title: 'Delete Configuration',
      content: `Are you sure you want to delete ${recordName} ?`
    }).then(() => {
      handleDelete(recordId);
    });
  };

  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  const handleDelete = async (config: string) => {
    const configId = config?.toString() ?? '';

    mutateAsync({
      params: { config_id: configId }
    })
      .then(res => {
        snack({
          message: 'Configuration deleted successfully',
          severity: 'success'
        });
        refetch().then(res => {
          res.data?.data && dispatch(setAllConfigs(res.data?.data));
        });
      })
      .catch(err => {
        snack({
          message: 'Failed to  delete Configuration',
          severity: 'error'
        });
      });
  };

  const columns: ITableHeadColumn[] = [
    {
      value: 'Configuration name',
      key: 'name',
      align: 'center',
      render: ({ value }) => {
        if (typeof value === 'string') {
          const truncatedName =
            value.length > 15 ? `${value.slice(0, 15)}...` : value;
          return (
            <Tooltip title={value.length > 15 ? value : ''}>
              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                {truncatedName}
              </Typography>
            </Tooltip>
          );
        }
        return null;
      }
    },
    {
      value: 'Created By',
      key: 'creator',
      align: 'center'
    },
    {
      value: 'Latest Update Date',
      key: 'date-modified',
      align: 'center'
    },
    {
      value: 'Actions',
      key: 'actions',
      align: 'center',
      render: ({ value, record }) => {
        return (
          <Box>
            {/* <IconButton
              onClick={() =>
                record &&
                typeof record?.id === 'string' &&
                typeof record?.name === 'string' &&
                handleEditClick(record.id, record?.name)
              }
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton> */}
            {/* <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                record &&
                  typeof record?.id === 'string' &&
                  typeof record?.name === 'string' &&
                  handleEditClick(record.id, record?.name);
              }}
              style={{
                cursor: 'pointer'
              }}
            /> */}
            <IconButton
              onClick={() =>
                record &&
                typeof record?.id === 'string' &&
                typeof record?.name === 'string' &&
                handleDeleteClick(record.id, record?.name)
              }
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  const rows =
    data?.map((item, index) => ({
      id: item?.id,
      name: item.name,
      creator: item?.created_by_name ? item?.created_by_name : 'John Doe',
      'date-modified': moment(item.updated_at).format(FORMAT_DATE),
      'no-of-cameras': 1,
      scene: item.scene,
      cameras: 'any',
      Analytics: item.analytics
    })) || [];

  const filteredRows = rows?.filter(row => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();

    if (trimmedSearchTerm === '') {
      return true;
    }

    const rowName = row.name?.toLowerCase() || '';
    return rowName.includes(trimmedSearchTerm);
  });

  const dataCount = filteredRows.length;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            <Stack direction={'row'} justifyContent={'space-between'} mb={2}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {' '}
                Configuration Dashboard{' '}
              </Typography>
              <Button
                version="light"
                onClick={() => {
                  setConfigName('');
                  setCreateModalOpen(true);
                }}
              >
                <AddIcon /> Add
              </Button>
            </Stack>
            {/* Search Bar */}
            <Box mb={2}>
              <TextField
                fullWidth
                placeholder="Search by Configuration Name"
                variant="outlined"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </Box>
            <Table
              columns={columns}
              rows={filteredRows}
              striped={true}
              loading={isLoading}
            />
            {dataCount === 0 && !isLoading && (
              <>
                <Grid container xs={12} style={{ height: '50vh' }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Stack direction="column">
                      <IconButton
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => setCreateModalOpen(true)}
                      >
                        <AddconfigIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 'bold' }}
                      >
                        ADD CONFIGURATION
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
      <CreateConfigModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        configName={configName}
        onSucess={() => {
          setCreateModalOpen(false);
          refetch().then(res => {
            res.data?.data && dispatch(setAllConfigs(res.data?.data));
          });
        }}
      />
    </>
  );
};
