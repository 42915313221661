import { Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useValidateOrganisation } from 'api/sdk';
import { Button, Typography } from 'components';
import { TextField } from 'components/Form';
import routes from 'routes/urls';
import { useNavigate } from 'react-router-dom';

interface IValidateOrganizationFormValues {
  orgCode: string;
}

interface IValidateOrganizationFormProps {
  onValidate: (token: string) => void;
}

const ValidateOrganizationForm: React.FC<IValidateOrganizationFormProps> = ({
  onValidate
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync, error, isError, isLoading, reset } =
    useValidateOrganisation();

  useEffect(() => {
    // Reset error after 10 seconds
    setTimeout(() => {
      reset();
    }, 10000);
  }, [isError, reset]);

  return (
    <Formik<IValidateOrganizationFormValues>
      initialValues={{ orgCode: '' }}
      onSubmit={(values, formikHelpers) => {
        mutateAsync({
          data: {
            org_code: values.orgCode
          }
        }).then(response => {
          if (!response.data.valid) {
            formikHelpers.resetForm();
            formikHelpers.setFieldError('orgCode', 'Invalid organization code');
          } else {
            onValidate(String(response.data.token));
          }
        });
      }}
      validationSchema={Yup.object().shape({
        orgCode: Yup.string().required('Organization code is required')
      })}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} style={{ marginTop: 50 }}>
            <>
              <TextField
                name="orgCode"
                placeholder="Enter Your Organization Code"
                label="Organisation Code"
                type="text"
                required
                boootstrap
                light
              />
              <Typography
                variant="body2"
                color={'white'}
                align="left"
                style={{ marginTop: 10, cursor: 'pointer' }}
                component={'a'}
                onClick={() => navigate(routes.LOGIN.generate())}
              >
                Back to Login
              </Typography>
              <Button type="submit" actionType="positive">
                {isLoading ? 'Validating...' : 'Validate'}
              </Button>
            </>
          </Stack>
        </form>
      )}
    </Formik>
  );
};
export default ValidateOrganizationForm;
