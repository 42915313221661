/* eslint-disable  */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { colors, getRandomColor, stringToColorCode } from 'utils/color';
import type { RootState } from './store';
import { ConfigSchema } from 'schemas';
import { set } from 'date-fns';
export interface ConfigState {
  selectedConfigurationID?: string;
  allConfigs?: ConfigSchema[];
}

const initialState: ConfigState = {
  selectedConfigurationID: ''
};

export const configSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setSelectedConfiguration: (state, action: PayloadAction<string>) => {
      state.selectedConfigurationID = action.payload;
    },
    setAllConfigs: (state, action: PayloadAction<ConfigSchema[]>) => {
      state.allConfigs = action.payload;
    }
  }
});

export const { setSelectedConfiguration, setAllConfigs } = configSlice.actions;

export default configSlice.reducer;
