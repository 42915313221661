import React, { useState, useEffect, useRef } from 'react';
import Modal from 'components/Modal';
import { Stack } from '@mui/material';
import { Button, Typography } from 'components';
import { useDeleteObjectInferenceId } from 'api/sdk';
import { useLocation, useNavigate } from 'react-router-dom';

interface DeleteModalProps {
  deleteModal: boolean;
  onClose: (data: boolean) => void;
  currentInferenceId: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  deleteModal,
  onClose,
  currentInferenceId
}) => {
  const { mutateAsync: deleteObject, isLoading } = useDeleteObjectInferenceId();
  const navigate = useNavigate();
  const handleDeleteObjectInference = async () => {
    const token = localStorage.getItem('token');

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/training/delete_preprocessed_data?inference_id=${currentInferenceId}`;
    // await deleteObject({ params: { inference_id: currentInferenceId } });
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      navigate('/media');
    } catch (error) {}
  };
  return (
    <Modal open={deleteModal} onClose={() => onClose(false)}>
      <Typography>
        All the progress made on this dataset will be Erased
      </Typography>

      <Typography>are you sure you want to proceed?</Typography>
      <Stack direction={'row'} spacing={1} justifyContent={'center'}>
        <Button version="light" onClick={() => onClose(false)}>
          cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            handleDeleteObjectInference();
            // deleteObject({ params: { inference_id: currentInferenceId } }).then(
            //   res => {
            //     navigate('/Media');
            //   }
            // );
          }}
        >
          Proceed
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
