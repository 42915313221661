import { useListConfigurations } from 'api/sdk';
import FileManager from 'components/filemanager';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAllConfigs } from 'store/configSlice';

export const MediaManager: React.FC = () => {
  const dispatch = useDispatch();

  const { isLoading, isError, refetch } = useListConfigurations();
  useEffect(() => {
    refetch().then(res => {
      res.data?.data && dispatch(setAllConfigs(res.data?.data));
    });
  }, []);

  return (
    <>
      <FileManager />
    </>
  );
};
