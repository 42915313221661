/* eslint-disable  */
import { AppBar as MAppbar, Toolbar } from '@material-ui/core';
import { AccountCircle, MenuOpen, MenuBook } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import React from 'react';
// import OutsideClickHandler from 'react-outside-click-handler';

import logo from 'assets/images/logo.png';

import AccountPaper from './AccountPaper';
import { useAppSelector } from 'hooks';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main
}));

const StyledPopover = styled(Popover)(() => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -10,
    right: 20,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid #fffff'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    rotate: '180deg',
    top: -8,
    right: 13,
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderRight: '8px solid #fffff'
  }
}));

interface IAppbarProps {
  mobile?: boolean;
  onMenuClick?: () => void;
  isMenuOpen?: boolean;
}

/**
 * Application Bar
 */
const Appbar: React.FC<IAppbarProps> = ({
  mobile = false,
  onMenuClick,
  isMenuOpen
}) => {
  // const anchorRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const { profile } = useAppSelector(state => state.auth);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MAppbar position="fixed">
      <StyledToolbar>
        {mobile && (
          <StyledIconButton
            onClick={onMenuClick}
            key={'menu'}
            aria-label={'menu'}
          >
            {isMenuOpen ? <MenuOpen /> : <MenuIcon />}
          </StyledIconButton>
        )}
        <Box display="flex" flexGrow={1}></Box>
        <Box display="flex" flexGrow={1}>
          <img src={logo} alt="logo" style={{ maxHeight: '3rem' }} />
        </Box>
        <StyledIconButton
          onClick={handleClick}
          key={'profile'}
          aria-describedby={'profile'}
        >
          <Typography color={'white'}>Welcome, {profile?.name}!</Typography>
          <Box width={10} />
          <AccountCircle />
        </StyledIconButton>

        <StyledPopover
          id={'profile'}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <AccountPaper />
        </StyledPopover>
      </StyledToolbar>
    </MAppbar>
  );
};

export default Appbar;
