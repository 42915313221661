import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { Button } from 'components/Button/Button';
import { useDeleteDirectoryFileManagerDirectoriesDelete } from 'api/media';
import { DirectoriesContents, Contents } from 'schemasMedia';
import Modal from 'components/Modal';

interface DeleteModalProps {
  openDeleteModal: boolean;
  onClose: (data: boolean) => void;
  currentDirectory: string;
  selectedFiles: Contents[];
  setSelectedFiles: (file: Contents[]) => void;
  setFiles: (Data: Contents[]) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  openDeleteModal,
  onClose,
  currentDirectory,
  selectedFiles,
  setSelectedFiles,
  setFiles
}) => {
  const { mutateAsync: deleteFiles, isLoading } =
    useDeleteDirectoryFileManagerDirectoriesDelete();
  return (
    <Modal
      onClose={() => onClose(false)}
      open={openDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Typography sx={{ textAlign: 'center' }}>
          Are you sure that you want to delete the selected folder?
        </Typography>
        <Stack direction={'row'} justifyContent={'center'} spacing={1} mt={3}>
          <Button
            version="light"
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              selectedFiles != undefined &&
                deleteFiles({
                  data: selectedFiles.map(i => i.path as string),
                  params: { current_directory: currentDirectory }
                }).then(res => {
                  setFiles(res.data.contents);
                  setSelectedFiles([]);
                  onClose(false);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default DeleteModal;
