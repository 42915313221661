// ConfusionMatrix.tsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

interface ConfusionMatrixProps {
  //   data: Record<string, Record<string, number>>;
  data: any;
}

const ConfusionMatrix: React.FC<ConfusionMatrixProps> = ({ data }) => {
  const labels = Object.keys(data); // Extract row and column labels

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: '300px', overflow: 'auto' }}
    >
      <Table stickyHeader aria-label="confusion matrix">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                fontWeight: 'bold',
                border: '1px solid rgba(224, 224, 224, 1)',
                position: 'sticky', // Make the cell sticky
                top: 0, // Stick to the top for the header row
                left: 0, // Stick to the left for the column header
                backgroundColor: 'white', // Background color to avoid overlap
                zIndex: 2 // Ensures row header appears above other cells when scrolling
              }}
            ></TableCell>
            {labels.map(label => (
              <TableCell
                key={label}
                align="center"
                style={{
                  fontWeight: 'bold',
                  border: '1px solid rgba(224, 224, 224, 1)',
                  zIndex: 1
                }}
              >
                Predicted: {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {labels.map(actual => (
            <TableRow key={actual}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  fontWeight: 'bold',
                  border: '1px solid rgba(224, 224, 224, 1)',
                  position: 'sticky', // Make the row header sticky
                  left: 0, // Stick to the left side
                  backgroundColor: 'white', // Background color to avoid overlap with other cells
                  zIndex: 1 // Ensures row header appears above other cells when scrolling
                }}
              >
                Actual: {actual}
              </TableCell>
              {labels.map(predicted => (
                <TableCell
                  key={predicted}
                  align="center"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {data[actual][predicted] || 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConfusionMatrix;
