// /* eslint-disable */
// import React from 'react';

// import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';

// import { TextInput } from './TextInput';
// import styled from 'styled-components';
// import { BaseInput, IBaseInputProps } from '../BaseInput';

// const StyledInput = styled('input')`
//   height: 30px;
//   padding: 0 10px;
// `;

// export interface IOtpInputProps extends MuiOtpInputProps, IBaseInputProps {
//   name: string;
//   label?: string;
//   value: string;
//   onChange: (otp: string) => void;
// }

// export const OtpInput: React.FC<IOtpInputProps> = ({
//   name,
//   label,
//   value,
//   onChange,
//   required = false,
//   helperText,
//   error,
//   light = false
// }) => {
//   const id = name;

//   return (
//     <BaseInput
//       id={id}
//       name={name}
//       label={label}
//       required={required}
//       helperText={helperText}
//       error={error}
//       light={light}
//     >
//       <MuiOtpInput
//         value={value}
//         onChange={onChange}
//         length={6}
//         TextFieldsProps={{
//           size: 'small',
//           variant: 'outlined'
//         }}
//         className="otp-input"
//         validateChar={(char: string) => /^[0-9]*$/.test(char)}
//       />
//     </BaseInput>
//   );
// };

// export default OtpInput;

/* eslint-disable */
import React from 'react';

import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';

import { TextInput } from './TextInput';
import styled from 'styled-components';
import { BaseInput, IBaseInputProps } from '../BaseInput';

// const StyledInput = styled('input')`
//   height: 30px;
//   padding: 0 10px;
//   color: white;
// `;
const StyledOtpInput = styled(MuiOtpInput)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 2px solid white; // White border color
    }
    &:hover fieldset {
      border: 2px solid white; // White border color on hover
    }
    &.Mui-focused fieldset {
      border: 2px solid white; // White border color when focused
    }
  }
`;

export interface IOtpInputProps
  extends MuiOtpInputProps,
    Omit<IBaseInputProps, 'width'> {
  name: string;
  label?: string;
  value: string;
  onChange: (otp: string) => void;
}

// export const OtpInput: React.FC<IOtpInputProps> = ({
//   name,
//   label,
//   value,
//   onChange,
//   required = false,
//   helperText,
//   error,
//   light = false
// }) => {
//   const id = name;

//   return (
//     <BaseInput
//       id={id}
//       name={name}
//       label={label}
//       required={required}
//       helperText={helperText}
//       error={error}
//       light={light}
//     >
//       <MuiOtpInput
//         value={value}
//         onChange={onChange}
//         length={6}
//         TextFieldsProps={{
//           size: 'small',
//           variant: 'outlined'
//         }}
//         className="otp-input"
//         validateChar={(char: string) => /^[0-9]*$/.test(char)}
//       />
//     </BaseInput>
//   );
// };

export interface IOtpInputProps
  extends MuiOtpInputProps,
    Omit<IBaseInputProps, 'width'> {
  name: string;
  label?: string;
  value: string;
  onChange: (otp: string) => void;
}

export const OtpInput: React.FC<IOtpInputProps> = ({
  name,
  label,
  value,
  onChange,
  required = false,
  helperText,
  error,
  light = false,
  ...props // Add this to pass remaining props to MuiOtpInput
}) => {
  const id = name;

  return (
    <BaseInput
      id={id}
      name={name}
      label={label}
      required={required}
      helperText={helperText}
      error={error}
      light={light}
    >
      <StyledOtpInput
        value={value}
        onChange={onChange}
        length={6}
        TextFieldsProps={{
          size: 'small',
          variant: 'outlined'
        }}
        className="otp-input"
        validateChar={(char: string) => /^[0-9]*$/.test(char)}
        {...props} // Add this to pass remaining props to MuiOtpInput
      />
    </BaseInput>
  );
};
export default OtpInput;
