import { Button, Card, Typography } from 'components';
import React, { useRef, useState, useEffect } from 'react';
import { Main } from './main';

import { ViewDetails } from './viewDetails';
import PreprocessedData from 'components/filemanager/preProcessedData';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import {
  useGetObjectsByInference,
  useStartTraining,
  useSyncObjectByInferenceid
} from 'api/sdk';
import {
  LabelAsisitRequestQualityControl,
  ObjResponseSchemaCount,
  ObjSchema,
  SyncObjectsRequest
} from 'schemas';
import { v4 } from 'uuid';

export const TrainPage: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [currentInferenceID, setCurrentInferenceID] = useState<
    string | undefined
  >(undefined);
  const [currentObjects, setCurrentObjects] = useState<ObjSchema[]>([]);
  const [objectAll, setObjectAll] = useState<ObjResponseSchemaCount>();

  const onChange = (num: number) => {
    setStep(num);
  };

  const selectedConfig = useSelector(
    (state: RootState) => state.config.selectedConfigurationID
  );
  const configs = useSelector((state: RootState) => state.config.allConfigs);

  const { refetch: currentObjectsRefetch, isLoading: objectsloading } =
    useGetObjectsByInference(currentInferenceID || '', {
      pipeline_satus: false
    });

  useEffect(() => {
    if (currentInferenceID) {
      currentObjectsRefetch().then(res => {
        setCurrentObjects(res?.data?.data.object_details || []);
        setObjectAll(res?.data?.data);
      });
    }
  }, [currentInferenceID]);

  return (
    <>
      {step == 1 && (
        <Main
          onChange={onChange}
          selectedConfig={selectedConfig}
          configs={configs}
          setCurrentInferenceID={setCurrentInferenceID}
          setViewMode={setViewMode}
          setStep={setStep}
          step={step}
        />
      )}

      {step == 2 && (
        <PreprocessedData
          type="TRAINING"
          selectedConfig={selectedConfig || ''}
          configs={configs || []}
          onSelect={e => {
            setCurrentInferenceID(e);
            setStep(3);
          }}
          onCancel={() => {
            setStep(1);
          }}
        />
      )}
      {step == 3 && (
        <ViewDetails
          onChange={onChange}
          selectedConfig={selectedConfig}
          objects={currentObjects}
          objectAll={objectAll}
          currentInferenceID={currentInferenceID}
          mode={'TRAINING'}
          setStep={setStep}
          viewMode={viewMode}
          configs={configs}

        />
      )}
    </>
  );
};
