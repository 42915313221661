import { Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useRegisterAdmin, useGetOrganisationSites } from 'api/sdk';
import { Button, Typography } from 'components';
import { SelectField, TextField } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import routes from 'routes/urls';

interface IRegisterEmailFormProps {
  token: string;
  onSuccess: () => void;
}

interface IRegisterEmailFormValues {
  email: string;
  siteId: string;
}

const RegisterEmailForm: React.FC<IRegisterEmailFormProps> = ({
  token,
  onSuccess
}) => {
  const { mutateAsync, isError, isLoading, reset } = useRegisterAdmin();
  const { data: sites } = useGetOrganisationSites({ token });
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    siteId: Yup.string().required('Site is required')
  });

  useEffect(() => {
    // Reset error after 10 seconds
    setTimeout(() => {
      reset();
    }, 10000);
  }, [isError, reset]);

  return (
    <Formik<IRegisterEmailFormValues>
      initialValues={{ email: '', siteId: '' }}
      onSubmit={(values, formik) => {
        mutateAsync({
          data: {
            site_id: values.siteId,
            email: values.email
          }
        }).then(() => {
          formik.resetForm();
          onSuccess();
        });
      }}
      validationSchema={validationSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} style={{ marginTop: 50 }}>
            <>
              <SelectField
                name="siteId"
                placeholder="Select site"
                label="Site"
                required
                options={
                  sites?.data.map(site => ({
                    value: site.id,
                    label: site.name
                  })) || []
                }
                light
              />

              <TextField
                name="email"
                placeholder="Enter Email"
                label="Email"
                type="email"
                required
                boootstrap
                light
              />
              <Typography
                variant="body2"
                color={'white'}
                align="left"
                style={{ marginTop: 10, cursor: 'pointer' }}
                component={'a'}
                onClick={() => navigate(routes.LOGIN.generate())}
              >
                Back to Login
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Submit
              </Button>
            </>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default RegisterEmailForm;
