/* eslint-disable */
import { Modal } from 'components';
import React, { useState } from 'react';

import CameraNameForm from './cameraNameForm';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  configName: string | undefined;
  onSucess?: () => void;
}

interface IformValues {
  config_name: string;
  number_of_cameras: number | null;
  analysisOptions: string[];
  imageBasedConfigOptions: string[];
}

export const CreateConfigModal: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  onSucess,
  configName
}) => {
  const [values, setValues] = useState<IformValues>({
    config_name: configName ? configName : '',
    number_of_cameras: null,
    analysisOptions: [],
    imageBasedConfigOptions: []
  });

  const resetValues = () => {
    setValues({
      config_name: configName ? configName : '',
      number_of_cameras: null,
      analysisOptions: [],
      imageBasedConfigOptions: []
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        resetValues(); // Reset values when modal is closed
      }}
      title="General Camera Configuration"
      size="xl"
    >
      <CameraNameForm
        initialValues={{
          config_name: values.config_name,
          number_of_cameras: values.number_of_cameras,
          analysisOptions: values.analysisOptions,
          imageBasedConfigOptions: values.imageBasedConfigOptions
        }}
        onSubmit={val => {
          setValues({ ...values, ...val });
          resetValues(); // Reset values
          onSucess && onSucess(); // Close modal
        }}
        onCancel={() => {
          onClose(); // Close modal
          resetValues(); // Reset values
        }}
      />
    </Modal>
  );
};
