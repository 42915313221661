// import {
//   FormControl,
//   FormHelperText,
//   Input,
//   InputAdornment,
//   InputBase,
//   InputLabel,
//   TextField,
//   alpha,
//   styled
// } from '@mui/material';
// import React, { useMemo, useState } from 'react';

// import { BaseInput, IBaseInputProps } from '../BaseInput';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { color } from 'html2canvas/dist/types/css/types/color';

// export type ITextInputType = 'text' | 'password' | 'email' | 'number';

// export interface ITextInputProps extends IBaseInputProps {
//   placeholder?: string;
//   type?: ITextInputType;
//   value?: string;
//   onChange?: (e: string) => void;
//   light?: boolean; // Login screen requires light label color
//   disabled?: boolean;
//   variant?: 'standard' | 'outlined' | 'filled';
//   boootstrap?: boolean;
//   endAdornment?: React.ReactNode;
//   size?: 'small' | 'medium';
// }

// const StyledInput = styled(Input)<{
//   variant?: 'standard' | 'outlined' | 'filled';
// }>(({ variant }) => {
//   return {
//     width: '100%',
//     marginTop: '5px',
//     ...(variant === 'outlined' && {
//       border: '1px solid #000 !important'
//     }),
//     '& .MuiInputBase-root': {
//       backgroundColor: '#fff'
//     }
//   };
// });

// const BootstrapInputStyled = styled(InputBase)<{ light?: boolean }>(
//   ({ theme, light }) => ({
//     width: '100%',
//     'label + &': {
//       marginTop: theme.spacing(3),
//       background: '#fff'
//     },
//     '& .MuiInputAdornment-root': {
//       marginRight: 0
//     },
//     '& .MuiFormLabel-root': {
//       color: light ? '#000 !important' : '#ffffff !important'
//     },
//     '& .MuiInputBase-input': {
//       width: '100%',
//       borderRadius: 0,
//       position: 'relative',
//       backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
//       border: '1px solid',
//       borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
//       fontSize: 16,

//       padding: '10px 12px',
//       transition: theme.transitions.create([
//         'border-color',
//         'background-color',
//         'box-shadow'
//       ]),
//       '&:focus': {
//         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//         borderColor: theme.palette.primary.main
//       }
//     }
//   })
// );

// const StyledInputLabel = styled(InputLabel)<{
//   light?: boolean;
//   isRequired?: boolean;
// }>(({ theme, light, isRequired }) => ({
//   color: light ? '#000 !important' : '#fff !important',
//   fontWeight: 500,
//   marginBottom: 5,
//   ...(isRequired && {
//     '&::before': {
//       content: '"*"',
//       color: theme.palette.error.main,
//       marginRight: 2
//     }
//   })
// }));

// export const TextInput: React.FC<ITextInputProps> = ({
//   id,
//   name,
//   label,
//   type = 'text',
//   disabled = false,
//   error,
//   helperText,
//   placeholder,
//   required,
//   value,
//   onChange,
//   light,
//   variant,
//   endAdornment,
//   size,
//   boootstrap
// }) => {
//   const inputId = useMemo(() => id || name, [id, name]);

//   const [hidden, setHidden] = useState<boolean>(true);

//   const handleTogglePasswordVisibility = () => {
//     setHidden(!hidden);
//   };

//   const renderEndAdornment = () => {
//     if (type === 'password') {
//       return (
//         <InputAdornment
//           position="end"
//           onClick={handleTogglePasswordVisibility}
//           style={{ cursor: 'pointer' }}
//         >
//           {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
//         </InputAdornment>
//       );
//     }
//     return endAdornment;
//   };

//   if (boootstrap) {
//     return (
//       <FormControl variant="standard">
//         <StyledInputLabel shrink htmlFor={inputId} isRequired={required}>
//           {label}
//         </StyledInputLabel>
//         <BootstrapInputStyled
//           id={inputId}
//           type={type === 'password' && hidden ? 'password' : 'text'}
//           name={name}
//           required={required}
//           disabled={disabled}
//           placeholder={placeholder}
//           value={value}
//           error={!!error}
//           onChange={e => onChange && onChange(e.target.value)}
//           size={size}
//           light={light}
//           endAdornment={renderEndAdornment()}
//         />
//         {{ error } && <FormHelperText error>{error}</FormHelperText>}
//       </FormControl>
//     );
//   }

//   return (
//     <TextField
//       id={inputId}
//       name={name}
//       label={label}
//       required={required}
//       disabled={disabled}
//       placeholder={placeholder}
//       value={value}
//       variant={variant}
//       error={!!error}
//       helperText={error || helperText}
//       onChange={e => onChange && onChange(e.target.value)}
//       InputLabelProps={{
//         shrink: true,
//         required,
//         style: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '15px' }
//       }}
//       size={size}
//       InputProps={{
//         type: type === 'password' && hidden ? 'password' : 'text',
//         endAdornment: renderEndAdornment()
//       }}
//     />
//   );

//   return (
//     <BaseInput
//       id={id}
//       name={name}
//       label={label}
//       required={required}
//       helperText={helperText}
//       error={error}
//       light={light}
//     >
//       <StyledInput
//         name={name}
//         id={inputId}
//         type={type === 'password' ? (hidden ? 'password' : 'text') : type}
//         disabled={disabled}
//         error={!!error}
//         placeholder={placeholder}
//         required={required}
//         value={value}
//         onChange={e => onChange && onChange(e.target.value)}
//         variant={variant}
//         size={size}
//         endAdornment={renderEndAdornment()}
//       />
//     </BaseInput>
//   );
// };

import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  TextField,
  alpha,
  styled
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import { BaseInput, IBaseInputProps } from '../BaseInput';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { color } from 'html2canvas/dist/types/css/types/color';

export type ITextInputType = 'text' | 'password' | 'email' | 'number';

export interface ITextInputProps extends IBaseInputProps {
  placeholder?: string;
  type?: ITextInputType;
  value?: string;
  onChange?: (e: string) => void;
  light?: boolean; // Login screen requires light label color
  disabled?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  boootstrap?: boolean;
  endAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
}

const StyledInput = styled(Input)<{
  variant?: 'standard' | 'outlined' | 'filled';
}>(({ variant }) => {
  return {
    width: '100%',
    marginTop: '5px',
    ...(variant === 'outlined' && {
      border: '1px solid #000 !important'
    }),
    '& .MuiInputBase-root': {
      backgroundColor: '#fff'
    }
  };
});

const BootstrapInputStyled = styled(InputBase)<{ light?: boolean }>(
  ({ theme, light }) => ({
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
      background: '#fff'
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0
    },
    '& .MuiFormLabel-root': {
      color: light ? '#000 !important' : '#ffffff !important'
    },
    '& .MuiInputBase-input': {
      width: '100%',
      borderRadius: 0,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,

      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow'
      ]),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  })
);

const StyledInputLabel = styled(InputLabel)<{
  light?: boolean;
  isRequired?: boolean;
}>(({ theme, light, isRequired }) => ({
  color: light ? '#000 !important' : '#fff !important',
  fontWeight: 500,
  marginBottom: 5,
  ...(isRequired && {
    '&::before': {
      content: '"*"',
      color: theme.palette.error.main,
      marginRight: 2
    }
  })
}));

export const TextInput: React.FC<ITextInputProps> = ({
  id,
  name,
  label,
  type = 'text',
  disabled = false,
  error,
  helperText,
  placeholder,
  required,
  value,
  onChange,
  light,
  variant,
  endAdornment,
  size,
  boootstrap
}) => {
  const inputId = useMemo(() => id || name, [id, name]);

  const [hidden, setHidden] = useState<boolean>(true);

  const handleTogglePasswordVisibility = () => {
    setHidden(!hidden);
  };

  const renderEndAdornment = () => {
    if (type === 'password') {
      return (
        <InputAdornment
          position="end"
          onClick={handleTogglePasswordVisibility}
          style={{ cursor: 'pointer' }}
        >
          {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </InputAdornment>
      );
    }
    return endAdornment;
  };

  if (boootstrap) {
    return (
      <FormControl variant="standard">
        <StyledInputLabel shrink htmlFor={inputId} isRequired={required}>
          {label}
        </StyledInputLabel>
        <BootstrapInputStyled
          id={inputId}
          type={type === 'password' && hidden ? 'password' : 'text'}
          name={name}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          error={!!error}
          onChange={e => onChange && onChange(e.target.value)}
          size={size}
          light={light}
          endAdornment={renderEndAdornment()}
        />
        {{ error } && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <TextField
      id={inputId}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      variant={variant}
      error={!!error}
      helperText={error || helperText}
      onChange={e => onChange && onChange(e.target.value)}
      InputLabelProps={{
        shrink: true,
        required,
        style: { color: 'rgba(0, 0, 0, 0.87)', fontSize: '15px' }
      }}
      size={size}
      InputProps={{
        type: type === 'password' && hidden ? 'password' : 'text',
        endAdornment: renderEndAdornment()
      }}
    />
  );

  return (
    <BaseInput
      id={id}
      name={name}
      label={label}
      required={required}
      helperText={helperText}
      error={error}
      light={light}
    >
      <StyledInput
        name={name}
        id={inputId}
        type={type === 'password' ? (hidden ? 'password' : 'text') : type}
        disabled={disabled}
        error={!!error}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={e => onChange && onChange(e.target.value)}
        variant={variant}
        size={size}
        endAdornment={renderEndAdornment()}
      />
    </BaseInput>
  );
};
