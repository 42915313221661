import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { parse } from 'querystring';
import ErrorBoundary from 'components/ErrorBoundry';
import { ConfirmServiceProvider } from 'plugins/confirm';
import { SnackServiceProvider, SnackbarOptions } from 'plugins/snack';
import { store } from 'store';
import { AppThemeProvider } from 'theme';
import './App.css';
import AppRouter from './routes/Routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: error => {
        console.error('error', error);
      }
    }
  }
});

const snackOptions: SnackbarOptions = {
  snackbarProps: {
    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    autoHideDuration: 3000
  }
};

const App: React.FC = () => {
  return (
    <ErrorBoundary name="App">
      <Provider store={store}>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: (search: string) =>
                parse(search.replace('?', '')),
              includeAllParams: true
            }}
          >
            <AppThemeProvider>
              <QueryClientProvider client={queryClient}>
                <SnackServiceProvider defaultOptions={snackOptions}>
                  <ConfirmServiceProvider>
                    <AppRouter />
                  </ConfirmServiceProvider>
                </SnackServiceProvider>
              </QueryClientProvider>
            </AppThemeProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;