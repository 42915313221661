import {
  Button,
  Card,
  ITableHeadColumn,
  Modal,
  Table,
  Typography
} from 'components';
import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { SelectField, SelectInput } from 'components/Form';
import {
  Stack,
  Box,
  Grid,
  LinearProgress,
  CircularProgress,
  IconButton
} from '@mui/material';

import Reports from '../../assets/images/reportsQuality.svg';
import { ConfigSchema, CreateObjectSchema, ObjSchema } from 'schemas';
import Inference from '../../assets/images/trainingImage.svg';
import { useWebSocket } from 'hooks';

import Env from 'config/env';
import { setSelectedConfiguration } from '../../store/configSlice';
import { useDispatch } from 'react-redux';
// import { useSaveFolderFiles } from 'api/sdk';
import { useGetTrainingResult } from '../../api/sdk';

interface MainProps {
  onChange: (value: number) => void;
  selectedConfig: string | undefined;
  configs: ConfigSchema[] | undefined;

  setCurrentInferenceID: (config: string | undefined) => void;

  setViewMode: (data: boolean) => void;
  setStep: (data: number) => void;
  step: number;
}

export const Main: React.FC<MainProps> = ({
  onChange,
  selectedConfig,
  configs,
  setCurrentInferenceID,

  setViewMode,
  setStep,
  step
}) => {
  // const { mutateAsync: saveFile } = useSaveFolderFiles();
  const { data, refetch: refectchTrainingResults } = useGetTrainingResult({
    config_id: selectedConfig || ''
  });

  const socketUrl = `${Env.WEBSOCKET_URL}/ws/config/${selectedConfig}`;
  const { socket, connectionStatus, sendMessage } = useWebSocket(
    socketUrl || ''
  );
  const [statusValue, setStatus] = useState('QUEUED');

  const [fromWebsocket, setFromWebsocket] = useState<any>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', event => {
        const message = JSON.parse(event.data);
        handleMessagesReceived(message);
      });
    }
  }, [socket]);

  const handleMessagesReceived = (message: any) => {
    const type = message?.type;
    const data = message?.data;
    if (
      message?.pipeline_type === 'quality_control_training' ||
      message?.pipeline_type === 'quality_control_incremental'
    ) {
      setFromWebsocket(data);
      setStatus(type);

      if (type == 'COMPLETED') {
        refectchTrainingResults();
        // handelRefetchObjects().then(res => {
        //   setProcessing(false);
        // });
      }
    }
  };

  useEffect(() => {
    if (selectedConfig != '') {
      refectchTrainingResults().then(res => {});
    }
  }, []);

  const dispatch = useDispatch();

  const columns: ITableHeadColumn[] = [
    {
      value: 'Model Name',
      key: 'ModelName',
      align: 'center'
    },
    {
      value: 'Base Model',
      key: 'baseModel',
      align: 'center'
    },
    {
      value: 'Model Architecture',
      key: 'ModelArchitecture',
      align: 'center'
    },
    {
      value: 'Total # Images',
      key: 'TotalImages',
      align: 'center'
    },
    {
      value: 'Total # Classes',
      key: 'totalClasses',
      align: 'center'
    },

    {
      value: 'Train test split',
      key: 'Traintestsplit',
      align: 'center'
    },
    {
      value: 'Batch size',
      key: 'Batchsize',
      align: 'center'
    },
    {
      value: 'Learning Rate',
      key: 'Learningrate',
      align: 'center'
    },
    {
      value: 'Epochs',
      key: 'Epochs',
      align: 'center'
    },
    {
      value: 'Training Status',
      key: 'TrainingStatus',
      align: 'center',
      render: ({ value, record }) => {
        const trainingStatus = record?.TrainingStatus;
        if (trainingStatus === 'COMPLETED') {
          return <Typography variant="body2">Completed</Typography>;
        }
        if (trainingStatus === 'FAILED') {
          return <Typography variant="body2">Failed</Typography>;
        }
        if (trainingStatus === 'INPROGRESS') {
          return <Typography variant="body2">InProgress</Typography>;
        }

        if (trainingStatus === 'QUEUED') {
          return <Typography variant="body2">Queued</Typography>;
        }

        // If it's neither "COMPLETED" nor "QUEUED", show the progress bar
        return (
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={typeof trainingStatus === 'number' ? trainingStatus : 0} // Ensure it's a number
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                style={{ fontSize: '10px' }}
              >
                {`${Math.round(trainingStatus as number)}%`}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      value: 'Test Accuracy',
      key: 'TestAccuracy'
    },
    {
      value: 'View Training Data',
      key: 'actions',
      render: ({ value, record }) => {
        return (
          <IconButton
            disabled={record.isDeleted != false}
            onClick={() => {
              if (record) {
                setCurrentInferenceID(record.inferenceID as string);
                setViewMode(true);
                onChange(3);
              }
            }}
          >
            <img
              src={Reports}
              alt="Report"
              style={{ width: '30px', height: '30px' }}
            />
          </IconButton>
        );
      }
    }
  ];

  const rows =
    data?.data?.map(item => ({
      ModelName: item.model_name,
      TotalImages: item?.total_image_count,
      totalClasses: item?.class_count || 0,
      ModelArchitecture: item?.model_architecture,
      Traintestsplit: item.train_spilt,
      Batchsize: item.batch_size,
      Learningrate: item.learning_rate,
      Epochs: item.total_epochs,
      TrainingStatus:
        fromWebsocket?.['Test accuracy'] &&
        fromWebsocket?.model_id === item.model_id
          ? 'COMPLETED' // Show "Completed" when data is received and inference_id matches
          : fromWebsocket?.progress !== undefined &&
            fromWebsocket?.model_id === item.model_id
          ? fromWebsocket.progress // Show progress if available and inference_id matches
          : item.status,

      TestAccuracy: item.test_accuracy
        ? (item.test_accuracy * 100).toFixed(2) + ' %'
        : fromWebsocket?.model_id === item.model_id &&
          fromWebsocket?.['Test accuracy']
        ? (fromWebsocket['Test accuracy'] * 100).toFixed(2) + ' %'
        : '0 %',

      inferenceID: item.inference_id,
      baseModel: item?.old_model_name || '-',
      isDeleted: item?.is_deleted
    })) || [];

  return (
    <Card
      style={{
        height: '82vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {configs && (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h6" fontWeight="bold">
            Train Model
          </Typography>

          <SelectInput
            label="Select Configuration"
            placeholder="Select Configuration"
            variant="outlined"
            width={'250px'}
            name="configurationType"
            options={configs?.map(i => ({ label: i.name, value: i.id }))}
            value={selectedConfig || ''}
            onChange={(value: string) => {
              dispatch(setSelectedConfiguration(value));
            }}
          />
        </Stack>
      )}

      {configs && configs.length == 0 && (
        <Stack style={{ textAlign: 'center' }}>
          <Typography>Please add configuration to train a model</Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig == '' && (
        <Stack
          style={{
            height: '41vh',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={Inference}
            alt="Inference"
            style={{ width: '350px', height: '400px' }}
          />
          <Typography style={{ color: '#707070', fontSize: '26px' }}>
            Select Configuration type to proceed with the process{' '}
          </Typography>
        </Stack>
      )}

      {configs && configs.length > 0 && selectedConfig != '' && (
        <Box>
          {
            <Box>
              <Box style={{ height: '65vh', overflowY: 'scroll' }}>
                {rows && (
                  <Table
                    stickyHeader={true}
                    columns={columns}
                    rows={rows}
                    striped={false}
                  />
                )}
              </Box>

              <Stack direction={'row'} justifyContent={'center'}>
                <Button
                  onClick={() => {
                    setViewMode(false);
                    setStep(2);
                  }}
                  style={{ width: '310px', height: '45px' }}
                >
                  {' '}
                  START TRAINING
                </Button>
              </Stack>
            </Box>
          }
        </Box>
      )}

      <Stack spacing={2} direction="column" alignItems="center">
        <Typography
          variant="body2"
          style={{ color: 'red', fontWeight: '16px' }}
        >
          {errorMessage}
        </Typography>
      </Stack>
    </Card>
  );
};
