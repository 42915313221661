import React from "react";
import { Modal, Grid } from "@mui/material";

interface VideoPlayerModalProps {
  imageModal: boolean;
  imageSrc: string;
  setImageModal: (value: boolean) => void;
  setimgSrc: (value: string) => void;
}

const ImageModal: React.FC<VideoPlayerModalProps> = ({
  imageModal,
  imageSrc,
  setImageModal,
  setimgSrc,
}) => {
  return (
    <Modal
      open={imageModal}
      onClose={() => {
        setImageModal(false);
        setimgSrc("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          padding: "1rem",
          boxShadow: 24,
        }}
      >
        <div>
          <img src={imageSrc} alt="" />
        </div>
      </Grid>
    </Modal>
  );
};

export default ImageModal;
