import { Button, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import Delete from '../icons/deleteIcon.svg';
import User from '../icons/userprofile.svg';
import { useConfirm } from 'plugins/confirm';
import { useDeleteMember } from 'api/sdk';

interface ProfileDetailsProps {}

const ProfileDetails: React.FC<ProfileDetailsProps> = () => {
  // Select profile information from the Redux store
  const profile = useSelector((state: RootState) => state.auth.profile);
  const confirm = useConfirm();

  const matchesLg = useMediaQuery('(min-width:960px)');
  const {
    mutateAsync,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete
  } = useDeleteMember();
  const handleDeleteClick = () => {
    confirm({
      title: 'Delete Account',
      content: 'Are you sure you want to delete the account?'
    }).then(() => {
      mutateAsync({
        data: {
          user_id: profile?.id || ''
        }
      }).then(() => {
        localStorage.removeItem('token');
        window.location.reload();
      });
    });
  };
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'space-between'
      }}
    >
      <Grid item xs={12} md={3} lg={2}>
        <Grid>
          <img src={User} alt="" />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} lg={5}>
        <Grid container xs={12} spacing={2}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <span
              style={{
                marginRight: '1vw',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Name:
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>{profile?.name}</span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <span
              style={{
                marginRight: '1vw',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Email ID:
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>{profile?.email}</span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <span
              style={{
                marginRight: '1vw',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Organization:
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              {profile?.organization?.name}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <span
              style={{
                marginRight: '1vw',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Role:
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>{profile?.role}</span>
          </Grid>
          <Grid
            item
            lg={6}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <span
              style={{
                marginRight: '1vw',
                fontWeight: 'bold',
                fontSize: '14px',
                whiteSpace: 'nowrap'
              }}
            >
              Site:
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              {profile?.session_site?.name}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md lg={4}>
        <Grid container xs={12}>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<img src={Delete}></img>}
              style={{
                whiteSpace: 'nowrap',
                width: '200px',
                marginTop: matchesLg ? '16vh' : ''
              }}
              onClick={handleDeleteClick}
            >
              {'Delete My Account'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileDetails;
