/* eslint-disable */
import { Grid, MobileStepper, Typography } from '@mui/material';
import { useCreateConfiguration } from 'api/sdk';
import { Button } from 'components';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Analytics } from 'schemas';
import * as Yup from 'yup';
import { TextField } from 'components/Form';
import { useSnack } from 'plugins/snack';
import { convertServerErrors } from 'utils/form';

interface IProps {
  initialValues: IformValues;
  onSubmit: (values: IformValues) => void;
  onCancel: () => void;
}

interface IformValues {
  config_name: string;
  number_of_cameras: number | null;
  analysisOptions: string[];
  imageBasedConfigOptions: string[];
}

const validationSchema = Yup.object().shape({
  config_name: Yup.string()
    .trim()
    .required('Configuration name is required')
    .min(6, 'Configuration name must be at least 6 characters')
    .max(20, 'Configuration name must be no more than 20 characters')
    .matches(
      /^[a-zA-Z0-9 @\-_()\[\]{}<>:]+$/, // Updated regex pattern
      'Invalid characters. Only alphanumeric, space, dash, and underscore, @, (), [], {}, <>, : are allowed.'
    )
    .max(128, 'Maximum value is 128'),
  number_of_cameras: Yup.number()
    .typeError('Number of cameras must be a number')
    .required('Number of cameras is required')
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximum value is 100')
});

const CameraNameForm: React.FC<IProps> = ({
  onSubmit,
  initialValues,
  onCancel
}) => {
  const { mutateAsync, isLoading } = useCreateConfiguration();

  const snack = useSnack();

  const defaultInitialValues: IformValues = {
    config_name: initialValues.config_name,
    number_of_cameras: 1,
    analysisOptions: [],
    imageBasedConfigOptions: ['quality_control']
  };

  const handleFormSave = async (
    values: IformValues,
    formikHelpers: FormikHelpers<IformValues>
  ) => {
    await mutateAsync({
      data: {
        config_name: values.config_name,
        number_of_cameras: 1,
        analytics: values.imageBasedConfigOptions.map(
          option => option as Analytics
        )
      }
    })
      .then(response => {
        snack({
          message: 'Configuration created successfully',
          severity: 'success'
        });
        onSubmit(values);
      })
      .catch(err => {
        if (err.response.status === 422) {
          formikHelpers.setErrors(
            convertServerErrors(err.response.data.detail)
          );
        }
      });
  };

  return (
    <Formik
      initialValues={defaultInitialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleFormSave}
    >
      {({ handleSubmit, errors, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Row Start */}
            <Grid item xs={7}>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                <Grid item xs={3}>
                  <Typography variant="body2" fontSize={14}>
                    Configuration Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="config_name"
                    placeholder="Enter Config Name"
                    type="text"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'flex-start'}
              ></Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
              }}
              padding={2}
            >
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  onCancel();
                }}
                version="light"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                loading={isLoading}
              >
                {isLoading ? 'Creating...' : 'Proceed'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CameraNameForm;
