/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * VAP-Backend
 * OpenAPI spec version: 1
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  AdminRegisterRequestSchema,
  AvailableSitesResponseSchema,
  BodyCreatePreProcessedObjects,
  ChangePasswordRequestSchema,
  ChangeSiteRequestSchema,
  ConfigSchema,
  CreateAdminSiteResponseSchema,
  CreateConfigurationRequestSchema,
  CreateObjectSchema,
  CreatePreProcessedObjectsParams,
  Createobjectresponseschema,
  DataDistributationResultSchema,
  DeleteConfigurationParams,
  DeleteInferenceParams,
  DeleteObjectInferenceIdParams,
  DeleteObjectObjectIdParams,
  DeletePreprocessedData200,
  DeletePreprocessedDataParams,
  ForgotPasswordRequestSchema,
  GetAllObjectsInferenceParams,
  GetAllObjectsParams,
  GetConfigurationParams,
  GetInferenceDataDistributionParams,
  GetInferenceListParams,
  GetInferenceReportDetailsParams,
  GetInferenceResultParams,
  GetModelListParams,
  GetNoveltyDetailsParams,
  GetObjectsByInferenceParams,
  GetOrganisationSitesParams,
  GetTrainingInferenceDetailsParams,
  GetTrainingResultParams,
  HTTPValidationError,
  InferenceResultSchema,
  InferenceSchema,
  LabelAsisitRequestQualityControl,
  LabelAsisitRequestQualityControlIncremental,
  LabelAsisitRequestQualityControlInference,
  LabelAsisitRequestQualityControlnovelty,
  LiveInference200,
  LoginResponseSchema,
  MemberDeleteRequestSchema,
  MemberRegisterRequestSchema,
  ModelArchitectureResponse,
  NoveltySchema,
  ObjResponseSchema,
  ObjResponseSchemaCount,
  ObjResponseSchemas,
  OrganisationValidateRequestSchema,
  OrganisationValidateResponseSchema,
  ProfileSchema,
  QualitygeneratemaskRequest,
  QueueRequest,
  QueueStatus200,
  ResetPasswordRequestSchema,
  SaveLabaelAssistParams,
  StartIncremetal200,
  StartInference200,
  StartNovelty200,
  StartTraining200,
  SyncObjectByInferenceidParams,
  SyncObjectsRequest,
  TrainingInferenceDetailsSchema,
  TrainingResultSchema,
  UpdateConfigurationParams,
  UserLoginRequestSchema,
  UserProfileSchema,
  VerifyAdminRegisterRequestSchema,
  VerifyTokenRequest
} from '../schemas'
import { apiAxiosInstanceFn } from './apiAxiosInstance';
import type { ErrorType } from './apiAxiosInstance';



/**
 * Login Endpoint
 * @summary Login
 */
export const login = (
    userLoginRequestSchema: UserLoginRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<LoginResponseSchema>(
      {url: `/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userLoginRequestSchema
    },
      );
    }
  


export const getLoginMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, {data: UserLoginRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  login(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
    export type LoginMutationBody = UserLoginRequestSchema
    export type LoginMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Login
 */
export const useLogin = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: UserLoginRequestSchema}, TContext>, }
) => {

      const mutationOptions = getLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Register Request Endpoint
 * @summary Register Request
 */
export const registerAdmin = (
    adminRegisterRequestSchema: AdminRegisterRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/register-admin`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminRegisterRequestSchema
    },
      );
    }
  


export const getRegisterAdminMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerAdmin>>, {data: AdminRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  registerAdmin(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RegisterAdminMutationResult = NonNullable<Awaited<ReturnType<typeof registerAdmin>>>
    export type RegisterAdminMutationBody = AdminRegisterRequestSchema
    export type RegisterAdminMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Register Request
 */
export const useRegisterAdmin = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerAdmin>>, TError,{data: AdminRegisterRequestSchema}, TContext>, }
) => {

      const mutationOptions = getRegisterAdminMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Register Request Member Endpoint
 * @summary Register Request Member
 */
export const registerMember = (
    memberRegisterRequestSchema: MemberRegisterRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/register-member`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: memberRegisterRequestSchema
    },
      );
    }
  


export const getRegisterMemberMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerMember>>, {data: MemberRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  registerMember(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RegisterMemberMutationResult = NonNullable<Awaited<ReturnType<typeof registerMember>>>
    export type RegisterMemberMutationBody = MemberRegisterRequestSchema
    export type RegisterMemberMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Register Request Member
 */
export const useRegisterMember = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerMember>>, TError,{data: MemberRegisterRequestSchema}, TContext>, }
) => {

      const mutationOptions = getRegisterMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Verify Admin Endpoint
 * @summary Verify Admin
 */
export const verifyAdminRegister = (
    verifyAdminRegisterRequestSchema: VerifyAdminRegisterRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/verify-admin-register`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifyAdminRegisterRequestSchema
    },
      );
    }
  


export const getVerifyAdminRegisterMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyAdminRegister>>, {data: VerifyAdminRegisterRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  verifyAdminRegister(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VerifyAdminRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof verifyAdminRegister>>>
    export type VerifyAdminRegisterMutationBody = VerifyAdminRegisterRequestSchema
    export type VerifyAdminRegisterMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Verify Admin
 */
export const useVerifyAdminRegister = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyAdminRegister>>, TError,{data: VerifyAdminRegisterRequestSchema}, TContext>, }
) => {

      const mutationOptions = getVerifyAdminRegisterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Verify token Endpoint
 * @summary Verify token
 */
export const verifyToken = (
    verifyTokenRequest: VerifyTokenRequest,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/verify-token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifyTokenRequest
    },
      );
    }
  


export const getVerifyTokenMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyToken>>, TError,{data: VerifyTokenRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof verifyToken>>, TError,{data: VerifyTokenRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyToken>>, {data: VerifyTokenRequest}> = (props) => {
          const {data} = props ?? {};

          return  verifyToken(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type VerifyTokenMutationResult = NonNullable<Awaited<ReturnType<typeof verifyToken>>>
    export type VerifyTokenMutationBody = VerifyTokenRequest
    export type VerifyTokenMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Verify token
 */
export const useVerifyToken = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyToken>>, TError,{data: VerifyTokenRequest}, TContext>, }
) => {

      const mutationOptions = getVerifyTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Change Password Endpoint
 * @summary Change Password
 */
export const changePassword = (
    changePasswordRequestSchema: ChangePasswordRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/change-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordRequestSchema
    },
      );
    }
  


export const getChangePasswordMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePassword>>, {data: ChangePasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  changePassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof changePassword>>>
    export type ChangePasswordMutationBody = ChangePasswordRequestSchema
    export type ChangePasswordMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Change Password
 */
export const useChangePassword = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{data: ChangePasswordRequestSchema}, TContext>, }
) => {

      const mutationOptions = getChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get User Profile
 * @summary Get Profile
 */
export const getProfile = (
    
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ProfileSchema>(
      {url: `/auth/profile`, method: 'GET', signal
    },
      );
    }
  

export const getGetProfileQueryKey = () => {
    return [`/auth/profile`] as const;
    }

    
export const getGetProfileQueryOptions = <TData = Awaited<ReturnType<typeof getProfile>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfile>>> = ({ signal }) => getProfile(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getProfile>>>
export type GetProfileQueryError = ErrorType<unknown>

/**
 * @summary Get Profile
 */
export const useGetProfile = <TData = Awaited<ReturnType<typeof getProfile>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Viewer Profile
 * @summary Get viewer profile
 */
export const getViewerProfile = (
    
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<UserProfileSchema[]>(
      {url: `/auth/viewer_profilelist`, method: 'GET', signal
    },
      );
    }
  

export const getGetViewerProfileQueryKey = () => {
    return [`/auth/viewer_profilelist`] as const;
    }

    
export const getGetViewerProfileQueryOptions = <TData = Awaited<ReturnType<typeof getViewerProfile>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetViewerProfileQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getViewerProfile>>> = ({ signal }) => getViewerProfile(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetViewerProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getViewerProfile>>>
export type GetViewerProfileQueryError = ErrorType<unknown>

/**
 * @summary Get viewer profile
 */
export const useGetViewerProfile = <TData = Awaited<ReturnType<typeof getViewerProfile>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getViewerProfile>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetViewerProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Forgot Password
 * @summary Forgot Password
 */
export const forgotPassword = (
    forgotPasswordRequestSchema: ForgotPasswordRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/forgot-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: forgotPasswordRequestSchema
    },
      );
    }
  


export const getForgotPasswordMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof forgotPassword>>, {data: ForgotPasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  forgotPassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof forgotPassword>>>
    export type ForgotPasswordMutationBody = ForgotPasswordRequestSchema
    export type ForgotPasswordMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Forgot Password
 */
export const useForgotPassword = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forgotPassword>>, TError,{data: ForgotPasswordRequestSchema}, TContext>, }
) => {

      const mutationOptions = getForgotPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Reset Password Endpoint
 * @summary Reset Password
 */
export const resetPassword = (
    resetPasswordRequestSchema: ResetPasswordRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/reset-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordRequestSchema
    },
      );
    }
  


export const getResetPasswordMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {data: ResetPasswordRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  resetPassword(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    export type ResetPasswordMutationBody = ResetPasswordRequestSchema
    export type ResetPasswordMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Reset Password
 */
export const useResetPassword = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{data: ResetPasswordRequestSchema}, TContext>, }
) => {

      const mutationOptions = getResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * List of Available Sites
 * @summary Available Sites
 */
export const availableSites = (
    
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<AvailableSitesResponseSchema[]>(
      {url: `/auth/available_sites`, method: 'GET', signal
    },
      );
    }
  

export const getAvailableSitesQueryKey = () => {
    return [`/auth/available_sites`] as const;
    }

    
export const getAvailableSitesQueryOptions = <TData = Awaited<ReturnType<typeof availableSites>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAvailableSitesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof availableSites>>> = ({ signal }) => availableSites(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData> & { queryKey: QueryKey }
}

export type AvailableSitesQueryResult = NonNullable<Awaited<ReturnType<typeof availableSites>>>
export type AvailableSitesQueryError = ErrorType<unknown>

/**
 * @summary Available Sites
 */
export const useAvailableSites = <TData = Awaited<ReturnType<typeof availableSites>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof availableSites>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAvailableSitesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Change Site Endpoint
 * @summary Change Site
 */
export const changeSite = (
    changeSiteRequestSchema: ChangeSiteRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/change_site`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeSiteRequestSchema
    },
      );
    }
  


export const getChangeSiteMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeSite>>, {data: ChangeSiteRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  changeSite(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ChangeSiteMutationResult = NonNullable<Awaited<ReturnType<typeof changeSite>>>
    export type ChangeSiteMutationBody = ChangeSiteRequestSchema
    export type ChangeSiteMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Change Site
 */
export const useChangeSite = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeSite>>, TError,{data: ChangeSiteRequestSchema}, TContext>, }
) => {

      const mutationOptions = getChangeSiteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Request Member Endpoint
 * @summary Delete Request Member
 */
export const deleteMember = (
    memberDeleteRequestSchema: MemberDeleteRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/auth/delete-member`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: memberDeleteRequestSchema
    },
      );
    }
  


export const getDeleteMemberMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMember>>, {data: MemberDeleteRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  deleteMember(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteMemberMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMember>>>
    export type DeleteMemberMutationBody = MemberDeleteRequestSchema
    export type DeleteMemberMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Request Member
 */
export const useDeleteMember = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMember>>, TError,{data: MemberDeleteRequestSchema}, TContext>, }
) => {

      const mutationOptions = getDeleteMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Validate Organisation Code
 * @summary Validate Organisation
 */
export const validateOrganisation = (
    organisationValidateRequestSchema: OrganisationValidateRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<OrganisationValidateResponseSchema>(
      {url: `/organisations/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organisationValidateRequestSchema
    },
      );
    }
  


export const getValidateOrganisationMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateOrganisation>>, {data: OrganisationValidateRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  validateOrganisation(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ValidateOrganisationMutationResult = NonNullable<Awaited<ReturnType<typeof validateOrganisation>>>
    export type ValidateOrganisationMutationBody = OrganisationValidateRequestSchema
    export type ValidateOrganisationMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Validate Organisation
 */
export const useValidateOrganisation = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateOrganisation>>, TError,{data: OrganisationValidateRequestSchema}, TContext>, }
) => {

      const mutationOptions = getValidateOrganisationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get All Sites of an Organisation by token. (Applicable for Admin regiser page only)
 * @summary Get Organisation Sites
 */
export const getOrganisationSites = (
    params: GetOrganisationSitesParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<CreateAdminSiteResponseSchema[]>(
      {url: `/sites/get-organisation-sites`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetOrganisationSitesQueryKey = (params: GetOrganisationSitesParams,) => {
    return [`/sites/get-organisation-sites`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrganisationSitesQueryOptions = <TData = Awaited<ReturnType<typeof getOrganisationSites>>, TError = ErrorType<HTTPValidationError>>(params: GetOrganisationSitesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganisationSitesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganisationSites>>> = ({ signal }) => getOrganisationSites(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganisationSitesQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganisationSites>>>
export type GetOrganisationSitesQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Organisation Sites
 */
export const useGetOrganisationSites = <TData = Awaited<ReturnType<typeof getOrganisationSites>>, TError = ErrorType<HTTPValidationError>>(
 params: GetOrganisationSitesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganisationSites>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrganisationSitesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create Configuration
 * @summary Create Configuration
 */
export const createConfiguration = (
    createConfigurationRequestSchema: CreateConfigurationRequestSchema,
 ) => {
      
      
      return apiAxiosInstanceFn<ConfigSchema>(
      {url: `/training/create_configuration`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createConfigurationRequestSchema
    },
      );
    }
  


export const getCreateConfigurationMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createConfiguration>>, {data: CreateConfigurationRequestSchema}> = (props) => {
          const {data} = props ?? {};

          return  createConfiguration(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof createConfiguration>>>
    export type CreateConfigurationMutationBody = CreateConfigurationRequestSchema
    export type CreateConfigurationMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Configuration
 */
export const useCreateConfiguration = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createConfiguration>>, TError,{data: CreateConfigurationRequestSchema}, TContext>, }
) => {

      const mutationOptions = getCreateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Configuration
 * @summary Get Configuration
 */
export const getConfiguration = (
    params: GetConfigurationParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ConfigSchema>(
      {url: `/training/get_configuration`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetConfigurationQueryKey = (params: GetConfigurationParams,) => {
    return [`/training/get_configuration`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getConfiguration>>, TError = ErrorType<HTTPValidationError>>(params: GetConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConfigurationQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfiguration>>> = ({ signal }) => getConfiguration(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getConfiguration>>>
export type GetConfigurationQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Configuration
 */
export const useGetConfiguration = <TData = Awaited<ReturnType<typeof getConfiguration>>, TError = ErrorType<HTTPValidationError>>(
 params: GetConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfiguration>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConfigurationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * List Configurations
 * @summary List Configurations
 */
export const listConfigurations = (
    
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ConfigSchema[]>(
      {url: `/training/list_configurations`, method: 'GET', signal
    },
      );
    }
  

export const getListConfigurationsQueryKey = () => {
    return [`/training/list_configurations`] as const;
    }

    
export const getListConfigurationsQueryOptions = <TData = Awaited<ReturnType<typeof listConfigurations>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListConfigurationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listConfigurations>>> = ({ signal }) => listConfigurations(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData> & { queryKey: QueryKey }
}

export type ListConfigurationsQueryResult = NonNullable<Awaited<ReturnType<typeof listConfigurations>>>
export type ListConfigurationsQueryError = ErrorType<unknown>

/**
 * @summary List Configurations
 */
export const useListConfigurations = <TData = Awaited<ReturnType<typeof listConfigurations>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigurations>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListConfigurationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update Configuration Name
 * @summary Update Configuration Name
 */
export const updateConfiguration = (
    params: UpdateConfigurationParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/update_configuration`, method: 'PATCH',
        params
    },
      );
    }
  


export const getUpdateConfigurationMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateConfiguration>>, {params: UpdateConfigurationParams}> = (props) => {
          const {params} = props ?? {};

          return  updateConfiguration(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof updateConfiguration>>>
    
    export type UpdateConfigurationMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Configuration Name
 */
export const useUpdateConfiguration = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConfiguration>>, TError,{params: UpdateConfigurationParams}, TContext>, }
) => {

      const mutationOptions = getUpdateConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Configuration
 * @summary Delete Configuration
 */
export const deleteConfiguration = (
    params: DeleteConfigurationParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/delete_configuration`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteConfigurationMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteConfiguration>>, {params: DeleteConfigurationParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteConfiguration(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteConfiguration>>>
    
    export type DeleteConfigurationMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Configuration
 */
export const useDeleteConfiguration = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteConfiguration>>, TError,{params: DeleteConfigurationParams}, TContext>, }
) => {

      const mutationOptions = getDeleteConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add Training Input to Queue Quality Control
 * @summary Add Training Input to Queue Quality Control
 */
export const startTraining = (
    labelAsisitRequestQualityControl: LabelAsisitRequestQualityControl,
 ) => {
      
      
      return apiAxiosInstanceFn<StartTraining200>(
      {url: `/training/start_training`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelAsisitRequestQualityControl
    },
      );
    }
  


export const getStartTrainingMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startTraining>>, TError,{data: LabelAsisitRequestQualityControl}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startTraining>>, TError,{data: LabelAsisitRequestQualityControl}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startTraining>>, {data: LabelAsisitRequestQualityControl}> = (props) => {
          const {data} = props ?? {};

          return  startTraining(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type StartTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof startTraining>>>
    export type StartTrainingMutationBody = LabelAsisitRequestQualityControl
    export type StartTrainingMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Training Input to Queue Quality Control
 */
export const useStartTraining = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startTraining>>, TError,{data: LabelAsisitRequestQualityControl}, TContext>, }
) => {

      const mutationOptions = getStartTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add Inference Input to Queue Quality Control
 * @summary Add Inference Input to Queue Quality Control 
 */
export const startInference = (
    labelAsisitRequestQualityControlInference: LabelAsisitRequestQualityControlInference,
 ) => {
      
      
      return apiAxiosInstanceFn<StartInference200>(
      {url: `/training/start_inference`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelAsisitRequestQualityControlInference
    },
      );
    }
  


export const getStartInferenceMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startInference>>, {data: LabelAsisitRequestQualityControlInference}> = (props) => {
          const {data} = props ?? {};

          return  startInference(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type StartInferenceMutationResult = NonNullable<Awaited<ReturnType<typeof startInference>>>
    export type StartInferenceMutationBody = LabelAsisitRequestQualityControlInference
    export type StartInferenceMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Inference Input to Queue Quality Control 
 */
export const useStartInference = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext>, }
) => {

      const mutationOptions = getStartInferenceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Model List According to Config ID
 * @summary Get Model List
 */
export const getModelList = (
    params: GetModelListParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<TrainingResultSchema[]>(
      {url: `/training/get_model_list`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetModelListQueryKey = (params: GetModelListParams,) => {
    return [`/training/get_model_list`, ...(params ? [params]: [])] as const;
    }

    
export const getGetModelListQueryOptions = <TData = Awaited<ReturnType<typeof getModelList>>, TError = ErrorType<HTTPValidationError>>(params: GetModelListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getModelList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetModelListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelList>>> = ({ signal }) => getModelList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModelList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetModelListQueryResult = NonNullable<Awaited<ReturnType<typeof getModelList>>>
export type GetModelListQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Model List
 */
export const useGetModelList = <TData = Awaited<ReturnType<typeof getModelList>>, TError = ErrorType<HTTPValidationError>>(
 params: GetModelListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getModelList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModelListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Inference List According to Config ID
 * @summary Get Inference List
 */
export const getInferenceList = (
    params: GetInferenceListParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<InferenceSchema[]>(
      {url: `/training/get_inference_list`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetInferenceListQueryKey = (params: GetInferenceListParams,) => {
    return [`/training/get_inference_list`, ...(params ? [params]: [])] as const;
    }

    
export const getGetInferenceListQueryOptions = <TData = Awaited<ReturnType<typeof getInferenceList>>, TError = ErrorType<HTTPValidationError>>(params: GetInferenceListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInferenceListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInferenceList>>> = ({ signal }) => getInferenceList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInferenceList>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInferenceListQueryResult = NonNullable<Awaited<ReturnType<typeof getInferenceList>>>
export type GetInferenceListQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Inference List
 */
export const useGetInferenceList = <TData = Awaited<ReturnType<typeof getInferenceList>>, TError = ErrorType<HTTPValidationError>>(
 params: GetInferenceListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInferenceListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Inference Result
 * @summary Get Inference Result
 */
export const getInferenceResult = (
    params: GetInferenceResultParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<InferenceResultSchema>(
      {url: `/training/get_inference_result`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetInferenceResultQueryKey = (params: GetInferenceResultParams,) => {
    return [`/training/get_inference_result`, ...(params ? [params]: [])] as const;
    }

    
export const getGetInferenceResultQueryOptions = <TData = Awaited<ReturnType<typeof getInferenceResult>>, TError = ErrorType<HTTPValidationError>>(params: GetInferenceResultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceResult>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInferenceResultQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInferenceResult>>> = ({ signal }) => getInferenceResult(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInferenceResult>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInferenceResultQueryResult = NonNullable<Awaited<ReturnType<typeof getInferenceResult>>>
export type GetInferenceResultQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Inference Result
 */
export const useGetInferenceResult = <TData = Awaited<ReturnType<typeof getInferenceResult>>, TError = ErrorType<HTTPValidationError>>(
 params: GetInferenceResultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceResult>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInferenceResultQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Inference Data Distribution
 * @summary Get Inference Data Distribution
 */
export const getInferenceDataDistribution = (
    params: GetInferenceDataDistributionParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<DataDistributationResultSchema[]>(
      {url: `/training/get_inference_data_distribution`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetInferenceDataDistributionQueryKey = (params: GetInferenceDataDistributionParams,) => {
    return [`/training/get_inference_data_distribution`, ...(params ? [params]: [])] as const;
    }

    
export const getGetInferenceDataDistributionQueryOptions = <TData = Awaited<ReturnType<typeof getInferenceDataDistribution>>, TError = ErrorType<HTTPValidationError>>(params: GetInferenceDataDistributionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceDataDistribution>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInferenceDataDistributionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInferenceDataDistribution>>> = ({ signal }) => getInferenceDataDistribution(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInferenceDataDistribution>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInferenceDataDistributionQueryResult = NonNullable<Awaited<ReturnType<typeof getInferenceDataDistribution>>>
export type GetInferenceDataDistributionQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Inference Data Distribution
 */
export const useGetInferenceDataDistribution = <TData = Awaited<ReturnType<typeof getInferenceDataDistribution>>, TError = ErrorType<HTTPValidationError>>(
 params: GetInferenceDataDistributionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceDataDistribution>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInferenceDataDistributionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get training Result
 * @summary Get training Result
 */
export const getTrainingResult = (
    params: GetTrainingResultParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<TrainingResultSchema[]>(
      {url: `/training/get_training_result`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTrainingResultQueryKey = (params: GetTrainingResultParams,) => {
    return [`/training/get_training_result`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTrainingResultQueryOptions = <TData = Awaited<ReturnType<typeof getTrainingResult>>, TError = ErrorType<HTTPValidationError>>(params: GetTrainingResultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrainingResult>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTrainingResultQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrainingResult>>> = ({ signal }) => getTrainingResult(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTrainingResult>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTrainingResultQueryResult = NonNullable<Awaited<ReturnType<typeof getTrainingResult>>>
export type GetTrainingResultQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get training Result
 */
export const useGetTrainingResult = <TData = Awaited<ReturnType<typeof getTrainingResult>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTrainingResultParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrainingResult>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTrainingResultQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add Novelty Input to Queue Quality Control
 * @summary Add Novelty Input to Queue Quality Control
 */
export const startNovelty = (
    labelAsisitRequestQualityControlnovelty: LabelAsisitRequestQualityControlnovelty,
 ) => {
      
      
      return apiAxiosInstanceFn<StartNovelty200>(
      {url: `/training/start_novelty`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelAsisitRequestQualityControlnovelty
    },
      );
    }
  


export const getStartNoveltyMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startNovelty>>, TError,{data: LabelAsisitRequestQualityControlnovelty}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startNovelty>>, TError,{data: LabelAsisitRequestQualityControlnovelty}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startNovelty>>, {data: LabelAsisitRequestQualityControlnovelty}> = (props) => {
          const {data} = props ?? {};

          return  startNovelty(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type StartNoveltyMutationResult = NonNullable<Awaited<ReturnType<typeof startNovelty>>>
    export type StartNoveltyMutationBody = LabelAsisitRequestQualityControlnovelty
    export type StartNoveltyMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Novelty Input to Queue Quality Control
 */
export const useStartNovelty = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startNovelty>>, TError,{data: LabelAsisitRequestQualityControlnovelty}, TContext>, }
) => {

      const mutationOptions = getStartNoveltyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add Incremental Input to Queue Quality Control
 * @summary Add Incremental Input to Queue Quality Control
 */
export const startIncremetal = (
    labelAsisitRequestQualityControlIncremental: LabelAsisitRequestQualityControlIncremental,
 ) => {
      
      
      return apiAxiosInstanceFn<StartIncremetal200>(
      {url: `/training/start_incremetal`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelAsisitRequestQualityControlIncremental
    },
      );
    }
  


export const getStartIncremetalMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startIncremetal>>, TError,{data: LabelAsisitRequestQualityControlIncremental}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof startIncremetal>>, TError,{data: LabelAsisitRequestQualityControlIncremental}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startIncremetal>>, {data: LabelAsisitRequestQualityControlIncremental}> = (props) => {
          const {data} = props ?? {};

          return  startIncremetal(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type StartIncremetalMutationResult = NonNullable<Awaited<ReturnType<typeof startIncremetal>>>
    export type StartIncremetalMutationBody = LabelAsisitRequestQualityControlIncremental
    export type StartIncremetalMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Incremental Input to Queue Quality Control
 */
export const useStartIncremetal = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startIncremetal>>, TError,{data: LabelAsisitRequestQualityControlIncremental}, TContext>, }
) => {

      const mutationOptions = getStartIncremetalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Training Inference Details
 * @summary Get training inference details
 */
export const getTrainingInferenceDetails = (
    params: GetTrainingInferenceDetailsParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<TrainingInferenceDetailsSchema>(
      {url: `/training/get_training_inference_details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetTrainingInferenceDetailsQueryKey = (params: GetTrainingInferenceDetailsParams,) => {
    return [`/training/get_training_inference_details`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTrainingInferenceDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getTrainingInferenceDetails>>, TError = ErrorType<HTTPValidationError>>(params: GetTrainingInferenceDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrainingInferenceDetails>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTrainingInferenceDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrainingInferenceDetails>>> = ({ signal }) => getTrainingInferenceDetails(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTrainingInferenceDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTrainingInferenceDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getTrainingInferenceDetails>>>
export type GetTrainingInferenceDetailsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get training inference details
 */
export const useGetTrainingInferenceDetails = <TData = Awaited<ReturnType<typeof getTrainingInferenceDetails>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTrainingInferenceDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrainingInferenceDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTrainingInferenceDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get novelty Details
 * @summary Get novelty details
 */
export const getNoveltyDetails = (
    params: GetNoveltyDetailsParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<NoveltySchema>(
      {url: `/training/get_novelty_details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetNoveltyDetailsQueryKey = (params: GetNoveltyDetailsParams,) => {
    return [`/training/get_novelty_details`, ...(params ? [params]: [])] as const;
    }

    
export const getGetNoveltyDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getNoveltyDetails>>, TError = ErrorType<HTTPValidationError>>(params: GetNoveltyDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNoveltyDetails>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNoveltyDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNoveltyDetails>>> = ({ signal }) => getNoveltyDetails(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNoveltyDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNoveltyDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getNoveltyDetails>>>
export type GetNoveltyDetailsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get novelty details
 */
export const useGetNoveltyDetails = <TData = Awaited<ReturnType<typeof getNoveltyDetails>>, TError = ErrorType<HTTPValidationError>>(
 params: GetNoveltyDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNoveltyDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNoveltyDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get inference Details
 * @summary Get inference details
 */
export const getInferenceReportDetails = (
    params: GetInferenceReportDetailsParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<NoveltySchema[]>(
      {url: `/training/get_inference_report_details`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetInferenceReportDetailsQueryKey = (params: GetInferenceReportDetailsParams,) => {
    return [`/training/get_inference_report_details`, ...(params ? [params]: [])] as const;
    }

    
export const getGetInferenceReportDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getInferenceReportDetails>>, TError = ErrorType<HTTPValidationError>>(params: GetInferenceReportDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceReportDetails>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInferenceReportDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInferenceReportDetails>>> = ({ signal }) => getInferenceReportDetails(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInferenceReportDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInferenceReportDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getInferenceReportDetails>>>
export type GetInferenceReportDetailsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get inference details
 */
export const useGetInferenceReportDetails = <TData = Awaited<ReturnType<typeof getInferenceReportDetails>>, TError = ErrorType<HTTPValidationError>>(
 params: GetInferenceReportDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInferenceReportDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInferenceReportDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete Inference
 * @summary Delete Inference
 */
export const deleteInference = (
    params: DeleteInferenceParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/delete_inference`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteInferenceMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInference>>, TError,{params: DeleteInferenceParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteInference>>, TError,{params: DeleteInferenceParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteInference>>, {params: DeleteInferenceParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteInference(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteInferenceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInference>>>
    
    export type DeleteInferenceMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Inference
 */
export const useDeleteInference = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInference>>, TError,{params: DeleteInferenceParams}, TContext>, }
) => {

      const mutationOptions = getDeleteInferenceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add To Label Assist Queue Quality generate_mask
 * @summary Add To Label Assist Queue Quality generate_mask
 */
export const generateMask = (
    qualitygeneratemaskRequest: QualitygeneratemaskRequest,
 ) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchema>(
      {url: `/training/generate_mask`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: qualitygeneratemaskRequest
    },
      );
    }
  


export const getGenerateMaskMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateMask>>, TError,{data: QualitygeneratemaskRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof generateMask>>, TError,{data: QualitygeneratemaskRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateMask>>, {data: QualitygeneratemaskRequest}> = (props) => {
          const {data} = props ?? {};

          return  generateMask(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type GenerateMaskMutationResult = NonNullable<Awaited<ReturnType<typeof generateMask>>>
    export type GenerateMaskMutationBody = QualitygeneratemaskRequest
    export type GenerateMaskMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add To Label Assist Queue Quality generate_mask
 */
export const useGenerateMask = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateMask>>, TError,{data: QualitygeneratemaskRequest}, TContext>, }
) => {

      const mutationOptions = getGenerateMaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete object by inference_id
 * @summary Delete object by inference_id
 */
export const deleteObjectInferenceId = (
    params: DeleteObjectInferenceIdParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/delete_object_ineference`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteObjectInferenceIdMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteObjectInferenceId>>, TError,{params: DeleteObjectInferenceIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteObjectInferenceId>>, TError,{params: DeleteObjectInferenceIdParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteObjectInferenceId>>, {params: DeleteObjectInferenceIdParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteObjectInferenceId(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteObjectInferenceIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteObjectInferenceId>>>
    
    export type DeleteObjectInferenceIdMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete object by inference_id
 */
export const useDeleteObjectInferenceId = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteObjectInferenceId>>, TError,{params: DeleteObjectInferenceIdParams}, TContext>, }
) => {

      const mutationOptions = getDeleteObjectInferenceIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete object by object_id
 * @summary Delete object by object_id
 */
export const deleteObjectObjectId = (
    params: DeleteObjectObjectIdParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/delete_object`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeleteObjectObjectIdMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteObjectObjectId>>, TError,{params: DeleteObjectObjectIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteObjectObjectId>>, TError,{params: DeleteObjectObjectIdParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteObjectObjectId>>, {params: DeleteObjectObjectIdParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteObjectObjectId(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteObjectObjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteObjectObjectId>>>
    
    export type DeleteObjectObjectIdMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete object by object_id
 */
export const useDeleteObjectObjectId = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteObjectObjectId>>, TError,{params: DeleteObjectObjectIdParams}, TContext>, }
) => {

      const mutationOptions = getDeleteObjectObjectIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Sending data for live inference
 * @summary Start Live Inference
 */
export const liveInference = (
    labelAsisitRequestQualityControlInference: LabelAsisitRequestQualityControlInference,
 ) => {
      
      
      return apiAxiosInstanceFn<LiveInference200>(
      {url: `/training/live_inference`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: labelAsisitRequestQualityControlInference
    },
      );
    }
  


export const getLiveInferenceMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof liveInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof liveInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof liveInference>>, {data: LabelAsisitRequestQualityControlInference}> = (props) => {
          const {data} = props ?? {};

          return  liveInference(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LiveInferenceMutationResult = NonNullable<Awaited<ReturnType<typeof liveInference>>>
    export type LiveInferenceMutationBody = LabelAsisitRequestQualityControlInference
    export type LiveInferenceMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Start Live Inference
 */
export const useLiveInference = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof liveInference>>, TError,{data: LabelAsisitRequestQualityControlInference}, TContext>, }
) => {

      const mutationOptions = getLiveInferenceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * To maintain queue status
 * @summary Update the queue status
 */
export const queueStatus = (
    queueRequest: QueueRequest,
 ) => {
      
      
      return apiAxiosInstanceFn<QueueStatus200>(
      {url: `/training/queue_status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: queueRequest
    },
      );
    }
  


export const getQueueStatusMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queueStatus>>, TError,{data: QueueRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof queueStatus>>, TError,{data: QueueRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queueStatus>>, {data: QueueRequest}> = (props) => {
          const {data} = props ?? {};

          return  queueStatus(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type QueueStatusMutationResult = NonNullable<Awaited<ReturnType<typeof queueStatus>>>
    export type QueueStatusMutationBody = QueueRequest
    export type QueueStatusMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update the queue status
 */
export const useQueueStatus = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queueStatus>>, TError,{data: QueueRequest}, TContext>, }
) => {

      const mutationOptions = getQueueStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get all objects of a Inference ID
 * @summary get all objects of a Inference ID
 */
export const getObjectsByInference = (
    inferenceId: string,
    params: GetObjectsByInferenceParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchemaCount>(
      {url: `/training/configuration/inference/${inferenceId}/object`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetObjectsByInferenceQueryKey = (inferenceId: string,
    params: GetObjectsByInferenceParams,) => {
    return [`/training/configuration/inference/${inferenceId}/object`, ...(params ? [params]: [])] as const;
    }

    
export const getGetObjectsByInferenceQueryOptions = <TData = Awaited<ReturnType<typeof getObjectsByInference>>, TError = ErrorType<HTTPValidationError>>(inferenceId: string,
    params: GetObjectsByInferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getObjectsByInference>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetObjectsByInferenceQueryKey(inferenceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getObjectsByInference>>> = ({ signal }) => getObjectsByInference(inferenceId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(inferenceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getObjectsByInference>>, TError, TData> & { queryKey: QueryKey }
}

export type GetObjectsByInferenceQueryResult = NonNullable<Awaited<ReturnType<typeof getObjectsByInference>>>
export type GetObjectsByInferenceQueryError = ErrorType<HTTPValidationError>

/**
 * @summary get all objects of a Inference ID
 */
export const useGetObjectsByInference = <TData = Awaited<ReturnType<typeof getObjectsByInference>>, TError = ErrorType<HTTPValidationError>>(
 inferenceId: string,
    params: GetObjectsByInferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getObjectsByInference>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetObjectsByInferenceQueryOptions(inferenceId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Sync Objects
 * @summary Sync Objects
 */
export const syncObjectByInferenceid = (
    inferenceId: string,
    syncObjectsRequest: SyncObjectsRequest[],
    params: SyncObjectByInferenceidParams,
 ) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchema>(
      {url: `/training/configuration/inference/${inferenceId}/object`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: syncObjectsRequest,
        params
    },
      );
    }
  


export const getSyncObjectByInferenceidMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObjectByInferenceid>>, TError,{inferenceId: string;data: SyncObjectsRequest[];params: SyncObjectByInferenceidParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof syncObjectByInferenceid>>, TError,{inferenceId: string;data: SyncObjectsRequest[];params: SyncObjectByInferenceidParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncObjectByInferenceid>>, {inferenceId: string;data: SyncObjectsRequest[];params: SyncObjectByInferenceidParams}> = (props) => {
          const {inferenceId,data,params} = props ?? {};

          return  syncObjectByInferenceid(inferenceId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SyncObjectByInferenceidMutationResult = NonNullable<Awaited<ReturnType<typeof syncObjectByInferenceid>>>
    export type SyncObjectByInferenceidMutationBody = SyncObjectsRequest[]
    export type SyncObjectByInferenceidMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Sync Objects
 */
export const useSyncObjectByInferenceid = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObjectByInferenceid>>, TError,{inferenceId: string;data: SyncObjectsRequest[];params: SyncObjectByInferenceidParams}, TContext>, }
) => {

      const mutationOptions = getSyncObjectByInferenceidMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get model architecture list
 * @summary get model architecture list
 */
export const modelArchitectureList = (
    
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ModelArchitectureResponse>(
      {url: `/training/model_architecture_list`, method: 'GET', signal
    },
      );
    }
  

export const getModelArchitectureListQueryKey = () => {
    return [`/training/model_architecture_list`] as const;
    }

    
export const getModelArchitectureListQueryOptions = <TData = Awaited<ReturnType<typeof modelArchitectureList>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof modelArchitectureList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getModelArchitectureListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof modelArchitectureList>>> = ({ signal }) => modelArchitectureList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof modelArchitectureList>>, TError, TData> & { queryKey: QueryKey }
}

export type ModelArchitectureListQueryResult = NonNullable<Awaited<ReturnType<typeof modelArchitectureList>>>
export type ModelArchitectureListQueryError = ErrorType<unknown>

/**
 * @summary get model architecture list
 */
export const useModelArchitectureList = <TData = Awaited<ReturnType<typeof modelArchitectureList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof modelArchitectureList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getModelArchitectureListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get all objects of a configuration
 * @summary get all objects of a configuration
 */
export const getAllObjects = (
    configId: string,
    params: GetAllObjectsParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchema>(
      {url: `/training/configuration/${configId}/object`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAllObjectsQueryKey = (configId: string,
    params: GetAllObjectsParams,) => {
    return [`/training/configuration/${configId}/object`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllObjectsQueryOptions = <TData = Awaited<ReturnType<typeof getAllObjects>>, TError = ErrorType<HTTPValidationError>>(configId: string,
    params: GetAllObjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllObjectsQueryKey(configId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllObjects>>> = ({ signal }) => getAllObjects(configId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(configId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllObjects>>>
export type GetAllObjectsQueryError = ErrorType<HTTPValidationError>

/**
 * @summary get all objects of a configuration
 */
export const useGetAllObjects = <TData = Awaited<ReturnType<typeof getAllObjects>>, TError = ErrorType<HTTPValidationError>>(
 configId: string,
    params: GetAllObjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjects>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllObjectsQueryOptions(configId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Sync Objects
 * @summary Sync Objects
 */
export const syncObject = (
    configId: string,
    syncObjectsRequest: SyncObjectsRequest[],
 ) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchema>(
      {url: `/training/configuration/${configId}/object`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: syncObjectsRequest
    },
      );
    }
  


export const getSyncObjectMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;data: SyncObjectsRequest[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;data: SyncObjectsRequest[]}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncObject>>, {configId: string;data: SyncObjectsRequest[]}> = (props) => {
          const {configId,data} = props ?? {};

          return  syncObject(configId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SyncObjectMutationResult = NonNullable<Awaited<ReturnType<typeof syncObject>>>
    export type SyncObjectMutationBody = SyncObjectsRequest[]
    export type SyncObjectMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Sync Objects
 */
export const useSyncObject = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncObject>>, TError,{configId: string;data: SyncObjectsRequest[]}, TContext>, }
) => {

      const mutationOptions = getSyncObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create Objects
 * @summary Create Objects
 */
export const createObjects = (
    configId: string,
    createObjectSchema: CreateObjectSchema[],
 ) => {
      
      
      return apiAxiosInstanceFn<Createobjectresponseschema[]>(
      {url: `/training/configuration/${configId}/object`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createObjectSchema
    },
      );
    }
  


export const getCreateObjectsMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createObjects>>, TError,{configId: string;data: CreateObjectSchema[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createObjects>>, TError,{configId: string;data: CreateObjectSchema[]}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createObjects>>, {configId: string;data: CreateObjectSchema[]}> = (props) => {
          const {configId,data} = props ?? {};

          return  createObjects(configId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateObjectsMutationResult = NonNullable<Awaited<ReturnType<typeof createObjects>>>
    export type CreateObjectsMutationBody = CreateObjectSchema[]
    export type CreateObjectsMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Objects
 */
export const useCreateObjects = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createObjects>>, TError,{configId: string;data: CreateObjectSchema[]}, TContext>, }
) => {

      const mutationOptions = getCreateObjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get all objects of a configuration  group by inference_id
 * @summary get all objects of a configuration group by inference_id
 */
export const getAllObjectsInference = (
    params?: GetAllObjectsInferenceParams,
 signal?: AbortSignal
) => {
      
      
      return apiAxiosInstanceFn<ObjResponseSchemas>(
      {url: `/training/configuration/object_inference`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAllObjectsInferenceQueryKey = (params?: GetAllObjectsInferenceParams,) => {
    return [`/training/configuration/object_inference`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllObjectsInferenceQueryOptions = <TData = Awaited<ReturnType<typeof getAllObjectsInference>>, TError = ErrorType<HTTPValidationError>>(params?: GetAllObjectsInferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjectsInference>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllObjectsInferenceQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllObjectsInference>>> = ({ signal }) => getAllObjectsInference(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllObjectsInference>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllObjectsInferenceQueryResult = NonNullable<Awaited<ReturnType<typeof getAllObjectsInference>>>
export type GetAllObjectsInferenceQueryError = ErrorType<HTTPValidationError>

/**
 * @summary get all objects of a configuration group by inference_id
 */
export const useGetAllObjectsInference = <TData = Awaited<ReturnType<typeof getAllObjectsInference>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllObjectsInferenceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllObjectsInference>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllObjectsInferenceQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * save labael_assist
 * @summary save labael_assist
 */
export const saveLabaelAssist = (
    params: SaveLabaelAssistParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/configuration/labael_assist`, method: 'PUT',
        params
    },
      );
    }
  


export const getSaveLabaelAssistMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveLabaelAssist>>, TError,{params: SaveLabaelAssistParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof saveLabaelAssist>>, TError,{params: SaveLabaelAssistParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveLabaelAssist>>, {params: SaveLabaelAssistParams}> = (props) => {
          const {params} = props ?? {};

          return  saveLabaelAssist(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SaveLabaelAssistMutationResult = NonNullable<Awaited<ReturnType<typeof saveLabaelAssist>>>
    
    export type SaveLabaelAssistMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary save labael_assist
 */
export const useSaveLabaelAssist = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveLabaelAssist>>, TError,{params: SaveLabaelAssistParams}, TContext>, }
) => {

      const mutationOptions = getSaveLabaelAssistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Create pre_processed_objects
 * @summary Create pre_processed_objects
 */
export const createPreProcessedObjects = (
    configId: string,
    bodyCreatePreProcessedObjects: BodyCreatePreProcessedObjects,
    params: CreatePreProcessedObjectsParams,
 ) => {
      
      
      return apiAxiosInstanceFn<unknown>(
      {url: `/training/configuration/${configId}/process_objects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bodyCreatePreProcessedObjects,
        params
    },
      );
    }
  


export const getCreatePreProcessedObjectsMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPreProcessedObjects>>, TError,{configId: string;data: BodyCreatePreProcessedObjects;params: CreatePreProcessedObjectsParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createPreProcessedObjects>>, TError,{configId: string;data: BodyCreatePreProcessedObjects;params: CreatePreProcessedObjectsParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPreProcessedObjects>>, {configId: string;data: BodyCreatePreProcessedObjects;params: CreatePreProcessedObjectsParams}> = (props) => {
          const {configId,data,params} = props ?? {};

          return  createPreProcessedObjects(configId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePreProcessedObjectsMutationResult = NonNullable<Awaited<ReturnType<typeof createPreProcessedObjects>>>
    export type CreatePreProcessedObjectsMutationBody = BodyCreatePreProcessedObjects
    export type CreatePreProcessedObjectsMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create pre_processed_objects
 */
export const useCreatePreProcessedObjects = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPreProcessedObjects>>, TError,{configId: string;data: BodyCreatePreProcessedObjects;params: CreatePreProcessedObjectsParams}, TContext>, }
) => {

      const mutationOptions = getCreatePreProcessedObjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete Preprocessed Data
 * @summary Delete Preprocessed Data
 */
export const deletePreprocessedData = (
    params: DeletePreprocessedDataParams,
 ) => {
      
      
      return apiAxiosInstanceFn<DeletePreprocessedData200>(
      {url: `/training/delete_preprocessed_data`, method: 'DELETE',
        params
    },
      );
    }
  


export const getDeletePreprocessedDataMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePreprocessedData>>, TError,{params: DeletePreprocessedDataParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePreprocessedData>>, TError,{params: DeletePreprocessedDataParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePreprocessedData>>, {params: DeletePreprocessedDataParams}> = (props) => {
          const {params} = props ?? {};

          return  deletePreprocessedData(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePreprocessedDataMutationResult = NonNullable<Awaited<ReturnType<typeof deletePreprocessedData>>>
    
    export type DeletePreprocessedDataMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Delete Preprocessed Data
 */
export const useDeletePreprocessedData = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePreprocessedData>>, TError,{params: DeletePreprocessedDataParams}, TContext>, }
) => {

      const mutationOptions = getDeletePreprocessedDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
