import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Stack, Box, Link } from '@mui/material';
import { Button } from 'components/Button/Button';

import { Contents, DirectoriesContents } from 'schemasMedia';
import Modal from 'components/Modal';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from 'components/Card';

interface SelectionModalProps {
  selectModal: boolean;
  setSelectModal: (data: boolean) => void;
  selectedFolder: Contents[];
  setCreateDataset: (data: boolean) => void;
  setMode: (data: string) => void;
  mode: string;
}

const SelectionModal: React.FC<SelectionModalProps> = ({
  selectModal,
  setSelectModal,
  selectedFolder,
  setCreateDataset,
  setMode,
  mode
}) => {
  const [step, setStep] = useState<number>(1);
  const [taskType, setTaskType] = useState<string>('');
  const [backgroundType, setBackgroundType] = useState<string>('');

  useEffect(() => {
    setStep(1);
    setTaskType('');
    setBackgroundType('');
    setMode('');
  }, []);

  return (
    <Modal
      onClose={() => {
        setStep(1);
        setTaskType('');
        setBackgroundType('');
        setMode('');
        setSelectModal(false);
      }}
      open={selectModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      padding="2rem"
      size="lg"
    >
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => setStep(1)}
          sx={{ fontWeight: step == 1 ? '800' : '500', fontSize: 16 }}
        >
          Select task type
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => setStep(2)}
          sx={{ fontWeight: step == 2 ? '800' : '500', fontSize: 16 }}
        >
          Select Data-set Type
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => setStep(3)}
          sx={{ fontWeight: step == 3 ? '800' : '500', fontSize: 16 }}
        >
          Reason for Dataset Creation
        </Link>
      </Breadcrumbs>
      {step == 1 && (
        <>
          <Grid container spacing={2} sx={{ height: '150px' }} mt={2}>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor: taskType == 'Supervised' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setTaskType('Supervised');
                  setStep(2);
                }}
              >
                Supervised
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor:
                    taskType == 'Semi-Supervised' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setTaskType('Semi-Supervised');
                  setStep(2);
                }}
              >
                Semi-Supervised
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                onClick={() => {
                  setTaskType('Unsupervised');
                  setStep(2);
                }}
                sx={{
                  backgroundColor: taskType == 'Unsupervised' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
              >
                Unsupervised
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {step == 2 && (
        <>
          <Grid
            container
            spacing={2}
            sx={{ height: '150px' }}
            mt={2}
            justifyContent={'space-around'}
          >
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor:
                    backgroundType == 'with_background' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setBackgroundType('with_background');
                  setStep(3);
                }}
              >
                With Background
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor:
                    backgroundType == 'without_background' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setBackgroundType('without_background');
                  setStep(3);
                }}
              >
                Without Background
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {step == 3 && (
        <>
          <Grid container spacing={2} sx={{ height: '150px' }} mt={2}>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor: mode == 'TRAINING' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setMode('TRAINING');
                }}
              >
                Training
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                sx={{
                  backgroundColor: mode == 'INFERENCE' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
                onClick={() => {
                  setMode('INFERENCE');
                }}
              >
                Inference
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ height: '150px' }}>
              <Button
                version="selection"
                onClick={() => {
                  setMode('BOTH');
                }}
                sx={{
                  backgroundColor: mode == 'BOTH' ? '#4595C4' : '',
                  '&:hover': {
                    backgroundColor: '#4595C4'
                  }
                }}
              >
                Both
              </Button>
            </Grid>
          </Grid>
          <Stack direction={'row'} justifyContent={'center'} spacing={1} mt={2}>
            {' '}
            <Button
              version="light"
              onClick={() => {
                setStep(1);
                setTaskType('');
                setBackgroundType('');
                setMode('');
                setSelectModal(false);
              }}
            >
              {' '}
              Cancel
            </Button>
            <Button
              disabled={backgroundType == '' || taskType == '' || mode == ''}
              onClick={() => {
                setCreateDataset(true);
                setSelectModal(false);
              }}
            >
              Proceed
            </Button>
          </Stack>
        </>
      )}
    </Modal>
  );
};

export default SelectionModal;
