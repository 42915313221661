/* eslint-disable */
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  styled,
  SortDirection,
  IconButton,
  Accordion,
  AccordionDetails,
  Skeleton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import React, { ReactNode, useEffect, useState } from 'react';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

// Define TableContainer as MuiTableContainer
export const TableContainer = MuiTableContainer;

// Define the possible types of values a record can have
export type RecordValue = string | number | undefined | object | boolean | null;

// Define the structure of a record
export type Record = {
  [key: string]: RecordValue | RecordValue[];
};

// Define the input for the rendering function of a cell
type RenderFunctionInput = {
  value: RecordValue;
  record: Record;
};

// Define the properties for a table cell
export interface ITableCellProps {
  value: RecordValue;
  key: string;
  render?: (input: RenderFunctionInput) => ReactNode;
  align?: 'left' | 'right' | 'center';
  ellipsis?: boolean;
}

// Define the properties for a table head column
export interface ITableHeadColumn extends ITableCellProps {
  sorter?: boolean;
  sortOrder?: 'ascend' | 'descend';
  sortDirections?: SortDirection;
  width?: string;
}

// Define the properties for the table component
interface ITableProps {
  columns: Array<ITableHeadColumn>;
  rows: Array<Record>;
  selectedConfig?: string;
  expandable?: boolean;
  expandedRowRender?: (record: Record) => ReactNode;
  striped?: boolean; // Striped prop highlights row by setting different color on alternate rows
  loading?: boolean;
  stickyHeader?: boolean;
  containerStyle?: React.CSSProperties;
  highlightCondition?: (record: Record) => boolean; // Custom highlight condition
}

// Style the table head
const StyledTableHead = styled(MuiTableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));

// Style the table head cell
const StyledTableHeadCell = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));

// Define the component for displaying cell values
const CellValue: React.FC<{
  key: string;
  value: RecordValue;
}> = ({ value }) => {
  if (typeof value === 'object') {
    return <>{JSON.stringify(value)}</>;
  }
  return <>{value}</>;
};

interface IRowProps {
  record: Record;
  selectedConfig?: string;
  columns: Array<ITableHeadColumn>;
  expanded?: boolean;
  expandedRowRender?: (record: Record) => ReactNode;
  onExpandClick?: (expanded: boolean) => void;
  rowIndex: number; // Added rowIndex prop for indentify the odd and even rows on table
  striped?: boolean; // Striped prop highlights row by setting different color on alternate rows
  analysis?: RecordValue;
  highlightCondition?: (record: Record) => boolean; // Custom highlight condition
}

// Define the component for rendering a row
const Row: React.FC<IRowProps> = ({
  record,
  columns,
  expanded = false,
  expandedRowRender,
  onExpandClick,
  rowIndex, // Added rowIndex prop for indentify the odd and even rows on table
  striped, // Striped prop highlights row by setting different color on alternate rows
  highlightCondition // Custom highlight condition
}) => {
  // Convert record to dictionary for easy access
  const recordDict = Object.entries(record).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Record);
  const highlightRow = highlightCondition && highlightCondition(record);

  return (
    <>
      <MuiTableRow
        style={{
          backgroundColor: highlightRow
            ? '#FCF2E1'
            : striped && rowIndex % 2 === 1
            ? '#F1F1F1'
            : 'inherit'
        }}
      >
        {columns.map((column, index) => (
          <MuiTableCell key={`row-${index}`} align={'center'}>
            {column.render ? (
              column.render({ value: recordDict[column.key], record })
            ) : (
              <CellValue key={column.key} value={recordDict[column.key]} />
            )}
          </MuiTableCell>
        ))}
        {expandedRowRender && (
          <MuiTableCell>
            <IconButton
              onClick={() => onExpandClick && onExpandClick(!expanded)}
            >
              {/* {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#102633',
                  fontSize: '16px'
                }}
              >
                {/* {expanded ? 'Hide Details' : 'View Details'} */}
              </span>
            </IconButton>
          </MuiTableCell>
        )}
      </MuiTableRow>
      {expanded && expandedRowRender && (
        <MuiTableRow>
          <MuiTableCell colSpan={columns.length + 1}>
            {expandedRowRender(record)}
          </MuiTableCell>
        </MuiTableRow>
      )}
    </>
  );
};

/**
 * Table Component
 * @param {ITableProps} columns - Array of column definitions.
 * @param {Array<Record>} rows - Array of record data.
 * @param {boolean} expandable - Indicates if the table rows are expandable.
 * @param {Function} expandedRowRender - Function to render expanded rows.
 */
export const Table: React.FC<ITableProps> = ({
  columns,
  rows = [],
  expandedRowRender,
  selectedConfig,
  striped = false, // Default value is false
  loading = false,
  stickyHeader = true, //To Stick the Header Part
  containerStyle,
  highlightCondition
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>();
  useEffect(() => {
    rows.map(
      (data, index) => data?.id === selectedConfig && setExpandedRowIndex(index)
    );
  }, [selectedConfig]);

  return (
    <MuiTableContainer style={containerStyle}>
      <MuiTable stickyHeader={stickyHeader}>
        {columns && (
          <StyledTableHead>
            <MuiTableRow>
              {columns.map((column, index) => (
                <StyledTableHeadCell
                  key={`head-${index}`}
                  {...(column.width && { width: column.width })}
                  {...(column.align && { align: column.align })}
                  {...(column.ellipsis && { whiteSpace: 'nowrap' })}
                  sortDirection={'asc'}
                  aria-sort="ascending"
                  variant="head"
                >
                  <CellValue value={column.value} key={column.key} />
                </StyledTableHeadCell>
              ))}
              {expandedRowRender && <StyledTableHeadCell />}
            </MuiTableRow>
          </StyledTableHead>
        )}
        <MuiTableBody>
          {loading &&
            [1, 2, 3, 4, 5].map((_, index) => (
              <MuiTableRow key={`loading-row-${index}`}>
                {columns.map((column, index) => (
                  <MuiTableCell key={`loading-cell-${index}`} align="center">
                    {/* <CellValue value="Loading..." key={column.key} /> */}
                    <Skeleton variant="text" />
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            ))}
          {rows.map((row, rowIndex) => (
            <Row
              key={`row-${rowIndex}`}
              record={row}
              analysis={row.analysis}
              columns={columns}
              expandedRowRender={expandedRowRender}
              onExpandClick={expanded => {
                setExpandedRowIndex(expanded ? rowIndex : null);
              }}
              expanded={expandedRowIndex === rowIndex}
              rowIndex={rowIndex}
              striped={striped} // Striped prop highlights row by setting different color on alternate rows
              highlightCondition={highlightCondition} // Pass highlight condition to row
            />
          ))}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
