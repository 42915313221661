import { InputLabel as MInputLabel, InputLabelProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface IInputLabelProps extends InputLabelProps {
  light?: boolean;
}

const StyledInputLabel = styled(MInputLabel)<IInputLabelProps>`
  flex-shrink: 0;
  margin-bottom: -20px;
  margin-left: 13px;
  font-size: 1rem;
  ${({ light }) =>
    light
      ? `
    color: #fff;
  `
      : `
    color: #000;
    background-color: #fff;
    z-index: 1;
    `};
};
`;

const StyledRequiredAsterisk = styled.span`
  color: red;
`;

export const RequiredAsterisk: React.FC = () => {
  return <StyledRequiredAsterisk>*</StyledRequiredAsterisk>;
};

export const InputLabel: React.FC<IInputLabelProps> = ({
  children,
  required,
  light,
  ...props
}) => {
  return (
    <StyledInputLabel {...props} light={light}>
      {' '}
      {required && <RequiredAsterisk />} {children}
    </StyledInputLabel>
  );
};
