/* eslint-disable */
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Stack
} from '@mui/material';
import {
  useCreateObjects,
  useGetObjectsByInference,
  useSaveLabaelAssist,
  useStartInference,
  useSyncObjectByInferenceid
} from 'api/sdk';
import { Modal, Typography, Button } from 'components';

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateObjectSchema,
  ImgSchema,
  SyncObjectsRequest,
  UnderRepresentedList,
  UnRepresentedlist
} from 'schemas';
import { v4 } from 'uuid';

interface ICreateConfigModalProps {
  open: boolean;
  onClose: () => void;
  selectedConfig: string;
  checkedUnderRepresented: UnderRepresentedList[];
  checkedUnRepresented: UnRepresentedlist[];

  selectedModal: string;
}

export const StartIncremental: React.FC<ICreateConfigModalProps> = ({
  open,
  onClose,
  selectedConfig,
  checkedUnderRepresented,
  selectedModal,
  checkedUnRepresented
}) => {
  const [newInferenceID, setNewInferenceID] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');

  const navigate = useNavigate();
  const { data: objectListArr, mutateAsync: syncObject } =
    useSyncObjectByInferenceid();
  const { data: newObjects, refetch: refetchNewObjects } =
    useGetObjectsByInference(newInferenceID || '', {
      pipeline_satus: false
    });

  const { mutateAsync: saveData, isLoading: saveLoading } =
    useSaveLabaelAssist();

  const createClasses: string[] = useMemo(() => {
    const classes: string[] = [];
    for (let x of checkedUnderRepresented) {
      for (let y of x.classnames) {
        // if (!classes.includes(y.classname as string)) {
        //   if (y.images && y.images?.length > 0) {
        //     classes.push(y.classname as string);
        //   }
        // }
      }
    }
    for (let x of checkedUnRepresented) {
      if (x.classnames) {
        for (let y of x.classnames) {
          if (!classes.includes(y.classname as string)) {
            if (y.images && y.images?.length > 0) {
              classes.push(y.classname as string);
            }
          }
        }
      }
    }
    return classes;
  }, [checkedUnderRepresented, checkedUnRepresented]);

  const { mutateAsync: CreateObjects, isLoading: createObjectLoading } =
    useCreateObjects();

  useEffect(() => {
    refetchNewObjects().then(res => {
      const obj = res.data?.data.object_details;
      if (obj) {
        for (let x of checkedUnderRepresented) {
          for (let y of x.classnames) {
            // const currentObj = obj.find(i => i.classname == y.classname);

            // if (currentObj && y.images) {
            //   const images: ImgSchema[] = [];
            //   // for (let i = 0; i < y?.images?.length; i++) {
            //   //   const temp: ImgSchema = {
            //   //     id: v4(),
            //   //     image_path: y.imagespath[i] as string,
            //   //     nas_path: y.nas_path[i] as string,
            //   //     overlayed_path: '',
            //   //     overlayed_url: '',
            //   //     thumb_url: y.imagespath[i]
            //   //   };
            //   //   images.push(temp);
            //   // }

            //   currentObj.images = currentObj.images
            //     ? [...currentObj.images, ...images]
            //     : images;
            // }
          }
        }

        for (let x of checkedUnRepresented) {
          if (x.classnames) {
            for (let y of x?.classnames) {
              const currentObj = obj.find(i => i.classname == y.classname);

              if (currentObj && y.images) {
                const images: ImgSchema[] = [];
                for (let i = 0; i < y?.images?.length; i++) {
                  const temp: ImgSchema = {
                    id: v4(),
                    image_path: y.imagespath[i] as string,
                    nas_path: y.nas_path[i] as string,
                    overlayed_path: '',
                    overlayed_url: '',
                    thumb_url: y.imagespath[i]
                  };
                  images.push(temp);
                }

                currentObj.images = currentObj.images
                  ? [...currentObj.images, ...images]
                  : images;
              }
            }
          }
        }

        const updatedObjects: SyncObjectsRequest[] = obj.map(obj2 => ({
          id: obj2.id,
          classname: obj2.classname,
          category: obj2.category,
          inference_id: newInferenceID,
          configuration_id: selectedConfig,
          groupname: obj2.groupname as string,
          images:
            obj2.images?.map(imagePath => ({
              id: imagePath.id,
              image_path: imagePath.thumb_url || null,
              nas_path: imagePath.nas_path,
              frame_path: null,
              frame_number: null,
              frame_width: null,
              frame_height: null,
              bounding_box: null,
              thumb_url: imagePath.thumb_url
            })) || [],
          mode: 'BOTH'
        }));

        syncObject({
          data: updatedObjects,
          inferenceId: newInferenceID,
          params: { config_id: selectedConfig || '' }
        }).then(res => {
          saveData({
            params: { inference_id: newInferenceID }
          }).then(res => {
            navigate('/labelAssist1', {
              state: {
                inferenceid: newInferenceID,
                type: 'Incremental',
                modelID: selectedModal
              }
            });
          });
        });
      }
    });
  }, [newInferenceID]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      title="Enter Dataset name"
      size="md"
    >
      <TextField
        name=""
        variant="outlined"
        placeholder="Enter Dataset name"
        size="small"
        fullWidth
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
      />

      <Stack direction={'row'} justifyContent={'center'} mt={2} spacing={2}>
        <Button version="light" onClick={() => onClose()}>
          cancel
        </Button>

        <Button
          disabled={groupName.length == 0 || createClasses.length == 0}
          onClick={() => {
            const mapped: CreateObjectSchema[] = createClasses.map(i => {
              return {
                classname: i,
                configuration_id: selectedConfig || '',
                mode: 'BOTH',
                groupname: groupName
              };
            });
            CreateObjects({
              configId: selectedConfig || '',
              data: mapped
            }).then(created => {
              const inferenceID = created.data[0].inference_id;
              setNewInferenceID(inferenceID || '');
            });
          }}
        >
          Proceed
        </Button>
      </Stack>
    </Modal>
  );
};
