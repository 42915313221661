import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import Folder from '../../assets/images/Folder.png';
import styled from '@emotion/styled';
import { Typography } from '../Typography/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface DatalistProps {
  classname: string;
  noOfImages: number;
  selected?: boolean;
  onRemove?: (data: string) => void;
}

const Datalist: React.FC<DatalistProps> = ({
  classname,
  noOfImages,
  selected,
  onRemove
}) => {
  return (
    <Grid
      style={{ backgroundColor: selected ? '#E3EFF6' : '#fff' }}
      mt={1}
      mb={1}
      p={1}
    >
      <Stack
        direction={'row'}
        justifyContent={onRemove ? 'space-between' : ''}
        pl={1}
        pr={1}
        spacing={2}
      >
        <img src={Folder} alt="" style={{ width: '50px', height: '40px' }} />
        {onRemove && (
          <IconButton
            onClick={() => {
              onRemove && onRemove(classname);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
        {!onRemove && (
          <Box>
            <Typography>Classname : {classname}</Typography>
            <Typography>No. of Images : {noOfImages}</Typography>
          </Box>
        )}
      </Stack>
      {onRemove && (
        <>
          <Typography>Classname : {classname}</Typography>
          <Typography>No. of Images : {noOfImages}</Typography>
        </>
      )}
    </Grid>
  );
};

export default Datalist;
